export const capitalFirstLetter = str => {
  if (str) {
    if (str.length <= 0 || str === null) return str;

    let newString =
      str.charAt(0).toUpperCase() +
      str
        .slice(1)
        .toLowerCase()
        .trim();
    return newString;
  }
  return;
};

export const allCapitalLetters = str => {
  if (str) {
    return str
      .toUpperCase()
      .trim()
      .split(" ")
      .join("");
  }
  return;
};

export const capManyWords = str => {
  if (str) {
    if (str.length <= 0 || str === null) return str;

    let newString = str
      .split(" ")
      .map(
        word => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
      )
      .join(" ")
      .trim();
    return newString;
  }
  return;
};

export const edusFormat = id => {
  const num = new Array(7 - id.toString().length).join("0");
  let newString = "EDUS";

  return newString + num + id;
};
