import React from "react";
import SiteWrapper from "../../SiteWrapper.react";
import Notifications from "./Notifications";

const index = (props) => {
  return (
    <SiteWrapper>
      <Notifications {...props} />
    </SiteWrapper>
  );
};

export default index;
