import Axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import config from "../config/config";
import pdfFile from "./international-refund-application.pdf";

export const SwalDeferOrRefund = async (id, terms) => {
  const deferQueue = Swal.mixin({
    confirmButtonText: "Next",
    cancelButtonText: "Back",
    inputAttributes: {
      required: true,
    },
    validationMessage: "This field is required",
    reverseButtons: true,
  });

  const DeferOrRefund = await deferQueue.fire({
    title: "Please select one option",
    input: "radio",
    inputOptions: {
      defer: "Defer",
      refund: "Refund",
    },
    inputValidator: value => {
      if (!value) {
        return "This field is required";
      }
    },
  });
  if (DeferOrRefund.dismiss) return;

  if (DeferOrRefund.value === "defer") {
    const message = await DeferSteps(terms);
    return message;
  }

  if (DeferOrRefund.value === "refund") {
    const file = await RefundSteps(id);
    return file;
  }

  return;
};

const DeferSteps = async terms => {
  const deferQueue = Swal.mixin({
    confirmButtonText: "Next",
    cancelButtonText: "Back",
    inputAttributes: {
      required: true,
    },
    validationMessage: "This field is required",
    reverseButtons: true,
  });

  const deferralAttempt = await deferQueue.fire({
    title: "Is this the 1st or 2nd deferral?",
    input: "radio",
    inputOptions: {
      first: "first",
      second: "second",
    },
    inputValidator: value => {
      if (!value) {
        return "This field is required";
      }
    },
  });
  if (deferralAttempt.dismiss) return;

  let paymentId = "";

  if (deferralAttempt.value === "second") {
    const value = await deferQueue.fire({
      title: "Please enter the flywire application fee payment ID",
      input: "text",
    });
    paymentId = value.value;
  }

  const newIntake = await deferQueue.fire({
    title: "Please select the new intake",
    input: "select",
    inputOptions: {
      "Fall 2022": "Fall 2022",
    },
    inputPlaceholder: "Select an intake",
  });
  if (newIntake.dismiss) return;

  const selectedTerm = terms.filter(e => e.name === newIntake.value);
  const availableList = await Axios.get(
    `${config.server}${config.api.availability}/?term=${selectedTerm[0].id}`
  );
  const newList = availableList.data.filter(
    e => !e.Program.name.includes("One")
  );
  const filteredList = newList.filter(
    e => !e.Program.name.includes("Computer System Technician")
  );
  // console.log('availablelist',availableList);
  // console.log('filteredlist',filteredList);

  const newObj = {};
  for (let i = 0; i < filteredList.length; i++) {
    newObj[filteredList[i].Program.name] = filteredList[i].Program.name;
  }

  const newProgram = await deferQueue.fire({
    title: "Please select the new program",
    input: "select",
    inputOptions: newObj,
    inputPlaceholder: "Select a program",
  });
  if (newProgram.dismiss) return;

  const message = `Deferral #${paymentId.length > 0 ? "2" : "1"} ${
    paymentId.length > 0 ? `Payment ID: ${paymentId}. ` : ""
  }Program & Intake: ${newIntake.value} ${newProgram.value}.`;

  return message;
};

const RefundSteps = async id => {
  const mySwal = withReactContent(Swal);

  const deferQueue = mySwal.mixin({
    confirmButtonText: "Next",
    cancelButtonText: "Back",
    inputAttributes: {
      required: true,
    },
    validationMessage: "This field is required",
    reverseButtons: true,
  });

  const refundFile = await deferQueue.fire({
    title: (
      <div style={{ textAlign: "center" }}>
        <p>Please fill out and submit your refund form</p>
        <a
          href={pdfFile}
          target="_blank"
          style={{ fontSize: "20px", color: "blue" }}
        >
          Refund Form
        </a>
      </div>
    ),
    input: "file",
  });
  if (refundFile.dismiss) return;

  const fileData = buildFileForm(5, refundFile.value, id);
  console.log("fileData", fileData);
  return fileData;
};

export const SwalVisaStatus = async id => {
  const visaQueue = Swal.mixin({
    confirmButtonText: "Next",
    cancelButtonText: "Back",
    inputAttributes: {
      required: true,
    },
    showCancelButton: true,
    validationMessage: "This field is required",
    reverseButtons: true,
    inputValidator: value => {
      if (!value) {
        return "This field is required";
      }
    },
  });

  const approvalFile = await visaQueue.fire({
    title: "Please submit your visa document",
    input: "file",
  });
  if (approvalFile.dismiss) return;

  const fileData = buildFileForm(5, approvalFile.value, id);
  return fileData;
};

const buildFileForm = (docType, file, id) => {
  console.log("file", file);
  const formData = new FormData();
  formData.append("documentType", docType);
  formData.append("file", file);
  formData.append("application", id);

  return formData;
};

export const SwalWithdraw = async () => {
  const withdrawQueue = Swal.mixin({
    inputAttributes: {
      required: true,
    },
    validationMessage: "This field is required",
    inputValidator: value => {
      if (!value) {
        return "This field is required";
      }
    },
  });

  await withdrawQueue.fire({
    title: "Application under process",
    text: "Are you sure you want to withdraw?",
    type: "warning",
    confirmButtonText: "Next",
  });

  const withdrawMessage = await withdrawQueue.fire({
    title: "Please enter the reason for withdrawal",
    input: "textarea",
    confirmButtonText: "Submit",
  });
  if (withdrawMessage.dismiss) return;

  return withdrawMessage.value;
};
