import React, { useEffect, useState } from "react";
import { Box, Button, Center, Container, Stack, Stepper } from "@mantine/core";
import SiteWrapper from "../../SiteWrapper.react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { useForm } from "@mantine/form";
import { useAppStore } from "../../lib/zustandStore";
import { useHistory } from "react-router-dom";
import {
  useGetOneApplication,
  usePostStep1,
  usePostStep2,
  usePostStep3,
  usePostStep4,
} from "./api";
import { notifications } from "@mantine/notifications";
import { APP_STATUS } from "../../utils/applicationStatus";

const initialValues = {
  personal: {
    id: "",
    salutation: "",
    firstName: "",
    lastName: "",
    middleName: "",
    DOB: "",
    MailingAddress: "",
    MailingAddress2: "",
    city: "",
    postalCode: "",
    telephone: "",
    email: "",
    passport: "",
    status: "",
    notifications: "",
    nationality: "",
    country: "",
    province: "",
    profileScore: "",
    studentId: "",
    gender: "",
    status_in_canada: "",
    name_emergency: "",
    phone_emergency: "",
    email_emergency: "",
    relation_emergency: "",
    english_emergency: "",
    passportExpiry: "",
    meta: "",
    program: "",
    program_secondary: "",
    applicationLogs: "",
    applicationLanguage: "",
    applicationAcademics: "",
    applicationDocuments: "",
    ProgramsAvailable: {
      Programs: {
        institution: "",
      },
    },
    program_second: "",
    User: "",
  },
  language: {
    mother_tongue: "",
    listening: "",
    reading: "",
    speaking: "",
    writing: "",
    totalScore: "",
    takenOn: "",
    accommodation: "",
    testTaken: "",
  },
  academics: [
    {
      type: "",
      country: "",
      province: "",
      city: "",
      currentStatus: "",
      instituteName: "",
      firstYear: "",
      lastYear: "",
    },
  ],
};

const ApplicationForm = () => {
  let history = useHistory();

  const changeApp = useAppStore(state => state.changeAppId);
  const appId = useAppStore(state => state.appId);
  const changeIsEdit = useAppStore(state => state.changeIsEdit);
  const isEdit = useAppStore(state => state.isEdit);

  const { data, isLoading } = useGetOneApplication(appId);

  const form = useForm({
    initialValues,
  });

  useEffect(
    () => {
      if (data) {
        form.setValues(values => ({
          ...values,
          personal: data,
        }));
      }
    },
    [data]
  );

  useEffect(
    () => {
      if (isEdit) {
        changeApp(appId);
      }
    },
    [appId]
  );

  const { mutateAsync: mutateStep1 } = usePostStep1();
  const { mutateAsync: mutateStep2 } = usePostStep2();
  const { mutateAsync: mutateStep3 } = usePostStep3();
  const { mutateAsync: mutateStep4 } = usePostStep4();

  // stepper state
  const [active, setActive] = useState(0);

  const handleErrorResponse = (obj, str) => {
    const properError = Object.keys(obj).reduce(
      (v, k) => ({
        ...v,
        [str + "." + k]: obj[k],
      }),
      null
    );

    form.setErrors(properError);
  };

  const nextStep = async () => {
    if (active === 0) {
      // api call
      try {
        const returnData = await mutateStep1({
          ...form.values.personal,
          editMode: isEdit,
        });
        if (!appId) {
          changeApp(returnData.id);
        }
      } catch (err) {
        let obj = err?.response?.data;
        handleErrorResponse(obj, "personal");
        return;
      }
    }

    if (active === 1) {
      try {
        await mutateStep2({
          ...form.values.language,
          application: appId,
        });
      } catch (err) {
        let obj = err.response.data;
        handleErrorResponse(obj, "language");
        return;
      }
    }

    if (active === 2) {
      try {
        await mutateStep3({
          academics: form.values.academics,
          metaData: { applicationId: appId },
        });
      } catch (err) {
        let obj = err.response.data;
        handleErrorResponse(obj, "academics");
        return;
      }
    }

    // redirect to view page at last step
    if (active === 3) {
      // TODO: This needs to be changed
      try {
        if (data.status >= 2) {
          await mutateStep4({
            status: APP_STATUS.UNDER_REVIEW.value,
            application: appId,
          });
        } else {
          await mutateStep4({
            status: APP_STATUS.PRE_SCREEN.value,
            application: appId,
          });
        }
        history.push(`/application/view/${appId}`);
      } catch (err) {
        notifications.show("something went wrong");
      }
    }

    window.scrollTo(0, 0);
    setActive(current => (current < 3 ? current + 1 : current));
  };

  const prevStep = () => {
    window.scrollTo(0, 0);
    setActive(current => (current > 0 ? current - 1 : current));
    if (isEdit !== true) {
      changeIsEdit(true);
    }
  };

  // if (!isEdit && !appId && active !== 0) {
  //   setActive(0);
  // }

  if (isLoading) {
    return <>Loading</>;
  }

  return (
    <SiteWrapper>
      <Container my="xl">
        <Center>
          <Stack>
            <Stepper active={active} breakpoint="sm">
              <Stepper.Step label="Step 1" description="Personal Information">
                <Step1 step={0} form={form} />
              </Stepper.Step>
              <Stepper.Step label="Step 2" description="Language Proficiency">
                <Step2 step={1} form={form} />
              </Stepper.Step>
              <Stepper.Step label="Step 3" description="Academic History">
                <Step3 step={2} form={form} />
              </Stepper.Step>
              <Stepper.Step label="Step 4" description="Document Upload">
                <Step4 step={3} form={form} />
              </Stepper.Step>
            </Stepper>
            <Box
              sx={theme => ({
                marginLeft: "auto",
                marginRight: 0,
                marginBottom: theme.spacing.xl,
                display: "flex",
              })}
            >
              {active !== 0 && (
                <Button
                  fullWidth={false}
                  variant="default"
                  onClick={prevStep}
                  mr="md"
                >
                  Previous
                </Button>
              )}
              <Button fullWidth={false} onClick={nextStep}>
                {active === 3 ? "Submit" : "Next"}
              </Button>
            </Box>
          </Stack>
        </Center>
      </Container>
    </SiteWrapper>
  );
};

export default ApplicationForm;
