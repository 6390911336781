import * as React from "react";

import View from "./View.react.js";
import { Route, Switch } from "react-router-dom";

import Step1 from "./Step1.react.js";
import Step2 from "./Step2.react.js";
import Step3 from "./Step3.react.js";
import Step4 from "./Step4.react.js";
import College from "./College.js";
import AppView from "../ApplicationForm/AppView";

function ApplicationRoute() {
  return (
    <Switch>
      <Route path="/application/view/:id" component={AppView} />
      <Route exact path="/application/submit" component={Step1} />
      <Route exact path="/application/language" component={Step2} />
      <Route exact path="/application/academic" component={Step3} />
      <Route exact path="/application/documents" component={Step4} />
      <Route exact path="/application/college" component={College} />
    </Switch>
  );
}

export default ApplicationRoute;
