// @flow

import * as React from "react";

import { Card, Container, Grid, Form, Button } from "tabler-react";

import SiteWrapper from "../../SiteWrapper.react";
import { withRouter } from "react-router-dom";
import { withLastLocation } from "react-router-last-location";
import { handleApplicationSubmissions } from "../../actions/applicationActions";
import isEmpty from "../../utils/is-empty.js";
import countriesList from "../../utils/countriesList.js";
import { programsList } from "../../actions/programsActions.js";
import config from "../../config/config.js";
import {
  allCapitalLetters,
  capitalFirstLetter,
  capManyWords,
} from "../../utils/formatUserData";
import "../../App.css";

// Get the redux files
import { connect } from "react-redux";
import PropTypes from "prop-types";

class Step1 extends React.Component {
  constructor(props) {
    super(props);

    // Set the initial State
    this.state = {
      formData: {
        ProgramsAvailable: { id: 1 },
        middleName: "",
        postalCode: "",
      },
      programsList: {},
      errors: {},
    };

    // Bind the functions with {this}
    this.onChange = this.onChange.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
  }

  // Set the errors state
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  // Component will mount
  componentWillMount() {
    this.props.programsList("?status=1");
  }

  // Set the formData if editMode
  componentDidMount() {
    // Set the form state to currentApplication state
    this.setState({
      formData: this.props.application.currentApplication,
      program: this.state.formData.ProgramsAvailable.id,
    });
  }

  // Handle the form input changes
  onChange(e) {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
    this.forceUpdate();
  }

  // Handle the form submission
  handleFormSubmission = e => {
    e.preventDefault();

    // Set the application data
    let { formData } = this.state;

    // Format the application data
    formData = {
      ...formData,
      firstName: capitalFirstLetter(formData.firstName),
      lastName: capitalFirstLetter(formData.lastName),
      middleName: capitalFirstLetter(formData.middleName),
      city: capitalFirstLetter(formData.city),
      province: capitalFirstLetter(formData.province),
      passport: allCapitalLetters(formData.passport),
      postalCode: allCapitalLetters(formData.postalCode),
      MailingAddress: capManyWords(formData.MailingAddress),
      name_emergency: capManyWords(formData.name_emergency),
      passportExpiry: formData.passportExpiry,
    };

    //Reset the errors
    this.setState({ errors: {} });
    // Check the current application mode

    let applicationID = "";
    if (
      this.props.application.editMode ||
      this.props.application.submissionMode
    )
      formData.applicationID = this.props.application.currentApplicationID;

    formData.editMode = this.props.application.editMode;

    // Send the application to action
    this.props.handleApplicationSubmissions(
      formData,
      "/application/language",
      `${config.server}${config.api.applicationSingle}`,
      1,
      this.props.history,
      formData.applicationID
    );
  };

  render() {
    if (
      this.props.settings.acceptNewApps === false &&
      (this.props.application.editMode === false ||
        this.props.application.currentApplication.status === 0) &&
      this.props.auth.user.permission === 1
    ) {
      this.props.history.push("/");
    }

    // Get the list of programs
    const programList = this.props.programs.list;
    const finalList = [];
    const currentProgram = !isEmpty(this.state.formData)
      ? this.state.formData.program
      : "";

    Object.keys(programList).forEach(key => {
      const currentProgram = programList[`${key}`];
      const code = currentProgram["name"];
      const term = currentProgram["ProgramsAvailables"];
      const institution = currentProgram["institution"];
      Object.keys(term).forEach(key => {
        const termName = term[`${key}`].Term.name;
        const termId = term[`${key}`]["id"];
        finalList.push(
          <option
            selected={currentProgram == termId ? "selected" : ""}
            value={termId}
          >{`${institution} | ${termName} | ${code}`}</option>
        );
      });
    });

    // Get the errors
    return (
      <SiteWrapper>
        <div className="my-3 my-md-5">
          <Container>
            <Grid.Row>
              <Grid.Col lg={8} className="applicationForm">
                <Card
                  title="Applicant profile information"
                  RootComponent={Form}
                >
                  <Card.Body>
                    <Form.Group label="Salutation" isRequired>
                      <Form.Select
                        value={this.state.formData.salutation}
                        name="salutation"
                        feedback={
                          isEmpty(this.state.errors.salutation)
                            ? ""
                            : this.state.errors.salutation
                        }
                        invalid={
                          isEmpty(this.state.errors.salutation) ? false : true
                        }
                        onChange={this.onChange}
                      >
                        <option value={null} disabled selected>
                          Select a saluation
                        </option>
                        <option
                          selected={
                            this.state.formData.saluation == "Mr."
                              ? "selected"
                              : ""
                          }
                          value="Mr."
                        >
                          Mr.
                        </option>
                        <option
                          selected={
                            this.state.formData.saluation == "Mrs."
                              ? "selected"
                              : ""
                          }
                          value="Mrs."
                        >
                          Mrs.
                        </option>
                        <option
                          selected={
                            this.state.formData.saluation == "Miss."
                              ? "selected"
                              : ""
                          }
                          value="Miss."
                        >
                          Miss.
                        </option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group label="First Name" isRequired>
                      <Form.Input
                        value={this.state.formData.firstName}
                        name="firstName"
                        feedback={
                          isEmpty(this.state.errors.firstName)
                            ? ""
                            : this.state.errors.firstName
                        }
                        invalid={
                          isEmpty(this.state.errors.firstName) ? false : true
                        }
                        onChange={this.onChange}
                        placeholder="Example: Ranjeet"
                      />
                    </Form.Group>

                    <Form.Group label="Last Name">
                      <Form.Input
                        value={this.state.formData.lastName}
                        name="lastName"
                        feedback={
                          isEmpty(this.state.errors.lastName)
                            ? ""
                            : this.state.errors.lastName
                        }
                        invalid={
                          isEmpty(this.state.errors.lastName) ? false : true
                        }
                        placeholder="Example: Sandhu"
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group label="Middle Name">
                      <Form.Input
                        value={this.state.formData.middleName}
                        name="middleName"
                        feedback={
                          isEmpty(this.state.errors.middleName)
                            ? ""
                            : this.state.errors.middleName
                        }
                        invalid={
                          isEmpty(this.state.errors.middleName) ? false : true
                        }
                        placeholder="Example: Singh"
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group label="Gender" isRequired>
                      <Form.Select
                        value={this.state.formData.gender}
                        name="gender"
                        feedback={
                          isEmpty(this.state.errors.gender)
                            ? ""
                            : this.state.errors.gender
                        }
                        invalid={
                          isEmpty(this.state.errors.gender) ? false : true
                        }
                        onChange={this.onChange}
                      >
                        <option value={null} disabled selected>
                          Select a Gender
                        </option>
                        <option
                          selected={
                            this.state.formData.gender == "Male"
                              ? "selected"
                              : ""
                          }
                          value="Male"
                        >
                          Male
                        </option>
                        <option
                          selected={
                            this.state.formData.gender == "Female"
                              ? "selected"
                              : ""
                          }
                          value="Female"
                        >
                          Female
                        </option>
                        <option
                          selected={
                            this.state.formData.gender == "Other"
                              ? "selected"
                              : ""
                          }
                          value="Other"
                        >
                          Other
                        </option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group label="Date of birth YYYY-MM-DD" isRequired>
                      <Form.MaskedInput
                        id="datePicker"
                        value={this.state.formData.DOB}
                        placeholder="0000-00-00"
                        name="DOB"
                        feedback={
                          isEmpty(this.state.errors.DOB)
                            ? ""
                            : this.state.errors.DOB
                        }
                        invalid={isEmpty(this.state.errors.DOB) ? false : true}
                        onChange={this.onChange}
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </Form.Group>

                    <Form.Group label="Nationality" isRequired>
                      <Form.Select
                        value={this.state.formData.nationality}
                        onChange={this.onChange}
                        name="nationality"
                        feedback={
                          isEmpty(this.state.errors.nationality)
                            ? ""
                            : this.state.errors.nationality
                        }
                        invalid={
                          isEmpty(this.state.errors.nationality) ? false : true
                        }
                      >
                        {countriesList(this.state.formData.country)}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group label="Current Status in Canada" isRequired>
                      <Form.Select
                        value={this.state.formData.status_in_canada}
                        name="status_in_canada"
                        feedback={
                          isEmpty(this.state.errors.status_in_canada)
                            ? ""
                            : this.state.errors.status_in_canada
                        }
                        invalid={
                          isEmpty(this.state.errors.status_in_canada)
                            ? false
                            : true
                        }
                        onChange={this.onChange}
                      >
                        <option value={null} disabled selected>
                          Select a Status
                        </option>
                        <option
                          selected={
                            this.state.formData.status_in_canada ==
                            "No Status in Canada"
                              ? "selected"
                              : ""
                          }
                          value="No Status in Canada"
                        >
                          No Status in Canada
                        </option>
                        <option
                          selected={
                            this.state.formData.status_in_canada ==
                            "In canada on the authority of another visa"
                              ? "selected"
                              : ""
                          }
                          value="In canada on the authority of another visa"
                        >
                          In canada on the authority of another visa
                        </option>
                        <option
                          selected={
                            this.state.formData.status_in_canada ==
                            "Refugee Status (i.e. Claimant)"
                              ? "selected"
                              : ""
                          }
                          value="Refugee Status (i.e. Claimant)"
                        >
                          Refugee Status (i.e. Claimant)
                        </option>
                        <option
                          selected={
                            this.state.formData.status_in_canada ==
                            "Study Permit / Student Visa"
                              ? "selected"
                              : ""
                          }
                          value="Study Permit / Student Visa"
                        >
                          Study Permit / Student Visa
                        </option>
                        <option
                          selected={
                            this.state.formData.status_in_canada == "Other"
                              ? "selected"
                              : ""
                          }
                          value="Other"
                        >
                          Other
                        </option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group label="Mailing Address" isRequired>
                      <Form.Input
                        value={this.state.formData.MailingAddress}
                        name="MailingAddress"
                        feedback={
                          isEmpty(this.state.errors.MailingAddress)
                            ? ""
                            : this.state.errors.MailingAddress
                        }
                        invalid={
                          isEmpty(this.state.errors.MailingAddress)
                            ? false
                            : true
                        }
                        placeholder="334445 Harold Shear."
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group label="Mailing Address (Line 2)">
                      <Form.Input
                        value={this.state.formData.MailingAddress2}
                        name="MailingAddress2"
                        feedback={
                          isEmpty(this.state.errors.MailingAddress2)
                            ? ""
                            : this.state.errors.MailingAddress2
                        }
                        invalid={
                          isEmpty(this.state.errors.MailingAddress2)
                            ? false
                            : true
                        }
                        placeholder="LandMark: Nothing"
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group label="City" isRequired>
                      <Form.Input
                        value={this.state.formData.city}
                        name="city"
                        feedback={
                          isEmpty(this.state.errors.city)
                            ? ""
                            : this.state.errors.city
                        }
                        invalid={isEmpty(this.state.errors.city) ? false : true}
                        placeholder="Example: Amritsar"
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group label="Province/State" isRequired>
                      <Form.Input
                        value={this.state.formData.province}
                        feedback={
                          isEmpty(this.state.errors.province)
                            ? ""
                            : this.state.errors.province
                        }
                        invalid={
                          isEmpty(this.state.errors.province) ? false : true
                        }
                        name="province"
                        placeholder="Example: Punjab"
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group label="Country" isRequired>
                      <Form.Select
                        value={this.state.formData.country}
                        onChange={this.onChange}
                        name="country"
                        feedback={
                          isEmpty(this.state.errors.country)
                            ? ""
                            : this.state.errors.country
                        }
                        invalid={
                          isEmpty(this.state.errors.country) ? false : true
                        }
                      >
                        {countriesList(this.state.formData.country)}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group label="Postal Code">
                      <Form.Input
                        value={this.state.formData.postalCode}
                        name="postalCode"
                        onChange={this.onChange}
                        placeholder="Example: 232323"
                        feedback={
                          isEmpty(this.state.errors.postalCode)
                            ? ""
                            : this.state.errors.postalCode
                        }
                        invalid={
                          isEmpty(this.state.errors.postalCode) ? false : true
                        }
                      />
                    </Form.Group>

                    <Form.Group label="Telephone" isRequired>
                      <Form.Input
                        value={this.state.formData.telephone}
                        placeholder="+91 5554953947"
                        feedback={
                          isEmpty(this.state.errors.telephone)
                            ? ""
                            : this.state.errors.telephone
                        }
                        invalid={
                          isEmpty(this.state.errors.telephone) ? false : true
                        }
                        name="telephone"
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group label="Email Address" isRequired>
                      <Form.Input
                        value={this.state.formData.email}
                        name="email"
                        onChange={this.onChange}
                        feedback={
                          isEmpty(this.state.errors.email)
                            ? ""
                            : this.state.errors.email
                        }
                        invalid={
                          isEmpty(this.state.errors.email) ? false : true
                        }
                        placeholder="example@somesite.com"
                      />
                    </Form.Group>

                    <Form.Group label="Passport Number" isRequired>
                      <Form.Input
                        value={this.state.formData.passport}
                        name="passport"
                        feedback={
                          isEmpty(this.state.errors.passport)
                            ? ""
                            : this.state.errors.passport
                        }
                        invalid={
                          isEmpty(this.state.errors.passport) ? false : true
                        }
                        placeholder="Passport Number..."
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group
                      label="Passport Expiry Date YYYY-MM-DD"
                      isRequired
                    >
                      <Form.MaskedInput
                        value={this.state.formData.passportExpiry}
                        placeholder="0000-00-00"
                        name="passportExpiry"
                        feedback={
                          isEmpty(this.state.errors.passportExpiry)
                            ? ""
                            : this.state.errors.passportExpiry
                        }
                        invalid={
                          isEmpty(this.state.errors.passportExpiry)
                            ? false
                            : true
                        }
                        onChange={this.onChange}
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </Form.Group>

                    <Form.Group function label="Primary Program">
                      <Form.Select
                        value={
                          !isEmpty(this.state.formData)
                            ? this.state.formData.program
                            : ""
                        }
                        name="program"
                        feedback={
                          isEmpty(this.state.errors.program)
                            ? ""
                            : this.state.errors.program
                        }
                        invalid={
                          isEmpty(this.state.errors.program) ? false : true
                        }
                        onChange={this.onChange}
                      >
                        <option value="">Select a Program</option>
                        {finalList}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group label="Program Secondary">
                      <Form.Select
                        value={
                          !isEmpty(this.state.formData)
                            ? this.state.formData.program_secondary
                            : ""
                        }
                        name="program_secondary"
                        feedback={
                          isEmpty(this.state.errors.program_secondary)
                            ? ""
                            : this.state.errors.program_secondary
                        }
                        invalid={
                          isEmpty(this.state.errors.program_secondary)
                            ? false
                            : true
                        }
                        onChange={this.onChange}
                      >
                        <option value="">Select a secondary program</option>
                        {finalList}
                      </Form.Select>
                    </Form.Group>
                  </Card.Body>
                </Card>
                <Card
                  title="Emergency Contact"
                  RootComponent={Form}
                  footer={
                    <div className="d-flex">
                      <Button
                        type="submit"
                        onClick={this.handleFormSubmission}
                        color="primary"
                        className="ml-auto"
                      >
                        Save and continue
                      </Button>
                    </div>
                  }
                >
                  <Card.Body>
                    <Form.Group label="Name" isRequired>
                      <Form.Input
                        value={this.state.formData.name_emergency}
                        name="name_emergency"
                        feedback={
                          isEmpty(this.state.errors.name_emergency)
                            ? ""
                            : this.state.errors.name_emergency
                        }
                        invalid={
                          isEmpty(this.state.errors.name_emergency)
                            ? false
                            : true
                        }
                        onChange={this.onChange}
                        placeholder="Person name"
                      />
                    </Form.Group>
                    <Form.Group label="Phone Number" isRequired>
                      <Form.Input
                        value={this.state.formData.phone_emergency}
                        name="phone_emergency"
                        feedback={
                          isEmpty(this.state.errors.phone_emergency)
                            ? ""
                            : this.state.errors.phone_emergency
                        }
                        invalid={
                          isEmpty(this.state.errors.name_emergency)
                            ? false
                            : true
                        }
                        onChange={this.onChange}
                        placeholder="Phone number"
                      />
                    </Form.Group>
                    <Form.Group label="Email Address" isRequired>
                      <Form.Input
                        value={this.state.formData.email_emergency}
                        name="email_emergency"
                        feedback={
                          isEmpty(this.state.errors.email_emergency)
                            ? ""
                            : this.state.errors.email_emergency
                        }
                        invalid={
                          isEmpty(this.state.errors.email_emergency)
                            ? false
                            : true
                        }
                        onChange={this.onChange}
                        placeholder="Email Address"
                      />
                    </Form.Group>
                    <Form.Group label="Your relationship" isRequired>
                      <Form.Input
                        value={this.state.formData.relation_emergency}
                        name="relation_emergency"
                        feedback={
                          isEmpty(this.state.errors.relation_emergency)
                            ? ""
                            : this.state.errors.relation_emergency
                        }
                        invalid={
                          isEmpty(this.state.errors.relation_emergency)
                            ? false
                            : true
                        }
                        onChange={this.onChange}
                        placeholder="Example: Brother"
                      />
                    </Form.Group>
                    <Form.Group label="Is person fluent in English?" isRequired>
                      <Form.Select
                        value={this.state.formData.english_emergency}
                        name="english_emergency"
                        feedback={
                          isEmpty(this.state.errors.english_emergency)
                            ? ""
                            : this.state.errors.english_emergency
                        }
                        invalid={
                          isEmpty(this.state.errors.english_emergency)
                            ? false
                            : true
                        }
                        onChange={this.onChange}
                      >
                        <option value={null} disabled selected>
                          Choose your answer
                        </option>
                        <option
                          selected={
                            this.state.formData.english_emergency == "Yes"
                              ? "selected"
                              : ""
                          }
                          value="Yes"
                        >
                          Yes
                        </option>
                        <option
                          selected={
                            this.state.formData.english_emergency == "No"
                              ? "selected"
                              : ""
                          }
                          value="No"
                        >
                          No
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Container>
        </div>
      </SiteWrapper>
    );
  }
}

Step1.propTypes = {
  handleApplicationSubmissions: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
  programs: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  application: state.application,
  programs: state.programs,
  errors: state.errors,
  settings: state.settings,
});
export default connect(
  mapStateToProps,
  {
    handleApplicationSubmissions,
    programsList,
  }
)(withRouter(withLastLocation(Step1)));
