// Error Actions
export const GET_ERRORS = "GET_ERRORS";

// User actions
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const REGISTER_NEW_USER = "REGISTER_NEW_USER";
export const USER_LIST = "USER_LIST";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const SET_PROFILE = "SET_PROFILE";

// Application actions
export const GET_ALL_APPLICATIONS = "GET_ALL_APPLICATIONS";
export const GET_CURRENT_APPLICATION = "GET_CURRENT_APPLICATION";
export const SET_CURRENT_APPLICATION = "SET_CURRENT_APPLICATION";
export const CURRENT_APP_STEP1 = "CURRENT_APP_STEP1";
export const CURRENT_APP_STEP2 = "CURRENT_APP_STEP2";
export const CURRENT_APP_STEP3 = "CURRENT_APP_STEP3";
export const CURRENT_APP_STEP4 = "CURRENT_APP_STEP4";
export const APPLICATION_COMMENT = "APPLICATION_COMMENT";
export const APP_STATUS = "APP_STATUS";
export const APP_AGENT = "APP_AGENT";

// Program actions
export const GET_ALL_PROGRAMS = "GET_ALL_PROGRAMS";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_AVAILABLE_PROGRAMS = "GET_AVAILABLE_PROGRAMS";

// Payment actions
export const GET_PAYMENTS = "GET_PAYMENTS";
export const SET_PAYMENTS = "SET_PAYMENTS";

// Announcement actions
export const MAKE_ANNOUNCEMENT = "MAKE_ANNOUNCEMENT";
export const GET_ANNOUNCEMENT = "GET_ANNOUNCEMENT";

// Settings actions
export const SET_SETTINGS = "SET_SETTINGS";
export const GET_SETTINGS = "GET_SETTINGS";

// Terms actions
export const GET_TERMS = "GET_TERMS";
