import { create } from "zustand";

/**
 * @typedef {{
 *  appId: number | string | null
 *  isEdit: boolean
 *  changeAppId: (v: number | string) => ()
 *  changeIsEdit: (v: boolean) => ()
 * }} AppStore
 *
 * @type {import('zustand').UseBoundStore<import('zustand').StoreApi<AppStore>}
 */
export const useAppStore = create(set => ({
  appId: null,
  isEdit: false,
  changeAppId: newId => set({ appId: newId }),
  changeIsEdit: newState => set({ isEdit: newState }),
}));
