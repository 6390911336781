import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import SiteWrapper from "../../SiteWrapper.react";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Center,
  Container,
  Divider,
  FileInput,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  ScrollArea,
  Select,
  Text,
  TextInput,
  Textarea,
  ThemeIcon,
  Timeline,
  Title,
} from "@mantine/core";
import {
  useAddComment,
  useAddInternalNotes,
  useChangeAgent,
  useClearNotifs,
  useDeleteAppDocument,
  useFetchAllActive,
  useGetOneApplication,
  useUpdateAppStatus,
  useUploadAppDocument,
  useWithdrawApplication,
} from "./api";
import RenderIcon from "./RenderIcon";
import { documentTypes } from "./constants";
import {
  IconCheck,
  IconCirclePlus,
  IconCircleX,
  IconDownload,
  IconFile,
  IconInfoCircle,
  IconMessage2,
  IconReplace,
  IconTrash,
  IconUser,
  IconX,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { APP_STATUS, applicationStatus } from "../../utils/applicationStatus";
import queryClient from "../../lib/queryClient";
import { useHistory, useLocation } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { openInNewTab } from "../../utils/downloadFile2.js";
import AppEnglish from "./AppEnglish.jsx";
import { useAppStore } from "../../lib/zustandStore.js";
import { renderFor } from "../../utils/renderComp.js";
import Axios from "axios";
import config from "../../config/config.js";
import ProgramPicker from "./ProgramPicker.jsx";

const AppView = () => {
  const location = useLocation();
  const { data, isLoading } = useGetOneApplication(
    parseInt(location.pathname.split("/")[3])
  );
  const userInfo = queryClient.getQueryData(["myInfo"]);

  const timeline = useMemo(
    timelineItem => {
      return data?.applicationLogs?.filter(
        timelineItem => timelineItem.action !== 3
      );
    },
    [data]
  );
  const internalNotes = useMemo(
    () => {
      return data?.applicationLogs?.filter(note => note.action === 3);
    },
    [data]
  );

  const changeAppId = useAppStore(state => state.changeAppId);

  // initialize the appId. this is used to change the program
  useEffect(
    () => {
      changeAppId(data?.id);
    },
    [data?.id]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    window.location.href = "/";
  }

  return (
    <SiteWrapper>
      <Container size="xl" pt="xl">
        <Center>
          <Box>
            <Flex gap="md">
              <Box
                sx={{
                  flex: "3",
                }}
              >
                <AppInfo data={data} />
                <AppEnglish data={data.ApplicationLanguage} />
                <AppAcademics data={data.ApplicationAcademics} />
              </Box>
              <Box
                sx={{
                  flex: "2",
                }}
              >
                <AppTimeline
                  appId={data.id}
                  data={timeline}
                  status={data.status}
                />
                {(userInfo.permission === 0 || userInfo.permission === 2) && (
                  <AppInternalNotes
                    appId={data.id}
                    status={data.status}
                    data={internalNotes}
                  />
                )}
                <AppFiles data={data.ApplicationDocuments} appId={data.id} />
              </Box>
            </Flex>
          </Box>
        </Center>
      </Container>
    </SiteWrapper>
  );
};

/**
 * @param {Object} props
 * @param {import("../../types").Application} props.data
 * @returns {JSX.Element}
 */
const AppInfo = ({ data }) => {
  const myInfo = queryClient.getQueryData("myInfo");

  const { data: allActive, isLoading } = useFetchAllActive();
  const [agent, setAgent] = useState(data.User?.id);

  const [opened, { open, close }] = useDisclosure(false);

  const { mutate } = useChangeAgent();

  const handleAgent = useCallback((aid, agent) => {
    setAgent(agent);
    mutate({ aid, agent });
    notifications.show({
      title: "Agent changed!",
      color: "green",
      icon: <IconCheck />,
    });
  });

  return (
    <>
      <Modal opened={opened} onClose={close} title="Edit Program">
        <ProgramPicker close={close} />
      </Modal>
      <Paper shadow="xs" px="md" py="xl" mb="xl">
        <Title size="h3">
          <Flex
            sx={{
              justifyContent: "space-between",
            }}
          >
            Application Information
            <AppOptions aid={data.id} status={data.status} />
          </Flex>
        </Title>
        <Divider my="lg" />
        <Grid>
          <Grid.Col span={3}>Status</Grid.Col>
          <Grid.Col span={9}>
            <Badge
              color={
                Object.entries(APP_STATUS).filter(
                  e => e[1].value === data.status
                )[0][1]?.color
              }
            >
              {applicationStatus(data.status).statusText}
            </Badge>
          </Grid.Col>
          <Grid.Col span={3}>Assigned ID</Grid.Col>
          <Grid.Col span={9}>EDUS01{data.id}</Grid.Col>
          <Grid.Col span={3}>Salutation</Grid.Col>
          <Grid.Col span={9}>{data.salutation}</Grid.Col>
          <Grid.Col span={3}>First Name</Grid.Col>
          <Grid.Col span={9}>{data.firstName} </Grid.Col>
          <Grid.Col span={3}>Middle Name</Grid.Col>
          <Grid.Col span={9}>{data.middleName}</Grid.Col>
          <Grid.Col span={3}>Last Name</Grid.Col>
          <Grid.Col span={9}>{data.lastName}</Grid.Col>
          <Grid.Col span={3}>Date of Birth</Grid.Col>
          <Grid.Col span={9}>{data.DOB}</Grid.Col>
          <Grid.Col span={3}>Nationality</Grid.Col>
          <Grid.Col span={9}>{data.nationality}</Grid.Col>
          <Grid.Col span={3}>Passport Number</Grid.Col>
          <Grid.Col span={9}>{data.passport}</Grid.Col>
          <Grid.Col span={3}>Email</Grid.Col>
          <Grid.Col span={9}>{data.email}</Grid.Col>
          <Grid.Col span={3}>Telephone</Grid.Col>
          <Grid.Col span={9}>{data.telephone}</Grid.Col>
          <Grid.Col span={3}>Mailing Address</Grid.Col>
          <Grid.Col span={9}>{data.MailingAddress}</Grid.Col>
          <Grid.Col span={3}>City</Grid.Col>
          <Grid.Col span={9}>{data.city}</Grid.Col>
          <Grid.Col span={3}>Province/State</Grid.Col>
          <Grid.Col span={9}>{data.province}</Grid.Col>
          <Grid.Col span={3}>Country</Grid.Col>
          <Grid.Col span={9}>{data.country}</Grid.Col>
          <Grid.Col span={3}>Primary Program</Grid.Col>
          <Grid.Col span={9}>
            <strong>{data.ProgramsAvailable?.Program?.institution}</strong> |{" "}
            {data.ProgramsAvailable?.Term?.name} -{" "}
            {data.ProgramsAvailable?.Program?.code}{" "}
            <Button size="xs" variant="outline" onClick={open}>
              Change
            </Button>
          </Grid.Col>
          <Grid.Col span={3}>Secondary Program</Grid.Col>
          <Grid.Col span={9}>
            {data.program_second && (
              <>
                <strong>{data.program_second?.Program?.institution}</strong> |{" "}
                {data.program_second?.Term?.name} -{" "}
                {data.program_second?.Program?.code}
              </>
            )}
          </Grid.Col>
          <Grid.Col span={3}>Student ID</Grid.Col>
          <Grid.Col span={9}>{data.studentId}</Grid.Col>
          {renderFor([0, 2], true, myInfo?.permission) && (
            <>
              <Grid.Col span={3}>Agent</Grid.Col>
              <Grid.Col span={9}>
                <Select
                  value={agent}
                  searchable
                  onChange={e => handleAgent(data.id, e)}
                  data={
                    allActive?.map(e => ({ value: e.id, label: e.name })) || []
                  }
                />
              </Grid.Col>
            </>
          )}
        </Grid>
      </Paper>
    </>
  );
};

/**
 * @param {Object} props
 * @param {import("../../types").ApplicationAcademic[]} props.data
 * @return {JSX.Element}
 */
const AppAcademics = ({ data }) => {
  return (
    <Paper shadow="xs" px="md" py="xl" mb="xl">
      <Title size="h3">Academics</Title>
      <Divider my="lg" />
      {data.map((e, i) => (
        <div key={i}>
          <Grid>
            <Grid.Col span={3}>Institute Name</Grid.Col>
            <Grid.Col span={9}>{e.instituteName}</Grid.Col>
            <Grid.Col span={3}>First Year</Grid.Col>
            <Grid.Col span={9}>{e.firstYear}</Grid.Col>
            <Grid.Col span={3}>Last Year</Grid.Col>
            <Grid.Col span={9}>{e.lastYear}</Grid.Col>
            <Grid.Col span={3}>City</Grid.Col>
            <Grid.Col span={9}>{e.city}</Grid.Col>
            <Grid.Col span={3}>Province/State</Grid.Col>
            <Grid.Col span={9}>{e.province}</Grid.Col>
            <Grid.Col span={3}>Country</Grid.Col>
            <Grid.Col span={9}>{e.country}</Grid.Col>
            <Grid.Col span={3}>Backlogs</Grid.Col>
            <Grid.Col span={9}>{e.backlogs}</Grid.Col>
            <Grid.Col span={3}>Bachelor Percentage</Grid.Col>
            <Grid.Col span={9}>{e.percentage}</Grid.Col>
          </Grid>
          {i !== data.length - 1 && <Divider my="md" />}
        </div>
      ))}
    </Paper>
  );
};

/**
 * @param {Object} props
 * @param {import("../../types").ApplicationDocument[]} props.data
 * @param {number} props.appId
 * @return {JSX.Element}
 */
const AppFiles = ({ data, appId }) => {
  const myInfo = queryClient.getQueryData("myInfo");
  const [opened, { open, close }] = useDisclosure(false);

  const { mutate: deleteFile } = useDeleteAppDocument();

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title=<strong>Upload Document</strong>
      >
        <FileUploader appId={appId} />
      </Modal>
      <Paper shadow="xs" px="md" py="xl" mb="xl">
        <Flex justify="space-between">
          <Title size="h3">Files</Title>
          {renderFor([0, 2], true, myInfo.permission) && (
            <Button onClick={open}>Upload File</Button>
          )}
        </Flex>
        <Divider my="lg" />
        <Grid>
          {data?.map((file, i) => (
            <Fragment key={i}>
              <Grid.Col span={5}>
                {<RenderIcon fileType={file?.extension} />}{" "}
                <strong>
                  {file.name}.{file.extension}
                </strong>
              </Grid.Col>
              <Grid.Col span={5}>
                {documentTypes.filter(
                  e => e.value === file.documentType.toString()
                )[0]?.label || "Other"}
              </Grid.Col>
              <Grid.Col span={2}>
                <Flex>
                  <ActionIcon
                    variant="transparent"
                    onClick={evt => openInNewTab(evt, file)}
                  >
                    <IconDownload />
                  </ActionIcon>
                  {renderFor([0, 2], true, myInfo?.permission) && (
                    <ActionIcon
                      variant="transparent"
                      onClick={() => deleteFile(file.code)}
                    >
                      <IconTrash />
                    </ActionIcon>
                  )}
                </Flex>
              </Grid.Col>
            </Fragment>
          ))}
        </Grid>
      </Paper>
    </>
  );
};

const FileUploader = ({ appId }) => {
  const [file, setFile] = useState(/** @type {File | null} */ null);
  const [docType, setDocType] = useState(/** @type {string | null} */ null);

  const fileUpload = useUploadAppDocument();

  const handleUploadFile = async () => {
    // if null, don't try upload
    if (file === null) {
      return;
    }
    if (docType === null) {
      alert("Please select a document type");
      return;
    }

    if (docType === "6") {
      const studentId = file.name.substring(0, file.name.indexOf("_"));
      await Axios.post(`${config.server}${config.api.applicationStudentId}`, {
        id: appId,
        studentId,
      });
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentType", docType);
    formData.append("application", appId);

    try {
      await fileUpload.mutateAsync(formData);

      await notifications.show({
        title: "File uploaded!",
        color: "teal",
        icon: <IconCheck />,
      });
    } catch (err) {
      console.log(err);
      notifications.show({
        title: "Something went wrong upload your file!",
        color: "red",
        icon: <IconX />,
      });
    }
    await queryClient.invalidateQueries(["application", appId]);

    // on successful upload reset the file
    setFile(null);
  };

  return (
    <>
      <Select
        data={documentTypes}
        onChange={setDocType}
        label="Document Type"
        withinPortal={true}
      />

      <FileInput
        label="Select File"
        accept="image/*, application/pdf"
        onChange={file => setFile(file)}
        value={file}
        mt="lg"
      />
      <Button
        mt="md"
        onClick={() => handleUploadFile()}
        loading={fileUpload.isLoading}
      >
        Upload
      </Button>
    </>
  );
};

const appLogs = {
  "2301": {
    title: "",
    bullet: (
      <ThemeIcon radius="xl" color="yellow">
        <IconInfoCircle />
      </ThemeIcon>
    ),
  },
  "2302": {
    title: "Application submitted",
    bullet: (
      <ThemeIcon radius="xl" color="teal">
        <IconCirclePlus />
      </ThemeIcon>
    ),
  },
  "2303": {
    title: "Agent changed",
    bullet: (
      <ThemeIcon radius="xl" color="teal">
        <IconUser size={20} />
      </ThemeIcon>
    ),
  },
  "2305": {
    title: "Document uploaded",
    bullet: (
      <ThemeIcon radius="xl" color="blue">
        <IconFile size={20} />
      </ThemeIcon>
    ),
  },
  "2306": {
    title: "Program changed",
    bullet: (
      <ThemeIcon radius="xl" color="blue">
        <IconReplace size={20} />
      </ThemeIcon>
    ),
  },
  "2310": {
    title: "",
    bullet: (
      <ThemeIcon radius="xl" color="teal">
        <IconMessage2 size={20} />
      </ThemeIcon>
    ),
  },
  "2311": {
    title: "",
    bullet: (
      <ThemeIcon radius="xl" color="teal">
        <IconMessage2 size={20} />
      </ThemeIcon>
    ),
  },
  "2323": {
    title: "Application deleted",
    bullet: (
      <ThemeIcon radius="xl" color="teal">
        <IconCircleX />
      </ThemeIcon>
    ),
  },
  "3323": {
    title: "Announcement changed",
    bullet: (
      <ThemeIcon radius="xl" color="teal">
        <IconCirclePlus />
      </ThemeIcon>
    ),
  },
};

/**
 * @param {import("../../types").ApplicationLog} log
 */
const ContentGenerator = log => {
  const { code, message, User } = log;

  let newMessage = message;
  switch (code) {
    case 2301: {
      return newMessage;
    }
    case 2302: {
      newMessage = `${User.name} has submitted a new application`;
      return newMessage;
    }
    case 2305: {
      let tmp = JSON.parse(message);
      newMessage = `${User.name} uploaded a new file: ${tmp.name}`;
      return newMessage;
    }
    default: {
      return newMessage;
    }
  }
};

/**
 * @param {Object} props
 * @param {import("../../types.d.ts").ApplicationLog[]} props.data
 * @return {JSX.Element}
 */
const AppTimeline = ({ data, status, appId }) => {
  const [comment, setComment] = useState("");
  const { mutateAsync } = useAddComment();

  const sendComment = async () => {
    const aid = appId;
    const commentData = { application: aid, comment: comment };

    await mutateAsync(commentData);
    setComment("");
  };

  return (
    <Paper shadow="xs" px="md" py="xl" mb="xl">
      <Title size="h3">
        <Flex
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Box>Timeline</Box>
        </Flex>
      </Title>
      <Divider my="lg" />
      <Flex align="center" gap="sm">
        <Box sx={{ flex: "5" }}>
          <Textarea
            placeholder="Add a comment..."
            pb="md"
            value={comment}
            onChange={e => setComment(e.currentTarget.value)}
          />
        </Box>
        <Box sx={{ flex: "1" }}>
          <Button fullWidth={true} mb="sm" onClick={sendComment}>
            Send
          </Button>
        </Box>
      </Flex>
      <ScrollArea
        dir="ltr"
        offsetScrollbars={true}
        sx={{
          maxHeight: "500px",
          overflow: "auto",
        }}
      >
        {/*This 1px margin fixes bullets being cut off*/}
        <Timeline mt="1px" ml="1px" bulletSize={24} lineWidth={2} active={1}>
          {data.map((e, i) => {
            let newMessage = ContentGenerator(e);

            return (
              <Timeline.Item
                title={
                  <Flex sx={{ justifyContent: "space-between" }}>
                    <Text>
                      {/*
                        Not a very elegant solution but will do for now
                      */}
                      {e.code === 2310 && <>{e.User.name}</>}
                      {appLogs[`${e.code}`]?.title}{" "}
                      {e.code === 2301 &&
                        Object.values(APP_STATUS).filter(
                          f => f.value === e.status
                        )[0]?.label}
                    </Text>
                    <Text ml={"xs"} size="xs">
                      {dayjs(e.createdAt).format("YYYY-MM-DD")}
                    </Text>
                  </Flex>
                }
                bullet={appLogs[`${e.code}`]?.bullet}
                key={i}
              >
                <Text color="dimmed">{newMessage}</Text>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </ScrollArea>
    </Paper>
  );
};

const AppInternalNotes = ({ appId, status, data }) => {
  const [note, setNote] = useState("");
  const addNote = useAddInternalNotes();

  const sendNote = () => {
    const aid = appId;
    const notesData = { application: aid, note: note, status };

    addNote.mutate(notesData);
    setNote("");
  };

  return (
    <Paper shadow="xs" px="md" py="xl" mb="xl">
      <Title size="h3">
        <Flex sx={{ justifyContent: "space-between" }}>
          <Box>Internal Notes</Box>
        </Flex>
      </Title>
      <Divider my="lg" />
      <Flex align="center" gap="sm">
        <Box sx={{ flex: "5" }} style={{ whiteSpace: "pre-wrap" }}>
          <Textarea
            placeholder="Add a note..."
            pb="md"
            value={note}
            onChange={e => setNote(e.currentTarget.value)}
          />
        </Box>
        <Box sx={{ flex: "1" }}>
          <Button fullWidth={true} mb="sm" onClick={sendNote}>
            Send
          </Button>
        </Box>
      </Flex>
      <ScrollArea
        dir="ltr"
        offsetScrollbars={true}
        sx={{ maxHeight: "500px", overflow: "auto" }}
      >
        {/*This 1px margin fixes bullets being cut off*/}
        <Timeline mt="1px" ml="1px" bulletSize={24} lineWidth={2} active={1}>
          {data.map((e, i) => {
            let newMessage = ContentGenerator(e);

            return (
              <Timeline.Item
                title={
                  <Flex sx={{ justifyContent: "space-between" }}>
                    <Text>{e.code === 2311 && <>{e.User.name}</>}</Text>
                    <Text ml={"xs"} size="xs">
                      {dayjs(e.createdAt).format("YYYY-MM-DD")}
                    </Text>
                  </Flex>
                }
                bullet={appLogs[`${e.code}`]?.bullet}
                key={i}
              >
                <Text color="dimmed" style={{ whiteSpace: "pre-wrap" }}>
                  {newMessage}
                </Text>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </ScrollArea>
    </Paper>
  );
};

const statusSelectOptions = [
  {
    value: APP_STATUS.UNDER_REVIEW.value.toString(),
    label: APP_STATUS.UNDER_REVIEW.label,
  },
  {
    value: APP_STATUS.REQUIRE_CHANGES.value.toString(),
    label: APP_STATUS.REQUIRE_CHANGES.label,
  },
  {
    value: APP_STATUS.DECLINED.value.toString(),
    label: APP_STATUS.DECLINED.label,
  },
  {
    value: APP_STATUS.PREREGISTERED.value.toString(),
    label: APP_STATUS.PREREGISTERED.label,
  },
  {
    value: APP_STATUS.APPROVED.value.toString(),
    label: APP_STATUS.APPROVED.label,
  },
  {
    value: APP_STATUS.DEFERRED.value.toString(),
    label: APP_STATUS.DEFERRED.label,
  },
  {
    value: APP_STATUS.PAYMENT_RECEIVED.value.toString(),
    label: APP_STATUS.PAYMENT_RECEIVED.label,
  },
  {
    value: APP_STATUS.VISA_REQUESTED.value.toString(),
    label: APP_STATUS.VISA_REQUESTED.label,
  },
  {
    value: APP_STATUS.DEFER_REQUESTED.value.toString(),
    label: APP_STATUS.DEFER_REQUESTED.label,
  },
  {
    value: APP_STATUS.PAID.value.toString(),
    label: APP_STATUS.PAID.label,
  },
  {
    value: APP_STATUS.REVOKED.value.toString(),
    label: APP_STATUS.REVOKED.label,
  },
  {
    value: APP_STATUS.REFUNDED.value.toString(),
    label: APP_STATUS.REFUNDED.label,
  },
  {
    value: APP_STATUS.CLOSED.value.toString(),
    label: APP_STATUS.CLOSED.label,
  },
];

/**
 * The UI meant to replace changing status, closing, edit, clear notifs, etc.
 *
 * @param {Object} obj
 * @param {number} obj.aid
 *
 * @return {JSX.Element}
 */
const AppOptions = ({ aid, status }) => {
  const myInfo = queryClient.getQueryData("myInfo");
  const history = useHistory();

  const [openedWithdraw, { open, close }] = useDisclosure(false);
  const [withdrawInput, setWithdrawInput] = useState("");

  const { mutateAsync: withdrawApp } = useWithdrawApplication();

  const [openedStatus, { open: openS, close: closeS }] = useDisclosure(false);
  const [statusSelect, setStatusSelect] = useState("");
  const [statusMsg, setStatusMsg] = useState("");

  const { mutateAsync } = useUpdateAppStatus();
  const { mutate: clearNotifs } = useClearNotifs();

  const [file, setFile] = useState(/** @type {File | null} */ null);
  const [openedVisa, { open: openV, close: closeV }] = useDisclosure(false);

  const fileUpload = useUploadAppDocument();

  const changeAppId = useAppStore(state => state.changeAppId);
  const changeIsEdit = useAppStore(state => state.changeIsEdit);

  const goToEdit = async () => {
    // TODO: Change to proper route
    history.push("/form");
    changeAppId(aid);
    changeIsEdit(true);
  };

  const withdraw = async () => {
    if (withdrawInput !== aid.toString()) {
      notifications.show({
        title: "incorrect!",
        color: "red",
        icon: <IconX />,
      });
      return;
    }
    const res = await withdrawApp(aid);

    if (res.success === true) {
      window.location.href = "/";
    } else {
      notifications.show({
        title: "Unable to withdraw application",
        color: "red",
        icon: <IconX />,
      });
    }

    close();
  };

  /**
   * @param {string} statusValue
   */
  const changeStatus = statusValue => {
    mutateAsync({
      status: parseInt(statusValue),
      application: aid,
      message: statusMsg,
    });
    notifications.show({
      title: "Status changed!",
      color: "teal",
      icon: <IconCheck />,
    });
    closeS();
  };

  const handleUploadFile = async () => {
    // if null, don't try upload
    if (file === null) {
      return;
    }

    const docType = 5;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentType", docType);
    formData.append("application", aid);

    try {
      await fileUpload.mutateAsync(formData);
      await mutateAsync({ application: aid, message: "", status: 11 });

      notifications.show({
        title: "File uploaded!",
        color: "teal",
        icon: <IconCheck />,
      });
      closeV();
    } catch (err) {
      console.log(err);
      notifications.show({
        title: "Something went wrong upload your file!",
        color: "red",
        icon: <IconX />,
      });
    }
    await queryClient.invalidateQueries(["application", aid]);

    // on successful upload reset the file
    setFile(null);
  };

  return (
    <>
      <Modal opened={openedVisa} title="Visa Approved" onClose={closeV}>
        <FileInput
          label="Submit your visa document below"
          accept="image/*, application/pdf"
          onChange={file => setFile(file)}
          value={file}
          mt="lg"
        />
        <Group position="right" mt="md">
          <Button onClick={closeV} color="red">
            Cancel
          </Button>
          <Button onClick={handleUploadFile}>Upload</Button>
        </Group>
      </Modal>
      <Modal
        opened={openedWithdraw}
        onClose={close}
        title=<strong>Withdraw Application</strong>
      >
        <div>
          <Text>
            This application is currently under process. Withdrawing an
            application cannot be reversed.
          </Text>
          <Text mt="sm">
            Type <strong>{aid}</strong> to withdraw
          </Text>
          <TextInput
            value={withdrawInput}
            onChange={e => setWithdrawInput(e.target.value)}
            my="sm"
          />
          <Group position="right" mt="md">
            <Button onClick={close} color="red">
              Cancel
            </Button>
            <Button type="submit" onClick={withdraw} variant="outline">
              Withdraw
            </Button>
          </Group>
        </div>
      </Modal>

      <Modal
        opened={openedStatus}
        onClose={() => {
          closeS();
          setStatusSelect("");
          setStatusMsg("");
        }}
        title="Change Status"
      >
        <Select
          data={statusSelectOptions}
          value={statusSelect}
          onChange={setStatusSelect}
          withinPortal={true}
        />
        {statusSelect !== "" && (
          <Textarea
            label="Comments are internal unless the status is REQUIRE CHANGES"
            placeholder="Add a comment..."
            py="md"
            value={statusMsg}
            onChange={e => setStatusMsg(e.currentTarget.value)}
          />
        )}
        <Group position="right" mt="md">
          <Button onClick={closeS} color="red">
            Cancel
          </Button>
          <Button type="submit" onClick={() => changeStatus(statusSelect)}>
            Change
          </Button>
        </Group>
      </Modal>

      <Flex gap="xs">
        {/* Agents can edit for INCOMPLETE and REQUIRE_CHANGES */}
        {[
          APP_STATUS.INCOMPLETE.value,
          APP_STATUS.REQUIRE_CHANGES.value,
        ].includes(status) &&
          myInfo?.permission === 1 && (
            <Button size="xs" color="cyan" onClick={() => goToEdit()}>
              Edit
            </Button>
          )}

        {/* Staff can edit whenever */}
        {renderFor([0, 2], true, myInfo?.permission) && (
          <Button size="xs" color="cyan" onClick={() => goToEdit()}>
            Edit
          </Button>
        )}

        <Button size="xs" color="red" onClick={open}>
          Withdraw
        </Button>
        {renderFor([0, 2], true, myInfo?.permission) && (
          <>
            <Button size="xs" color="violet" onClick={openS}>
              Edit Status
            </Button>
            <Button size="xs" onClick={() => clearNotifs(aid)}>
              Clear Notifications
            </Button>
          </>
        )}
        {[APP_STATUS.APPROVED.value, APP_STATUS.PAID.value].includes(status) &&
          renderFor([1], true, myInfo?.permission) && (
            <Button size="xs" color="blue" onClick={openV}>
              Visa Approved
            </Button>
          )}
      </Flex>
    </>
  );
};

export default AppView;
