import axios from "axios";
import config from "../config/config";
/**
 * @description Download a file from the server and open in a new tab
 * @param {React.MouseEvent} evt - On click event. This is currently unused
 * @param {import("../types").ApplicationDocument} file
 * @param {boolean} publicFile - If the file is public or not. This will change the download url. as of 2024-06-17 this should always be false because there is no meaningful difference in the code atm
 */
export const openInNewTab = async (_evt, file, publicFile = false) => {
  try {
    let api = config.api.downloadFile;
    if (publicFile === true) {
      api = "/documents/public/download";
    }

    const res = await axios.get(
      `${config.server}${config.api.downloadFile}/?code=${file.code}&ext=${
        file.extension
      }`,
      {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const type = res.headers["content-type"];
    const blob = new Blob([res.data], { type: type, encoding: "UTF-8" });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.target = "_blank";

    link.click();
  } catch (error) {
    alert("Error downloading file");
  }
};
