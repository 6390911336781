import { GET_PAYMENTS, SET_PAYMENTS } from "../actions/types";
import isEmpty from "../utils/is-empty";

// Set the initial state
const initialState = {
  paymentList: {},
};

// Reducer function
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENTS:
      return {
        ...state,
        paymentList: action.payload,
      };
    case SET_PAYMENTS:
      return {
        ...state,
      };
    default:
      return state;
  }
}
