import { useMutation } from "react-query";
import axios from "axios";
import config from "../config/config";

/**
 *
 * @param {Object} payload
 * @param {string} payload.username
 * @param {string} payload.companyName
 * @param {string} payload.password
 * @returns {Promise<string> | Error}
 */
const registerAgent = async payload => {
  const res = await axios.post(
    `${config.server}${config.api.agentRegister}`,
    payload
  );

  return res.data;
};

export const useRegisterAgent = () => {
  return useMutation(registerAgent);
};
