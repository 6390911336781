import React, { useEffect } from "react";
import {
  Divider,
  Grid,
  Input,
  Paper,
  Select,
  TextInput,
  Title,
} from "@mantine/core";
import { useGetOneApplication } from "./api";
import { useAppStore } from "../../lib/zustandStore";
import { useId } from "@mantine/hooks";
import { IMaskInput } from "react-imask";

const testTypes = [
  {
    label: "IELTS",
    value: 0,
  },
  {
    label: "TOEFL",
    value: 1,
  },
  {
    label: "PTE",
    value: 2,
  },
];

/**
 * @param {{ step: number, form: Object}} props
 */
const Step2 = ({ step, form }) => {
  const appId = useAppStore(state => state.appId);

  // For masked input DOB
  const id = useId();

  /** @type {{data: import("../../types").Application, isLoading: boolean}} */
  const { data, isLoading } = useGetOneApplication(appId);

  useEffect(
    () => {
      if (data) {
        form.setValues(values => ({
          ...values,
          language: data.ApplicationLanguage
            ? data.ApplicationLanguage
            : values.language,
        }));
      }
    },
    [data]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Paper shadow="xs" px="md" py="xl">
        <Title size="h3">Language Proficiency</Title>
        <Divider my="lg" />
        <Grid>
          <Grid.Col>
            <TextInput
              required
              label="Mother Tongue"
              {...form.getInputProps("language.mother_tongue")}
              placeholder="French"
            />
          </Grid.Col>
          <Grid.Col>
            <Select
              required
              label="English Language Test"
              data={testTypes}
              {...form.getInputProps("language.testTaken")}
            />
          </Grid.Col>

          <Grid.Col>
            <Input.Wrapper required id={id} label="Test Taken On">
              <Input
                {...form.getInputProps("language.takenOn")}
                id={id}
                component={IMaskInput}
                mask="0000-00-00"
                placeholder="YYYY-MM-DD"
              />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required
              label="Overall Band Score"
              {...form.getInputProps("language.totalScore")}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required
              label="Listening Score"
              {...form.getInputProps("language.listening")}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required
              label="Reading Score"
              {...form.getInputProps("language.reading")}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required
              label="Writing Score"
              {...form.getInputProps("language.writing")}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required
              label="Speaking Score"
              {...form.getInputProps("language.speaking")}
            />
          </Grid.Col>
        </Grid>
      </Paper>
    </>
  );
};

export default Step2;
