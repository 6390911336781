import React, { useState } from "react";
import SiteWrapper from "../../SiteWrapper.react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Form,
  Grid,
  Icon,
  Page,
  Table,
  Text,
} from "tabler-react";
import Pagination from "rc-pagination";
import axios from "axios";
import config from "../../config/config";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { applicationStatus } from "../../utils/applicationStatus";
import queryString from "query-string";
import { renderFor } from "../../utils/renderComp";
import { Select } from "@mantine/core";
// import SearchFilter from "./SearchFilter";

// https://react-query.tanstack.com/guides/paginated-queries

const statusOptions = {
  0: "Incomplete",
  1: "Pending",
  41: "Under Review",
  42: "Require Changes",
  44: "Signed",
  45: "Partner",
  46: "Terminated",
  47: "Expired",
  50: "On Hold",
};

const AgentApps = props => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [staffList, setStaffList] = useState([]);

  const fetchAgentApps = async (page, filters) => {
    const stringified = queryString.stringify(filters);

    const res = await axios.get(
      `${config.server}${config.api.agentApps}?page=${page}&${stringified}`
    );
    return res.data;
  };

  const { isLoading, data, refetch } = useQuery(
    ["agentapps", page],
    () => fetchAgentApps(page, filters),
    { keepPreviousData: true }
  );

  const fetchUserList = async () => {
    const res = await axios.get(`${config.server}${config.api.userList}`);
    console.log(res);
    return res.data;
  };

  const { data: userList } = useQuery(["userList"], () => fetchUserList(), {
    onSuccess: list => {
      const temp = list
        .filter(
          e => (e.permission === 0 || e.permission === 2) && e.status === true
        )
        .map(f => {
          return { value: f.id, label: f.name };
        });
      setStaffList(temp);
    },
  });

  const mutation = useMutation(payload => {
    return axios.post(`${config.server}${config.api.changeAssignedTo}`, {
      staffId: payload.staffId,
      userId: payload.userId,
    });
  });

  const handleFilter = e => {
    let temp = filters;
    temp[e.target.name] = e.target.value;

    setFilters(temp);
    refetch();
  };

  const { refetch: fetchReport } = useQuery(
    ["agentReport", { filters }],
    async () => {
      const stringified = queryString.stringify(filters, {
        skipEmptyString: true,
        skipNull: true,
      });

      const res = await axios.get(
        `${config.server}/applications/agentapp-report?${stringified}`,
        {}
      );

      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "AgentReport.csv");
        document.body.appendChild(link);
        link.click();
      }
    },
    {
      enabled: false,
      retry: false,
    }
  );

  return (
    <SiteWrapper>
      <Page.Content contain={true}>
        <div>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <Grid.Col width={12}>
              <Grid.Row>
                <Grid.Col width={12}>
                  {renderFor([0, 2], true, props.user.permission) && (
                    <Card>
                      <Card.Header className="cardInput">
                        <Card.Title>Agent Applications</Card.Title>
                        {/* <SearchFilter /> */}
                        <Card.Options>
                          <Form.Group className="cardInput">
                            <Form.Input
                              className="mb-3"
                              icon="search"
                              placeholder="Company"
                              name="companyName"
                              onChange={handleFilter}
                            />
                          </Form.Group>
                          <Form.Group className="cardInput combineButtons">
                            <Form.Input
                              className="mb-3"
                              icon="search"
                              placeholder="Operating Location"
                              name="location"
                              onChange={handleFilter}
                            />
                          </Form.Group>
                          <Form.Group className="cardInput combineButtons">
                            <Form.Input
                              className="mb-3"
                              icon="search"
                              placeholder="Recruiting Location"
                              name="recruitCountries"
                              onChange={handleFilter}
                            />
                          </Form.Group>
                          <Form.Group className="cardInput combineButtons">
                            <Form.Select name="status" onChange={handleFilter}>
                              <option value="">All statuses</option>
                              {Object.keys(statusOptions).map(key => (
                                <option value={key}>
                                  {statusOptions[key]}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="cardInput combineButtons">
                            <Button onClick={() => fetchReport()}>
                              Generate Report
                            </Button>
                          </Form.Group>
                        </Card.Options>
                      </Card.Header>
                    </Card>
                  )}
                  <Card>
                    <Table
                      responsive
                      className="card-table table-vcenter text-nowrap"
                      headerItems={[
                        {
                          content: props.user.permission === 1 ? "" : "No.",
                          className: "w-1",
                        },
                        { content: "Company" },
                        { content: "Agent" },
                        { content: "Country" },
                        { content: "Email" },
                        { content: "Status" },
                        { content: "Created" },
                        { content: "Staff", className: "w-1" },
                        { content: null, className: "w-1" },
                      ]}
                    >
                      <Table.Body>
                        {data &&
                          data.rows.map(app => {
                            const {
                              statusColor,
                              statusText,
                            } = applicationStatus(app.status);
                            return (
                              <Table.Row>
                                <Table.Col>
                                  <Text RootComponent="span" muted>
                                    {props.user.permission === 1 ? "" : app.id}
                                  </Text>
                                </Table.Col>
                                <Table.Col>
                                  <Text RootComponent="span">
                                    {app.companyName}
                                  </Text>
                                </Table.Col>
                                <Table.Col>
                                  <Text RootComponent="span">{app.u.name}</Text>
                                </Table.Col>
                                <Table.Col>
                                  <Text RootComponent="span">
                                    {app.u.country}
                                  </Text>
                                </Table.Col>
                                <Table.Col>
                                  <Text RootComponent="span">{app.email}</Text>
                                </Table.Col>
                                <Table.Col>
                                  <span
                                    className={`status-icon bg-${statusColor}`}
                                  />{" "}
                                  {statusText}
                                </Table.Col>
                                <Table.Col>
                                  <Text RootComponent="span">
                                    {app.createdAt.substring(0, 10)}
                                  </Text>
                                </Table.Col>
                                <Table.Col>
                                  {/* For Admins*/}
                                  {renderFor(
                                    [0],
                                    true,
                                    props.user.permission
                                  ) && (
                                    <Select
                                      data={[
                                        ...staffList,
                                        { value: "", label: "" },
                                      ]}
                                      defaultValue={app.u.assignedTo || ""}
                                      onChange={async e => {
                                        await mutation.mutateAsync({
                                          staffId: e,
                                          userId: app.u.id,
                                        });
                                        refetch();
                                      }}
                                      value={app.u.assignedTo}
                                      sx={{ width: "150px" }}
                                    />
                                  )}
                                  {/* For Staff*/}
                                  {renderFor(
                                    [2],
                                    true,
                                    props.user.permission
                                  ) && (
                                    <Text RootComponent="span">
                                      {app.u.assigned?.name}
                                    </Text>
                                  )}
                                </Table.Col>
                                <Table.Col>
                                  <Link to={`/agentapp/view/${app.id}`}>
                                    <Button size="sm" color="secondary">
                                      View
                                    </Button>
                                  </Link>
                                </Table.Col>
                              </Table.Row>
                            );
                          })}
                      </Table.Body>
                    </Table>
                  </Card>
                </Grid.Col>
              </Grid.Row>
              <Pagination
                prevIcon={<Icon link name="arrow-left" />}
                nextIcon={<Icon link name="arrow-right" />}
                jumpPrevIcon={<Icon link name="more-horizontal" />}
                jumpNextIcon={<Icon link name="more-horizontal" />}
                onChange={current => {
                  fetchAgentApps(current);
                  setPage(current);
                }}
                current={page}
                total={data.count}
              />
            </Grid.Col>
          )}
        </div>
      </Page.Content>
    </SiteWrapper>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  auth: state.auth,
});

export default connect(mapStateToProps)(AgentApps);
