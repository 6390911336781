// @flow
import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Error404 } from "./pages";
import { Provider } from "react-redux";
import store from "./store";
import "tabler-react/dist/Tabler.css";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { LastLocationProvider } from "react-router-last-location";
import PrivateRoute from "./components/common/PrivateRoute.js";
import ApplyingOnly from "./components/common/ApplyingOnly";

// Get the pages
import Applications from "./pages/Applications.js";
import Applications2 from "./pages/Applications/Applications2.jsx";
import Programs2 from "./pages/Programs/index.jsx";
import Commission from "./pages/Commission.react.js";
import ApplicationRoute from "./pages/formSubmission/ApplicationRoute.react";
import HomeSecond from "./HomePage.react";
import Documents from "./pages/Documents.react";
import Documents2 from "./pages/Documents/View";
import Agents from "./pages/Agents";
import Availability from "./pages/Availability";
import Terms from "./pages/Terms.react";
import Payments from "./pages/Payments";
import Register from "./pages/Register.jsx";
import Admin from "./pages/Admin";
import AgentApps from "./pages/AgentApp/AgentApps";
import AgentAppRoute from "./pages/AgentApp/AgentAppRoute";
import Notification from "./pages/Notification";
import { ReactQueryDevtools } from "react-query/devtools";
import ApplicationForm from "./pages/ApplicationForm/ApplicationForm";
import Login from "./pages/Login.jsx";
import { Notifications } from "@mantine/notifications";
import ForgotPassword from "./pages/ForgotPassword";

import { useGetMyInfo } from "./hooks/useGetMyInfo.js";

// Check for the token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get the user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set the user to be not isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check if the token is expired
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout the user
    store.dispatch(logoutUser());
    // Clear the current profile
    //store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = "/login";
  }
}

function App() {
  useGetMyInfo();
  return (
    <>
      <Notifications />
      <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <LastLocationProvider>
            <Switch>
              <PrivateRoute exact path="/" component={Applications} />
              <PrivateRoute
                exact
                path="/applications"
                component={Applications}
              />
              <PrivateRoute
                exact
                path="/applications2"
                component={Applications2}
              />
              <PrivateRoute
                exact
                path="/applications/:page"
                component={Applications}
              />
              <PrivateRoute exact path="/programs" component={Programs2} />
              <PrivateRoute exact path="/documents" component={Documents2} />
              <PrivateRoute exact path="/commission" component={Commission} />
              <PrivateRoute path="/application" component={ApplicationRoute} />
              {/* <PrivateRoute path="/home" component={HomeSecond} /> */}
              <PrivateRoute path="/form" component={ApplicationForm} />
              <PrivateRoute path="/all" component={Applications2} />
              <Route path="/login" component={Login} />
              <PrivateRoute path="/agents" component={Agents} />
              <PrivateRoute path="/payments" component={Payments} />
              <PrivateRoute path="/availability" component={Availability} />
              <PrivateRoute path="/terms" component={Terms} />
              <PrivateRoute path="/admin" component={Admin} />
              <PrivateRoute path="/notifications" component={Notification} />
              <ApplyingOnly path="/agentapps" component={AgentApps} />
              <ApplyingOnly path="/agentapp/" component={AgentAppRoute} />
              <Route path="/register" component={Register} />
              <Route path="/forgot-password" component={ForgotPassword} />
              {/* <Route path="/forgot-password2" component={ForgotPasswordPage} /> */}
              <PrivateRoute component={Error404} />
            </Switch>
          </LastLocationProvider>
        </BrowserRouter>
      </Provider>
      {process.env.REACT_APP_RQ_ENV === "development" ? (
        <ReactQueryDevtools />
      ) : null}
    </>
  );
}

export default App;
