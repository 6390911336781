// @flow

import * as React from "react";
import { connect } from "react-redux";
import { RegisterPage as TablerRegisterPage } from "tabler-react";
import { registerUser } from "../actions/authActions";

type Props = {||};

class RegisterPage extends React.Component {
  constructor() {
    super();
    // Set the initial state of the componenet
    this.state = {
    formData: {
      name: "",
      email: "",
      password: "",
      password2: "",
      permission: 4,
    }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // Function to handle the form submission
  handleSubmit(e) {

	// Prevent the default action
	e.preventDefault();

	// Send the request to the database
	this.props.registerUser(this.state.formData, false);

  }

  // Function to handle the input change
  handleChange(e) {

	return this.setState({
		formData: {
			...this.state.formData,
			[e.target.name]: e.target.value
		}
 	});

  }

render() {
  console.log(this.props.errors);
  return  (
	<TablerRegisterPage
		onSubmit={this.handleSubmit}
		onChange={this.handleChange}
		values={{
			email: this.state.formData.email,
			name: this.state.formData.name,
			password: this.state.formData.password,
			password2: this.state.formData.password2
		}}
		errors={this.props.errors}
	/>

  );
}
}

// Map the state to props
const mapStateToProps = state => ({
  errors: state.errors,
  auth: state.auth,
});


export default connect(mapStateToProps, { registerUser })(RegisterPage);
