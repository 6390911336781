import {
  SET_CURRENT_USER,
  REGISTER_NEW_USER,
  USER_LIST,
  EDIT_USER,
  DELETE_USER,
  SET_PROFILE,
} from "../actions/types";
import isEmpty from "../utils/is-empty";

const initialState = {
  isAuthenticated: false,
  user: {},
  list: {},
  currentUser: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.playload),
        user: action.playload,
      };
    case SET_PROFILE:
      return {
        ...state,
        currentUser: action.playload,
      };
    case REGISTER_NEW_USER:
      return {
        ...state,
        list: action.playload,
      };
    case EDIT_USER:
      var index = state.list.findIndex(x => x.id == action.playload.id);
      var newList = [
        ...state.list.slice(0, index),
        action.playload,
        ...state.list.slice(index + 1),
      ];

      return {
        ...state,
        list: newList,
      };
    case DELETE_USER:
      var index = state.list.findIndex(x => x.id == action.playload.id);
      state.list.splice(index, 1);
      return {
        ...state,
        list: state.list,
      };
    case USER_LIST:
      return {
        ...state,
        list: action.playload,
      };
    default:
      return state;
  }
}
