import React, { Component, Fragment } from "react";
import { Table, Icon } from "tabler-react";
import isEmpty from "./is-empty";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../config/config.js";
import { AppDocTypes } from "../constants/documentTypes";

// Function to get the list view
export function termsList(terms) {
  // Map through the terms
  if (!isEmpty(terms)) {
    return terms.map(term => (
      <option key={term.id} value={term.id}>
        {term.name}
      </option>
    ));
  }
}

export function agentsList(agents) {
  if (!isEmpty(agents)) {
    return agents.map(agent => (
      <option value={agent.id}>
        {agent.name} {agent.status === false ? "(inactive)" : ""}
      </option>
    ));
  }
}

export function mapProgramsList(programs) {
  if (!isEmpty(programs)) {
    return programs.map(program => (
      <option key={program.id} value={program.id}>
        {program.name} - {program.institution}
      </option>
    ));
  }
}

//Handle the document delete
export function handleDocumentDelete(documentCode) {
  //Show the popup
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, withdraw it!",
  }).then(result => {
    if (result.value) {
      const applicationData = { document: documentCode };
      // Send the delete request
      axios
        .delete(`${config.server}${config.api.applicationDocuments}`, {
          data: applicationData,
        })
        .then(res => {
          Swal.fire(
            "Deleted!",
            "Your document has been deleted.",
            "success"
          ).then(success => {
            window.location.reload();
          });
        })
        .catch(err => {
          if (!isEmpty(err)) {
            Swal.fire(
              "Error!",
              "Something went wrong, Please contact support.",
              "warning"
            );
          }
        });
    }
  });
}

const downloadFile = async (_evt, file) => {
  const res = await axios.get(
    `${config.server}${config.api.downloadFile}/?code=${file.code}&ext=${
      file.extension
    }`,
    {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const type = res.headers["content-type"];
  const blob = new Blob([res.data], { type: type, encoding: "UTF-8" });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.target = "_blank";

  link.click();
};

// Files content view
export const filesContentView = (
  files,
  deleteButton,
  downloadButton,
  fileType
) => {
  // Set the content view
  let resultView = [];

  // Loop through the files
  files.forEach(file => {
    // Check the file type
    if (fileType && (file.type == 8 || file.type == 6 || file.type == 7)) {
      return;
    }

    // Create new table row
    resultView.push(
      <Table.Row>
        <Table.Col>
          <img src={`/assets/icons/${file.extension}.png`} width="30" />
        </Table.Col>
        <Table.Col className="document-name-column">
          {isEmpty(file.name) ? "Not Provided" : file.name}
        </Table.Col>
        <Table.Col>
          <strong>{AppDocTypes[file.documentType]}</strong>
        </Table.Col>
        {!deleteButton ? (
          ""
        ) : (
          <Table.Col>
            {/* <a */}
            {/*   href={`https://portal.edusolutions.ca/apistorage/documents/${ */}
            {/*     file.code */}
            {/*   }.${file.extension}`} */}
            {/*   target="__blank" */}
            {/* > */}
            {/*   <Icon link name="download" /> */}
            {/* </a> */}
            <Icon
              link
              name="download"
              onClick={evt => downloadFile(evt, file)}
              target="_blank"
            />
          </Table.Col>
        )}
        {!downloadButton ? (
          ""
        ) : (
          <Table.Col>
            <Icon
              link
              name="trash"
              onClick={() => {
                handleDocumentDelete(file.code);
              }}
            />
          </Table.Col>
        )}
      </Table.Row>
    );
  });

  //return the file view
  return resultView;
};

// Function to get the testType
const testType = testNumber => {
  let result;
  switch (testNumber) {
    case 0:
      result = "IELTS";
      break;
    case 1:
      result = "TOFEL";
      break;
    case 2:
      result = "PTE";
      break;
    default:
      result = "IELTS";
  }
  return result;
};

// Function to get the english pro
const englishPro = englishPro => {
  let result;
  switch (englishPro) {
    case 0:
      result = "Beginner";
      break;
    case 1:
      result = "Intermediate";
      break;
    case 2:
      result = "Advanced";
      break;
    default:
      result = "Beginner";
  }
  return result;
};

// Function to parse the incoming data
const viewDataParse = (app, type) => {
  let finalData = {};
  switch (type) {
    case "language": {
      finalData = {
        "Mother Tongue": app.mother_tongue,
        "English Language test": testType(app.testTaken),
        "English Proficiency": englishPro(app.englishProficiency),
        "Listening Score": app.listening,
        "Reading Score": app.reading,
        "Writing Name": app.writing,
        "Speaking Score": app.speaking,
        "Total Band Score": app.totalScore,
        "Test Date": app.takenOn,
      };
      break;
    }
    case "academics": {
      finalData = {
        "Institute Name": app.instituteName,
        "First Year": app.firstYear,
        "Last Year": app.lastYear,
        Country: app.country,
        Province: app.province,
        City: app.city,
        Backlogs: app.backlogs,
        "Bachelors Percentage": app.bachelorsPercentage,
      };
      break;
    }
    default:
      // Combine the full name
      finalData = {
        "Assigned ID":
          "EDUS" +
          Array(6 - app.id.toString().length)
            .fill()
            .map(() => "0")
            .join("") +
          `${app.id}`,
        "Student Name": `${app.salutation} ${app.firstName} ${app.middleName ||
          ""} ${app.lastName}`,
        "Date of Birth": app.DOB,
        Nationality: app.nationality,
        "Passport Number": app.passport,
        "Email Address": app.email,
        Telephone: app.telephone,
        Address: `${app.MailingAddress} ${app.MailingAddress2}, ${app.city}, ${
          app.province
        }, ${app.country}`,
        //"Program": `${app.ProgramsAvailable.Program.code} - ${app.ProgramsAvailable.Term.name}` || "Unknow",
        //"Secondary Program": `${app.program_second.Program.code} - ${app.program_second.Term.name}` || "Unknown"
      };

      if (app.ProgramsAvailable) {
        finalData["Program"] = `${
          app.ProgramsAvailable.Program.institution
        } | ${app.ProgramsAvailable.Program.code} - ${
          app.ProgramsAvailable.Term.name
        }`;
      }
      if (app.program_second) {
        finalData["Secondary Program"] = `${
          app.program_second.Program.code
        } - ${app.program_second.Term.name}`;
      }

      if (app.studentId) {
        finalData["Student Id"] = `${app.studentId}`;
      }
      break;
  }

  return finalData;
};

// Function to generate the contentView view
const contentView = (currentApplication, type) => {
  // Set the content view
  let resultView = [];
  let value;

  if (isEmpty(currentApplication)) {
    return false;
  }
  const finalData = viewDataParse(currentApplication, type);

  Object.keys(finalData).forEach(function(key) {
    // Get the value
    value = finalData[`${key}`];
    if (typeof value == "object" && !isEmpty(value)) {
      // Get the nested object result and attach it with result Arrry
      resultView = resultView.concat(contentView(value));
    } else {
      // Create new table row
      resultView.push(
        <Table.Row>
          <Table.Col>{key}</Table.Col>
          <Table.Col>{isEmpty(value) ? "Not Provided" : value}</Table.Col>
        </Table.Row>
      );
    }
  });

  //Return the contentView
  return resultView;
};

export default contentView;
