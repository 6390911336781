import { SET_SETTINGS, GET_SETTINGS } from "../actions/types";

const initialState = {
  emailTo: "",
  emailFrom: "",
  paymentDeadline: "",
  term: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        emailTo: action.playload.emailTo,
        emailFrom: action.playload.emailFrom,
        paymentDeadline: action.playload.paymentDeadline,
        paymentDeadlineEAP: action.playload.paymentDeadlineEAP,
        term: action.playload.term,
        acceptNewApps: action.playload.acceptNewApps,
      };
    case GET_SETTINGS:
      return {
        ...state,
        emailTo: action.playload.emailTo,
        emailFrom: action.playload.emailFrom,
        paymentDeadline: action.playload.paymentDeadline,
        paymentDeadlineEAP: action.playload.paymentDeadlineEAP,
        term: action.playload.term,
        acceptNewApps: action.playload.acceptNewApps,
      };
    default:
      return state;
  }
}
