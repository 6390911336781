import Axios from "axios";
import { useMutation, useQuery } from "react-query";
import queryClient from "../../lib/queryClient";
import config from "../../config/config";

const baseUrl = "http://localhost:5000";

export const useFetchAllPrograms = () => {
  return useQuery("programs", async () => {
    const res = await Axios.get(`${config.server}/programs`);
    return res.data;
  });
};

/**
 * @description add a new program or edit if program already exists
 */
export const useAddEditProgram = () => {
  return useMutation({
    mutationFn: async (
      /** @type {import("./programTypes").ProgramData} */
      data
    ) => {
      const res = await Axios.post(`${config.server}/programs`, {
        code: data.code,
        name: data.name,
        url: data.url,
        institution: data.institution,
        id: data.id,
        status: true,
      });

      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries("programs");
    },
  });
};

// delete a program
export const useEditProgram = () => {
  return useMutation();
};
