import { GET_ERRORS, GET_DOCUMENTS } from "./types";

import axios from "axios";
import isEmpty from "../utils/is-empty";
import config from "../config/config.js";


// Get the list of all programs
export const documentsList = () => dispatch => {
  // Send the get request to backend server
  axios
    .get(`${config.server}${config.api.documentsList}`)
    .then(res => {
      // Return the data to the reducer
      dispatch({
        type: GET_DOCUMENTS,
        playload: res.data.files,
      });
    })
    .catch(err => console.log(err));
};
