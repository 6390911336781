// Hello

import React from "react";
import SiteWrapper from "../SiteWrapper.react";
import { userList } from "../actions/authActions";
import { connect } from "react-redux";
import isEmpty from "../utils/is-empty";
import { avatarGenerator } from "../utils/colorGenerator";
import { registerUser } from "../actions/authActions";
import Swal from "sweetalert2";

import { Page, Grid, Card, Table, Button, Icon } from "tabler-react";
import AgentAdd from "../components/modals/AgentAdd";

class Agents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agentAddModal: false,
      formData: {},
      list: {},
      editMode: false,
      agentSelected: "hidden",
      staffList: [],
      errors: {},
    };

    this.toggleAdd = this.toggleAdd.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  // Set the errors state
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    // Get the agents list
    this.setState({ list: this.props.auth.list });
  }

  // Hanlde the input changes
  onChange(e) {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
    if (e.target.name == "permission" && e.target.value == "1") {
      this.setState({ agentSelected: false });
    } else if (e.target.name == "permission" && e.target.value == "2") {
      this.setState({ agentSelected: "hidden" });
    }
  }

  async componentWillMount() {
    // Get the list of the programs
    await this.props.userList();
    // Filter a list of only staff members
    const staffList = this.props.auth.list
      .filter(e => e.permission === 0 || e.permission === 2)
      .map(f => {
        return { value: f.id, label: f.name };
      });
    // Set the user list
    this.setState({ list: this.props.auth.list, staffList: staffList });
  }

  toggleAdd() {
    this.setState({
      agentAddModal: !this.state.agentAddModal,
    });
    if (!this.state.agentAddModal)
      this.setState({ formData: {}, editMode: false });
  }

  // Handle the form submission
  handleFormSubmission = async e => {
    e.preventDefault();
    // Set the application data
    const fromData = this.state.formData;

    //Reset the errors
    this.setState({ errors: {} });

    // Send the application to action
    await this.props.registerUser(fromData, this.state.editMode);

    // Check for the errors
    if (isEmpty(this.props.errors)) {
      // Hide the form
      this.toggleAdd();
      // Remove the formData
      this.setState({ formData: {}, editMode: false });
    }
  };

  // Close all the moddals
  closeModals() {
    this.setState({
      agentAddModal: false,
    });
  }

  // Handle the delete program
  handleDelete(id) {
    // Get the user data
    const userData = this.state.list.filter(x => x.id == id)[0];
    userData.status = false;

    //Show the popup
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete User",
    }).then(result => {
      if (result.value) {
        // Send the delete request
        const response = this.props.registerUser(userData, "DELETE");

        if (response) {
          Swal.fire(
            "Deleted!",
            "Your application has been deleted.",
            "success"
          );
        } else {
          Swal.fire("Deleted!", "Program has been deleted.", "success");
        }
      }
    });
  }

  // Handle the user edit
  async handleEdit(userId) {
    // Get the user information
    const selectedUser = this.state.list.filter(x => x.id == userId)[0];
    // Set the formData state
    await this.setState({ formData: selectedUser, editMode: true });
    //Toggle the add user model
    this.toggleAdd();
  }

  // Function to list the programs
  listUsers(userList) {
    // finalList array
    const finalList = [];

    Object.keys(userList).forEach(key => {
      const currentUser = userList[`${key}`];
      const id = currentUser["id"];
      const name = currentUser["name"];
      const email = currentUser["email"];
      let type;
      let company;
      let website;
      let country;

      if (currentUser["permission"] === 1) {
        type = "Agent";
        company = currentUser["company"] || "Unknown";
        website = currentUser["website"] || "Unknown";
        country = currentUser["country"] || "Unknown";
      } else if (currentUser["permission"] === 3) {
        type = "Admission Officer";
        company = "Loyalist College";
        website = "www.loyalistcollege.com";
        country = "Canada";
      } else if (currentUser["permission"] === 4) {
        type = "Finance Officer";
        company = "Loyalist College";
        website = "www.loyalistcollege.com";
        country = "Canada";
      } else {
        type = "Assistant";
        company = "Toronto Business College";
        website = "www.loyalistcollege.com";
        country = "Canada";
      }

      // Get the date
      // let dateObject = new Date(Date.parse(currentUser["createdAt"]));
      // const date = dateObject.toDateString();

      finalList.push(
        <Table.Row>
          <Table.Col className="w-1">{avatarGenerator(name)}</Table.Col>
          <Table.Col>{name}</Table.Col>
          <Table.Col>
            <a href={`mailto:${email}`}>{email}</a>
          </Table.Col>

          <Table.Col className="text-nowrap">{type}</Table.Col>
          <Table.Col className="text-nowrap">{country}</Table.Col>
          <Table.Col className="text-nowrap">{website}</Table.Col>
          <Table.Col className="text-nowrap">{company}</Table.Col>
          <Table.Col className="text-nowrap">
            <Button
              className="btn btn-azure btn-sm pill"
              onClick={() => this.handleEdit(id)}
            >
              <Icon name="edit" />
            </Button>
            <Button
              className="btn btn-pink btn-sm combineButtons"
              onClick={() => this.handleDelete(id)}
            >
              <Icon name="trash" />
            </Button>
          </Table.Col>
        </Table.Row>
      );
    });
    //Return the final list
    return finalList;
  }

  //Empty view
  emptyView() {
    // const messsage =
    //   this.props.auth.user == 1
    //     ? "You didn't submitted any application, "
    //     : "No application has been submitted by the agents";
    return (
      <div className="notfound">
        <img src="/assets/icons/user.png" alt="not found" />
        <span>
          You don't have any employee or user
          <br /> Please click on the button below add a user.
        </span>
        <button className="btn btn-primary" onClick={this.toggleAdd}>
          Add new user
        </button>
      </div>
    );
  }

  // Card view
  cardView(userList) {
    return (
      <Card>
        <Card.Header>
          <Card.Title>EduSolutions' User list</Card.Title>
          <Card.Options>
            {this.props.auth.user.permission === 0 ? (
              <Button
                onClick={this.toggleAdd}
                color="azure"
                size="sm"
                className="ml-2"
              >
                Add User
              </Button>
            ) : (
              ""
            )}
          </Card.Options>
        </Card.Header>
        <Table
          cards={true}
          striped={true}
          responsive={true}
          className="table-vcenter"
        >
          <Table.Header>
            <Table.Row>
              <Table.ColHeader colSpan={2}>User Name</Table.ColHeader>
              <Table.ColHeader>Email</Table.ColHeader>
              <Table.ColHeader>User Type</Table.ColHeader>
              <Table.ColHeader>Country</Table.ColHeader>
              <Table.ColHeader>Website</Table.ColHeader>
              <Table.ColHeader>Company</Table.ColHeader>
              <Table.ColHeader>Assigned To</Table.ColHeader>
              <Table.ColHeader />
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.listUsers(userList)}</Table.Body>
        </Table>
      </Card>
    );
  }

  render() {
    let userList = this.state.list;

    return (
      <SiteWrapper>
        <AgentAdd
          formData={this.state.formData}
          onChange={this.onChange}
          toggleAdd={this.toggleAdd}
          agentAddModal={this.state.agentAddModal}
          handleFormSubmission={this.handleFormSubmission}
          editMode={this.state.editMode}
          errors={this.state.errors}
          agentSelected={this.state.agentSelected}
          staffList={this.state.staffList}
        />

        {this.state.staffList.length > 0 && (
          <Page.Content contain={true}>
            <div>
              <Grid.Col width={12}>
                {isEmpty(userList) ? this.emptyView() : this.cardView(userList)}
              </Grid.Col>
            </div>
          </Page.Content>
        )}
      </SiteWrapper>
    );
  }
}

// Map the state to props
const mapStateToProps = state => ({
  documents: state.documents,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { userList, registerUser })(Agents);
