// @flow

import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import "./c3jscustom.css";

import App from "./App.react";
import { QueryClientProvider } from "react-query";
import queryClient from "./lib/queryClient";

const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>,
    rootElement
  );
} else {
  throw new Error("Could not find root element to mount to!");
}
