import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { Button, Card, Form, Page, Table } from "tabler-react";
import { settingGet } from "../actions/settingsActins";
import config from "../config/config";
import SiteWrapper from "../SiteWrapper.react";

const AdminModal = ({ modalOpen, setModalOpen }) => {
  const [colName, setColName] = useState("");

  return (
    <Modal isOpen={modalOpen} toggle={setModalOpen} backdrop={true}>
      <ModalBody>
        <Form.Group label="Column name" isRequired>
          <Form.Input name="colName" value={colName} onChange={setColName} />
        </Form.Group>
      </ModalBody>
      <Button>Add</Button>
    </Modal>
  );
};

const Admin = ({ auth, settings, settingGet }) => {
  useEffect(() => {
    if (auth.user.permission !== 0) {
    }
  }, []);

  const [modalOpen, setModalOpen] = useState(false);

  const handleAcceptNewApps = async () => {
    await axios.post(`${config.server}${config.api.settingSet}/acceptNewApps`);
    await settingGet();
    return;
  };

  return (
    <SiteWrapper>
      {auth.user.permission === 0 ? (
        <Page.Content>
          <Card>
            <Card.Header>
              <Card.Title>Admin Settings</Card.Title>
              <Card.Options>
                <Button onClick={() => setModalOpen(!modalOpen)}>
                  Add Setting Column
                </Button>
              </Card.Options>
            </Card.Header>
            <Table
              cards={true}
              striped={true}
              responsive={true}
              className="table-vcenter"
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Function</Table.ColHeader>
                  <Table.ColHeader>Setting</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Col>Accepting New Applications</Table.Col>
                  <Table.Col>
                    <Form.Switch
                      onClick={handleAcceptNewApps}
                      checked={settings.acceptNewApps === true ? true : false}
                    />
                  </Table.Col>
                </Table.Row>
              </Table.Body>
            </Table>
          </Card>
        </Page.Content>
      ) : (
        ""
      )}
    </SiteWrapper>
  );
};

const mapStateToProps = state => ({
  settings: state.settings,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { settingGet }
)(Admin);
