import { Divider, Paper, Title } from "@mantine/core";
import React from "react";

const TitledPaper = ({ title = "", children }) => {
  return (
    <Paper shadow="xs" px="md" py="xl">
      <Title size="h3">{title}</Title>
      <Divider my="lg" />
      {children}
    </Paper>
  );
};

export default TitledPaper;
