import React, { Fragment, useEffect, useState } from "react";
import SiteWrapper from "../../SiteWrapper.react";
import { Button, Card, Container, Form, Icon, Table } from "tabler-react";
import { connect } from "react-redux";
import {
  useSingleAgentApp,
  useAgentAppDocUpload,
  useAgentAppDocs,
  useAgentAppDocDelete,
  useAgentAppSubmit,
} from "../../hooks/useAgentApp";
import { Formik, FieldArray, Field } from "formik";

const exts = [".png", ".jpg", ".jpeg", ".doc", ".docx", ".pdf"];

const AgentStep1 = ({ history, match, user }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({});

  const { mutate: addDoc } = useAgentAppDocUpload();
  const { mutate: delDoc } = useAgentAppDocDelete();
  const { isSuccess, mutate: submitApp } = useAgentAppSubmit();

  const { isLoading, data: appData } = useSingleAgentApp(match.params.id);
  const { isLoading: docIsLoading, data: docData } = useAgentAppDocs(
    match.params.id
  );

  useEffect(
    () => {
      setFormData(appData);
    },
    [appData]
  );

  if (isSuccess) {
    history.push(`/agentapp/view/${match.params.id}`);
  }

  const submitForm = theForm => {
    // 2023-09-19 No longer require
    // if (docData.length === 0) {
    //   return alert("Upload at least 1 document to submit application.");
    // }
    try {
      // clear empty staff entries
      let filteredStaff = theForm.staff.filter(
        e => e.name.length > 0 && e.email.length > 0 && e.title.length > 0
      );
      let formDataToSend = { ...theForm };

      formDataToSend.staff = filteredStaff;

      const response = submitApp(formDataToSend);

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = async e => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("id", appData.id);

    if (exts.some(extension => selectedFile.name.includes(extension))) {
      try {
        const response = addDoc(formData);
        return response;
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("invalid file type");
    }
  };

  const handleFileDelete = async (e, document) => {
    e.preventDefault();

    try {
      delDoc(document);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          {isLoading && <div>Loading...</div>}

          {!appData && <div>Unable to find application</div>}

          {appData && formData && (
            <Fragment>
              <Formik
                initialValues={formData}
                onSubmit={(values, { setSubmitting }) => {
                  submitForm(values);
                  setSubmitting(false);
                }}
                enableReinitialize
              >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <Card title="Company Information" RootComponent={Form}>
                      <Card.Body>
                        <Form.Input
                          value={values.companyName}
                          name="companyName"
                          onChange={handleChange}
                          label="Name of Company"
                          isRequired
                        />
                        <Form.Input
                          label="Website"
                          isRequired
                          value={values.website}
                          name="website"
                          onChange={handleChange}
                        />
                        <Form.Input
                          label="Email Address"
                          isRequired
                          value={values.email}
                          name="email"
                          onChange={handleChange}
                        />
                        <Form.Input
                          label="Phone Number"
                          isRequired
                          value={values.phoneNumber}
                          onChange={handleChange}
                          name="phoneNumber"
                        />
                        <Form.Input
                          isRequired
                          label="License / Registration No."
                          value={values.licenseNo}
                          onChange={handleChange}
                          name="licenseNo"
                        />
                        <Form.Input
                          isRequired
                          label="How long have you been operating?"
                          value={values.timeOperating}
                          onChange={handleChange}
                          name="timeOperating"
                        />
                        <Form.Input
                          isRequired
                          label="Complete Address (Head office)"
                          value={values.address}
                          name="address"
                          onChange={handleChange}
                        />
                        <Form.Input
                          isRequired
                          label="How many branches you have and in which cities/countries"
                          value={values.branchesNLocation}
                          name="branchesNLocation"
                          onChange={handleChange}
                        />
                        <Form.Input
                          label="List the services that you provide"
                          isRequired
                          value={values.services}
                          name="services"
                          onChange={handleChange}
                        />
                        <Form.Input
                          label="From which countries do you recruit students"
                          isRequired
                          value={values.recruitCountries}
                          name={`recruitCountries`}
                          onChange={handleChange}
                        />
                      </Card.Body>
                    </Card>

                    <Card title="Owner's Information" RootComponent={Form}>
                      <Card.Body>
                        <Form.Input
                          label="Name"
                          isRequired
                          value={values.ownerName}
                          onChange={handleChange}
                          name="ownerName"
                        />
                        <Form.Input
                          label="Email"
                          isRequired
                          onChange={handleChange}
                          value={values.ownerEmail}
                          name="ownerEmail"
                        />
                        <Form.Input
                          label="Cell Phone"
                          isRequired
                          onChange={handleChange}
                          value={values.ownerPhone}
                          name="ownerPhone"
                        />
                      </Card.Body>
                    </Card>

                    <Card>
                      <Card.Body>
                        <FieldArray
                          name="staff"
                          render={arrayHelpers => (
                            <div>
                              <Card.Header>
                                <Card.Title>Staff Information</Card.Title>
                                <Card.Options>
                                  {/* <Button onClick={addStaff}>Add More</Button> */}
                                  <Button
                                    onClick={e => {
                                      e.preventDefault();

                                      arrayHelpers.push({
                                        name: "",
                                        email: "",
                                        title: "",
                                      });
                                    }}
                                  >
                                    Add More
                                  </Button>
                                </Card.Options>
                              </Card.Header>
                              {values.staff &&
                                values.staff.map((input, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <Form.FieldSet>
                                        <Field
                                          value={input.name}
                                          name={`staff[${index}].name`}
                                          render={({ field }) => (
                                            <Form.Input
                                              isRequired
                                              label="Name"
                                              {...field}
                                            />
                                          )}
                                        />
                                        <Field
                                          value={input.email}
                                          name={`staff[${index}].email`}
                                          render={({ field }) => (
                                            <Form.Input
                                              isRequired
                                              label="Email"
                                              {...field}
                                            />
                                          )}
                                        />
                                        <Field
                                          value={input.title}
                                          name={`staff[${index}].title`}
                                          render={({ field }) => (
                                            <Form.Input
                                              isRequired
                                              label="Title"
                                              {...field}
                                            />
                                          )}
                                        />
                                        <Form.Footer>
                                          <Button
                                            color="danger"
                                            // onClick={e => removeStaff(index, e)}
                                            onClick={e => {
                                              e.preventDefault();
                                              arrayHelpers.remove(index);
                                            }}
                                          >
                                            Remove
                                          </Button>
                                        </Form.Footer>
                                      </Form.FieldSet>
                                    </Fragment>
                                  );
                                })}
                            </div>
                          )}
                        />
                      </Card.Body>
                    </Card>

                    <Card
                      title="Please answer the following questions"
                      RootComponent={Form}
                    >
                      <Card.Body>
                        <Form.Input
                          label="How many students do you recruit for Canadian Institutions per
              intake (On Average)?"
                          isRequired
                          onChange={handleChange}
                          value={values.studentsPerIntake}
                          name="studentsPerIntake"
                          placeholder="100 (Numbers only)"
                          type="number"
                        />
                        <Form.Input
                          label="How many students received visa approval for Canada last year?"
                          isRequired
                          onChange={handleChange}
                          type="number"
                          value={values.visasLastYear}
                          placeholder="100 (Numbers only)"
                          name="visasLastYear"
                        />
                        <Form.Input
                          label="How many students on an average do you anticipate sending to
              Loyalist College in Toronto?"
                          isRequired
                          onChange={handleChange}
                          type="number"
                          value={values.studentsToSend}
                          placeholder="100 (Numbers only)"
                          name="studentsToSend"
                        />
                        <Form.Input
                          label="Canadian Education institutes you currently work with"
                          isRequired
                          onChange={handleChange}
                          value={values.currentCEI}
                          name="currentCEI"
                        />
                      </Card.Body>
                    </Card>

                    <Card>
                      <Card.Body>
                        <FieldArray
                          name="references"
                          render={arrayHelpers => (
                            <div>
                              <Card.Header>
                                <Card.Title>References</Card.Title>
                                <Card.Options>
                                  <Button
                                    onClick={e => {
                                      e.preventDefault();

                                      arrayHelpers.push({
                                        institution: "",
                                        contact: "",
                                        position: "",
                                        phone: "",
                                        email: "",
                                      });
                                    }}
                                  >
                                    Add More
                                  </Button>
                                </Card.Options>
                              </Card.Header>
                              {values.references &&
                                values.references.map((input, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <Form.FieldSet>
                                        <Field
                                          value={input.institution}
                                          name={`references[${index}].institution`}
                                          render={({ field }) => (
                                            <Form.Input
                                              isRequired
                                              label="Institution"
                                              {...field}
                                            />
                                          )}
                                        />
                                        <Field
                                          value={input.contact}
                                          name={`references[${index}].contact`}
                                          render={({ field }) => (
                                            <Form.Input
                                              isRequired
                                              label="Contact Name"
                                              {...field}
                                            />
                                          )}
                                        />
                                        <Field
                                          value={input.position}
                                          name={`references[${index}].position`}
                                          render={({ field }) => (
                                            <Form.Input
                                              isRequired
                                              label="Position"
                                              {...field}
                                            />
                                          )}
                                        />
                                        <Field
                                          value={input.phone}
                                          name={`references[${index}].phone`}
                                          render={({ field }) => (
                                            <Form.Input
                                              isRequired
                                              label="Phone Number"
                                              {...field}
                                            />
                                          )}
                                        />
                                        <Field
                                          value={input.email}
                                          name={`references[${index}].email`}
                                          render={({ field }) => (
                                            <Form.Input
                                              isRequired
                                              label="Email"
                                              {...field}
                                            />
                                          )}
                                        />
                                        <Form.Footer>
                                          <Button
                                            color="danger"
                                            // onClick={e => removeStaff(index, e)}
                                            onClick={e => {
                                              e.preventDefault();
                                              arrayHelpers.remove(index);
                                            }}
                                          >
                                            Remove
                                          </Button>
                                        </Form.Footer>
                                      </Form.FieldSet>
                                    </Fragment>
                                  );
                                })}
                            </div>
                          )}
                        />
                      </Card.Body>
                    </Card>

                    <Card
                      footer={
                        <div className="d-flex">
                          <Button
                            type="submit"
                            color="primary"
                            className="ml-auto"
                            onClick={handleFileUpload}
                            disabled={selectedFile ? false : true}
                          >
                            Upload
                          </Button>
                        </div>
                      }
                    >
                      <Card.Body>
                        <Form.Group label="Document Upload">
                          {docIsLoading ? (
                            <div>Loading documents...</div>
                          ) : (
                            <DocTable
                              docData={docData}
                              handleFileDelete={handleFileDelete}
                              user={user}
                            />
                          )}
                          <Form.FileInput
                            onChange={e => setSelectedFile(e.target.files[0])}
                          />
                        </Form.Group>
                      </Card.Body>
                    </Card>
                    <div className="d-flex">
                      <Button
                        type="submit"
                        color="primary"
                        className="ml-auto"
                        disabled={isSubmitting}
                        // onClick={handleSubmit}
                      >
                        Submit Application
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </Fragment>
          )}
        </Container>
      </div>
    </SiteWrapper>
  );
};

// TODO: Change this query such that agents dont grab all documents
// only the documents they own. Staff grab all documents
const DocTable = ({ docData, handleFileDelete, user }) => (
  <Table cards={true} striped={true} response={true} className="table-vcenter">
    <Table.Body>
      {docData &&
        (user.permission === 0 || user.permission === 2) &&
        docData.map(e => (
          <Table.Row>
            <Table.Col>{e.name}</Table.Col>
            <Table.Col>{e.extension}</Table.Col>
            <Table.Col>
              <Icon
                onClick={evt => handleFileDelete(evt, e.code)}
                link
                name="trash"
              />
            </Table.Col>
          </Table.Row>
        ))}
      {docData &&
        user.permission === 1 &&
        docData
          .filter(i => i.owner === user.id)
          .map(e => (
            <Table.Row>
              <Table.Col>{e.name}</Table.Col>
              <Table.Col>{e.extension}</Table.Col>
              <Table.Col>
                <Icon
                  onClick={evt => handleFileDelete(evt, e.code)}
                  link
                  name="trash"
                />
              </Table.Col>
            </Table.Row>
          ))}
    </Table.Body>
  </Table>
);

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(
  mapStateToProps,
  null
)(AgentStep1);
