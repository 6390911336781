import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, ContactCard } from "tabler-react";
import isEmpty from "../../utils/is-empty";

class AgentInfo extends React.Component {

    constructor(props) {
      super(props);
    }

    render() {
      return (
        <Modal isOpen={this.props.agentInfo} toggle={this.props.toggle}>
          <ContactCard
                className="agentInfo"
                cardTitle="Agent information"
                rounded
                objectURL={this.props.agentData ? `assets/icons3/${this.props.agentData.avatar}.png` : 'Waiting for data'}
                alt="Agent Photo"
                name={this.props.agentData.name ? this.props.agentData.name : 'Not Provided'}
                address={{
                  line1: `${(this.props.agentData.email) ? this.props.agentData.email : 'Not Provided'}`,
                }}
                details={[
                  { title: "Status", content: <React.Fragment><span className={`status-icon bg-${(this.props.agentData.status) ? 'success' : 'danger'}`} />{(this.props.agentData.status) ? 'Active' : 'Deleted'}</React.Fragment> },
                  { title: "Business Name", content: this.props.agentData.company ? this.props.agentData.company : 'Not provided' },
                  {
                    title: "Website",
                    content: (
                      <a href={this.props.agentData.website ? this.props.agentData.website : ''}>{this.props.agentData.website ? this.props.agentData.website : 'Not Provided'}</a>
                    ),
                  },
                  { title: "Country", content: (this.props.agentData.country) ? this.props.agentData.country : 'Not Provided' },
                ]}
              />
        </Modal>
        )
    }

}

export default AgentInfo;