import React from "react";
import { Select } from "@mantine/core";
import { useGetAllUsers } from "../../hooks/useGetAllUsers";

const AgentSearchBar = ({ onChange, value }) => {
  const { data: userData } = useGetAllUsers();

  return (
    <Select
      placeholder="Agent"
      searchable
      clearable
      nothingFound="No agent"
      data={userData?.map(e => ({ value: e.id, label: e.name })) ?? [""]}
      sx={{
        ".mantine-Select-wrapper": { width: "400px" },
      }}
      mr={4}
      minLength={500}
      maxDropdownHeight={500}
      value={value}
      onChange={onChange}
    />
  );
};

export default AgentSearchBar;
