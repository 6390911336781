// @flow
/* eslint-disable no-unused-expressions */
import * as React from "react";

import { Card, Container, Grid, Form, Button } from "tabler-react";

import SiteWrapper from "../../SiteWrapper.react";
import "../../App.css";
import { connect } from "react-redux";
import isEmpty from "../../utils/is-empty";
import { handleApplicationSubmissions } from "../../actions/applicationActions";
import config from "../../config/config.js";
import AcademicForm from "../../components/forms/AcademicForm";
import { throws } from "assert";
import { timingSafeEqual } from "crypto";
import { capManyWords } from "../../utils/formatUserData";

class Step3 extends React.PureComponent {
  constructor(props) {
    super(props);

    // Set the initial State
    this.state = {
      formData: [],
      secondaryForms: [],
      currentForm: 0,
      errors: {},
    };

    // Bind the functions with {this}
    this.onChange = this.onChange.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleAddMore = this.handleAddMore.bind(this);
  }

  // Check if the form is in submission mode
  async componentWillMount() {
    // Redirect if form is not in submission mode
    if (
      !this.props.application.submissionMode &&
      !this.props.application.editMode
    ) {
      this.props.history.push("/application/submit");
    }
    // Check if we are in edit mode and got currentApplication data
    if (
      !isEmpty(this.props.application.currentApplication.ApplicationAcademics)
    ) {
      // Set the form state to currentApplication state
      await this.setState({
        formData: this.props.application.currentApplication
          .ApplicationAcademics,
      });
      // Set the initial forms
      const totalForms = this.state.formData.length;
      // Set the initial State
      for (let form = 0; totalForms > form; form++) {
        this.handleAddMore();
      }
    } else {
      this.handleAddMore();
    }
  }

  // Hanlde the input changes
  onChange(e, currentForm) {
    let formData = this.state.formData.slice();
    formData[currentForm][e.target.name] = e.target.value;

    this.setState({
      formData,
    });
  }

  // Set the errors state
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  // Handle the form submission
  handleFormSubmission = e => {
    e.preventDefault();

    // Set the application data
    let { formData } = this.state;

    // Format the application data
    formData[0] = {
      ...formData[0],
      instituteName: capManyWords(formData[0].instituteName),
    };

    // Attach the application id with formData
    // Set the final request Data
    const requestData = {
      academics: formData,
      metaData: {
        applicationId: this.props.application.currentApplicationID,
      },
    };

    //Reset the errors
    this.setState({ errors: {} });
    // Send the application to action
    this.props.handleApplicationSubmissions(
      requestData,
      "/application/documents",
      `${config.server}${config.api.applicationAcademic}`,
      3,
      this.props.history
    );
  };

  // Handle the previous button
  handlePrevious = e => {
    e.preventDefault();
    if (true) {
      // Redirect user to next link
      this.props.history.push("/application/language");
    }
  };

  // Function to add more form in the current view
  async handleAddMore(e) {
    // Prevent default
    isEmpty(e) ? "" : e.preventDefault();

    // Check if the data exits
    const nextForm = this.state.currentForm + 1;
    !isEmpty(this.state.formData[this.state.currentForm])
      ? ""
      : await this.setState({
          formData: [...this.state.formData, { type: this.state.currentForm }],
        });

    this.setState({
      ...this.state,
      currentForm: nextForm,
      secondaryForms: [
        ...this.state.secondaryForms,
        <AcademicForm
          errors={isEmpty(this.state.errors[this.state.currentForm]) || {}}
          formData={this.state.formData[this.state.currentForm]}
          onChange={this.onChange}
          currentForm={this.state.currentForm}
        />,
      ],
    });
  }

  render() {
    return (
      <SiteWrapper>
        <div className="my-3 my-md-5">
          <Container>
            <Grid.Row>
              <Grid.Col lg={8} className="applicationForm">
                <Card
                  RootComponent={Form}
                  footer={
                    <div className="d-flex">
                      <Button
                        color="secondary"
                        onClick={this.handlePrevious}
                        link
                      >
                        Previous Step
                      </Button>
                      <Button
                        type="submit"
                        onClick={this.handleFormSubmission}
                        color="primary"
                        className="ml-auto"
                      >
                        Next Step
                      </Button>
                    </div>
                  }
                >
                  <Card.Header>
                    <Card.Title>
                      Submit Application - Academic History
                    </Card.Title>

                    <Card.Options>
                      {this.state.currentForm > 2 ? (
                        ""
                      ) : (
                        <Button
                          onClick={e => this.handleAddMore(e)}
                          color="purple"
                          size="sm"
                        >
                          Add More
                        </Button>
                      )}
                    </Card.Options>
                  </Card.Header>
                  <Card.Body>{this.state.secondaryForms}</Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Container>
        </div>
      </SiteWrapper>
    );
  }
}

// Set the component's properties
const mapStateToProps = state => ({
  application: state.application,
  errors: state.errors,
});
export default connect(
  mapStateToProps,
  { handleApplicationSubmissions }
)(Step3);
