// Statuses 41 - 45 are for agent applications
export const APP_STATUS = {
  INCOMPLETE: {
    value: 0,
    color: "yellow",
    name: "Incomplete",
    label: "Incomplete",
  },
  PENDING: { value: 1, color: "blue", name: "Pending", label: "Pending" },
  UNDER_REVIEW: {
    value: 2,
    color: "teal",
    name: "Under Review",
    label: "Under Review",
  },
  REQUIRE_CHANGES: {
    value: 3,
    color: "",
    name: "Require Changes",
    label: "Require Changes",
  },
  DECLINED: { value: 4, color: "", name: "Declined", label: "Declined" },
  APPROVED: { value: 5, color: "", name: "Approved", label: "Approved" },
  NEED_INFO: { value: 6, color: "", name: "Need Info", label: "Need Info" },
  EXPIRED: { value: 9, color: "", name: "Expired", label: "Expired" },
  PAYMENT_RECEIVED: {
    value: 10,
    color: "",
    name: "Payment Received",
    label: "Payment Received",
  },
  VISA_APPROVED: {
    value: 11,
    color: "",
    name: "Visa Approved",
    label: "Visa Approved",
  },
  REFUND_REQUESTED: {
    value: 12,
    color: "",
    name: "Refund Requested",
    label: "Refund Requested",
  },
  PENDING_APP_FEE: {
    value: 13,
    color: "",
    name: "Pending App Fee",
    label: "Pending App Fee",
  },
  VISA_REQUESTED: {
    value: 15,
    color: "",
    name: "Visa Requested",
    label: "Visa Requested",
  },
  DEFERRED: { value: 20, color: "", name: "Deferred", label: "Deferred" },
  PREREGISTERED: {
    value: 21,
    color: "",
    name: "Pre-registered",
    label: "Pre-registered",
  },
  NOT_REGISTERED: {
    value: 22,
    color: "",
    name: "Not Registered",
    label: "Not Registered",
  },
  REGISTERED: { value: 23, color: "", name: "Registered", label: "Registered" },
  LATE: { value: 24, color: "", name: "Late", label: "Late" },
  DEFER_REQUESTED: {
    value: 25,
    color: "",
    name: "Defer Requested",
    label: "Defer Requested",
  },
  PRE_SCREEN: {
    value: 26,
    color: "red",
    name: "Pre Screen",
    label: "Pre Screen",
  },
  SYSTEM_DECLINED: {
    value: 27,
    color: "",
    name: "System Declined",
    label: "System Declined",
  },
  WITHDRAW_REQUESTED: {
    value: 28,
    color: "",
    name: "Withdraw Requested",
    label: "Withdraw Requested",
  },
  PAID: { value: 29, color: "", name: "Paid", label: "Paid" },
  RESUBMITTED: {
    value: 31,
    color: "",
    name: "Resubmitted",
    label: "Resubmitted",
  },
  NEEDS_INFORMATION: {
    value: 32,
    color: "",
    name: "Needs Information",
    label: "Needs Information",
  },
  CLOSED: { value: 40, color: "", name: "Closed", label: "Closed" },
  REVOKED: { value: 48, color: "", name: "Revoked", label: "Revoked" },
  REFUNDED: { value: 49, color: "", name: "Refunded", label: "Refunded" },
  AG_UNDER_REVIEW: {
    value: 41,
    color: "yellow",
    name: "Under Review",
    label: "Under Review",
  },
  AG_REQUIRE_CHANGES: {
    value: 42,
    color: "grey",
    name: "Require Changes",
    label: "Require Changes",
  },
  AG_SIGNED: { value: 44, color: "teal", name: "Signed", label: "Signed" },
  AG_PARTNER: { value: 45, color: "green", name: "Partner", label: "Partner" },
  AG_TERMINATED: {
    value: 46,
    color: "",
    name: "Terminated",
    label: "Terminated",
  },
  AG_EXPIRED: { value: 47, color: "", name: "Expired", label: "Expired" },
  AG_REVOKED: { value: 48, color: "", name: "Revoked", label: "Revoked" },
  AG_REFUNDED: { value: 49, color: "", name: "Refunded", label: "Refunded" },
  AG_ON_HOLD: { value: 50, color: "", name: "On Hold", label: "On Hold" },
};

export const applicationStatus = statusCode => {
  switch (statusCode) {
    case 0:
      return {
        statusColor: "warning",
        color: "yellow",
        statusText: "Incomplete",
      };
    case 1:
      return { statusColor: "info", color: "blue", statusText: "Pending" };
    case 2:
      return {
        statusColor: "primary",
        color: "orange",
        statusText: "Under Review",
      };
    case 3:
      return {
        statusColor: "warning",
        color: "red",
        statusText: "Require Changes",
      };
    case 4:
      return { statusColor: "danger", color: "red", statusText: "Declined" };
    case 5:
      return { statusColor: "success", color: "green", statusText: "Approved" };
    case 6:
      return {
        statusColor: "warning",
        color: "orange",
        statusText: "Need Info",
      };
    case 9:
      return { statusColor: "danger", color: "red", statusText: "Expired" };
    case 10:
      return { statusColor: "success", color: "blue", statusText: "Payment" };
    case 11:
      return {
        statusColor: "success",
        color: "green",
        statusText: "Visa Approved",
      };
    case 12:
      return {
        statusColor: "warning",
        color: "pink",
        statusText: "Refund Requested",
      };
    case 13:
      return {
        statusColor: "warning",
        color: "yellow",
        statusText: "Pending App Fee",
      };
    case 15:
      return {
        statusColor: "primary",
        color: "teal",
        statusText: "Visa Requested",
      };
    case 20:
      return {
        statusColor: "warning",
        color: "yellow",
        statusText: "Deferred",
      };
    case 21:
      return {
        statusColor: "success",
        color: "green",
        statusText: "Pre-registered",
      };
    case 22:
      return {
        statusColor: "danger",
        color: "red",
        statusText: "Not Registered",
      };
    case 23:
      return {
        statusColor: "success",
        color: "green",
        statusText: "Registered",
      };
    case 24:
      return { statusColor: "danger", color: "pink", statusText: "Late" };
    case 25:
      return {
        statusColor: "warning",
        color: "yellow",
        statusText: "Defer Requested",
      };
    case 26:
      return { statusColor: "info", color: "orange", statusText: "Pre Screen" };
    case 27:
      return {
        statusColor: "danger",
        color: "red",
        statusText: "System Declined",
      };
    case 28:
      return {
        statusColor: "warning",
        color: "",
        statusText: "Withdraw Requested",
      };
    case 29:
      return { statusColor: "success", color: "", statusText: "Paid" };
    case 30:
      return { statusColor: "info", color: "", statusText: "Visa Requested" };
    case 31:
      return { statusColor: "info", color: "", statusText: "Resubmitted" };
    case 32:
      return {
        statusColor: "warning",
        color: "",
        statusText: "Needs Information",
      };
    case 33:
      return {
        statusColor: "warning",
        color: "",
        statusText: "Pending App Fee",
      };
    case 40:
      return { statusColor: "danger", color: "red.8", statusText: "Closed" };
    case 41:
      return { statusColor: "primary", color: "", statusText: "Under Review" };
    case 42:
      return {
        statusColor: "warning",
        color: "yellow",
        statusText: "Require Changes",
      };
    case 43:
      return { statusColor: "info", color: "green", statusText: "Approved" }; // unused
    case 44:
      return { statusColor: "success", color: "green", statusText: "Signed" };
    case 45:
      return { statusColor: "success", color: "green", statusText: "Partner" };
    case 46:
      return { statusColor: "danger", color: "red", statusText: "Terminated" };
    case 47:
      return { statusColor: "danger", color: "red", statusText: "Expired" };
    case 48:
      return { statusColor: "danger", color: "red", statusText: "Revoked" };
    case 49:
      return {
        statusColor: "warning",
        color: "yellow",
        statusText: "Refunded",
      };
    case 50:
      return { statusColor: "warning", color: "yellow", statusText: "On Hold" };
    default:
      return { statusColor: "secondary", color: "gray", statusText: "Unknown" };
  }
};
