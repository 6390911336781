import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Form, Button } from "tabler-react";
import isEmpty from "../../utils/is-empty";
import { termsList } from "../../utils/contentView";

class PortalSettings extends React.Component {

    // Constructor function
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <Modal isOpen={this.props.announcementModal} toggle={this.props.toggle}>
            <ModalBody>
            <Form.Group
              label="Notification Email"
              isRequired
            >
              <Form.Input
                name="emailTo"
                value={this.props.settings.emailTo}
                onChange={this.props.onChange}
                placeholder="international@queenscollege.ca"
                feedback={
                  isEmpty(this.props.errors.emailTo)
                    ? ""
                    : this.props.errors.emailTo
                }
                invalid={isEmpty(this.props.errors.emailTo) ? false : true}
              />
            </Form.Group>
            <Form.Group
              label="Reply-to Email"
              isRequired
            >
              <Form.Input
                name="emailFrom"
                value={this.props.settings.emailFrom}
                onChange={this.props.onChange}
                placeholder="international@queenscollege.ca"
                feedback={
                  isEmpty(this.props.errors.emailFrom)
                    ? ""
                    : this.props.errors.emailFrom
                }
                invalid={isEmpty(this.props.errors.emailFrom) ? false : true}
              />
            </Form.Group>
            <Form.Group label="Payment deadline" isRequired >
                      <Form.MaskedInput id="datePicker"
                        value={this.props.settings.paymentDeadline}
                        placeholder="0000-00-00"
                        name="paymentDeadline"
                        onChange={this.props.onChange}
                        feedback={
                          isEmpty(this.props.errors.paymentDeadline)
                            ? ""
                            : this.props.errors.paymentDeadline
                        }
                        invalid={isEmpty(this.props.errors.paymentDeadline) ? false : true}
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </Form.Group>
                    <Form.Group label="Payment deadline for EAP" isRequired >
                      <Form.MaskedInput id="datePicker"
                        value={this.props.settings.paymentDeadlineEAP}
                        placeholder="0000-00-00"
                        name="paymentDeadlineEAP"
                        onChange={this.props.onChange}
                        feedback={
                          isEmpty(this.props.errors.paymentDeadlineEAP)
                            ? ""
                            : this.props.errors.paymentDeadlineEAP
                        }
                        invalid={isEmpty(this.props.errors.paymentDeadlineEAP) ? false : true}
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </Form.Group>
            <Form.Group label="Select the current terms"  isRequired>
              <Form.Select
                name="term"
                value={this.props.settings.term}
                onChange={this.props.onChange}
                feedback={
                  isEmpty(this.props.errors.term)
                    ? ""
                    : this.props.errors.term
                }
                invalid={isEmpty(this.props.errors.term) ? false : true}
              >
                <option value={null}>Select a user type</option>
                {termsList(this.props.terms)}
              </Form.Select>
            </Form.Group>

            </ModalBody>
            <Button
              color="primary"
              onClick={this.props.onClick}
            >
            Update the settings
            </Button>{" "}
        </Modal>
        )
    }

}

export default PortalSettings;
