import React, { useEffect } from "react";
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Paper,
  Select,
  TextInput,
  Title,
} from "@mantine/core";
import { useId } from "@mantine/hooks";
import { useGetOneApplication } from "./api";
import { countryList } from "../../utils/countriesList";
import { useAppStore } from "../../lib/zustandStore";
import { IconX } from "@tabler/icons-react";

const Step3 = ({ form }) => {
  const appId = useAppStore(state => state.appId);
  // For masked input
  const id = useId();

  const { data, isLoading } = useGetOneApplication(appId);

  //  [] [0,1,2,3]
  //  [0,
  const handleDelete = idx => {
    let temp = form.values.academics.slice();

    temp.splice(idx, 1);

    form.setValues(values => ({
      ...values,
      academics: temp,
    }));
  };
  const handleAdd = () => {
    form.setValues(values => ({
      ...values,
      academics: [...values.academics, {}],
    }));
  };

  useEffect(
    () => {
      if (data) {
        form.setValues(values => ({
          ...values,
          academics: data.ApplicationAcademics,
        }));
      }
    },
    [data]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Paper shadow="xs" px="md" py="xl">
        <Flex justify="space-between">
          <Title size="h3">Academic History</Title>
          <Button onClick={handleAdd}>Add Entry</Button>
        </Flex>
        {form.values.academics.map((_academic, index) => (
          <AcademicComp
            form={form}
            index={index}
            key={index}
            handleDelete={handleDelete}
          />
        ))}
      </Paper>
    </>
  );
};

const AcademicComp = ({ form, index, handleDelete }) => {
  return (
    <>
      <Divider my="lg" />
      {/*
      <Flex justify="right">
        <ActionIcon color="red">
          <IconX onClick={() => handleDelete(index)} />
        </ActionIcon>
      </Flex>
      */}
      <Grid>
        <Grid.Col>
          <Select
            required
            label="Institute Level"
            data={[
              { label: "Secondary / High School", value: 0 },
              { label: "College", value: 1 },
              { label: "University", value: 2 },
            ]}
            {...form.getInputProps(`academics.${index}.type`)}
          />
        </Grid.Col>
        <Grid.Col>
          <TextInput
            required
            label="Institute Name"
            {...form.getInputProps(`academics.${index}.instituteName`)}
          />
        </Grid.Col>
        <Grid.Col>
          <Select
            required
            label="Status"
            data={[
              { label: "Completed", value: false },
              { label: "In Progress", value: true },
            ]}
            {...form.getInputProps(`academics.${index}.currentStatus`)}
          />
        </Grid.Col>
        <Grid.Col>
          <TextInput
            required
            type="number"
            label="First Year of Attendance"
            {...form.getInputProps(`academics.${index}.firstYear`)}
          />
        </Grid.Col>
        <Grid.Col>
          <TextInput
            required
            type="number"
            label="Last Year of Attendance"
            {...form.getInputProps(`academics.${index}.lastYear`)}
          />
        </Grid.Col>
        <Grid.Col>
          <Select
            required
            searchable
            label="Country"
            data={countryList}
            {...form.getInputProps(`academics.${index}.country`)}
          />
        </Grid.Col>
        <Grid.Col>
          <TextInput
            required
            label="Province/State"
            {...form.getInputProps(`academics.${index}.province`)}
          />
        </Grid.Col>
        <Grid.Col>
          <TextInput
            required
            label="City"
            {...form.getInputProps(`academics.${index}.city`)}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default Step3;
