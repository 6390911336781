import Axios from "axios";
import { useMutation, useQuery } from "react-query";
import queryClient from "../../lib/queryClient";
import config from "../../config/config";

export const useUploadFile = () => {
  return useMutation({
    mutationFn: formData => {
      return Axios.post(`${config.server}/documents/v2/upload`, formData);
    },
    onSuccess: () => queryClient.invalidateQueries(["my_files"]),
  });
};

export const useFetchAllDocuments = () => {
  return useQuery("public-documents", async () => {
    const res = await Axios.get(`${config.server}/documents/all/public`);
    return res.data;
  });
};

export const useDeletePublicDocument = () => {
  return useMutation(
    "deletePublicDoc",
    async code => {
      const res = await Axios.delete(
        `${config.server}/documents/delete-public`,
        {
          data: {
            document: code,
          },
        }
      );

      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["public-documents"]);
      },
    }
  );
};
