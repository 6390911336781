import { GET_PAYMENTS, SET_PAYMENTS, GET_ERRORS } from "./types";

import axios from "axios";
import config from "../config/config.js";

// Get the payments list
export const getPayments = data => dispatch => {
  axios
    .get(`${config.server}${config.api.paymentList}`, data)
    .then(res => {
      return dispatch({
        type: GET_PAYMENTS,
        payload: res.data,
      });
    })
    .catch(err => {
      return dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      });
    });
};

// Update the payment content
export const setPayments = data => async dispatch => {
  await axios
    .post(`${config.server}${config.api.paymentSet}`, data)
    .then(res => {
      return dispatch({
        type: SET_PAYMENTS,
        payload: res.data,
      });
    })
    .catch(err => {
      return dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      });
    });
};
