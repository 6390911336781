import { Badge, Button, Indicator, ScrollArea, Table } from "@mantine/core";
import React from "react";
import dayjs from "dayjs";
import { applicationStatus } from "../../utils/applicationStatus";
import { tableHeaders } from "./Applications2";
import { useHistory } from "react-router-dom";

const ApplicationsTable = ({ appData, isLoading }) => {
  const history = useHistory();

  /** @param {number} id */
  const handleView = id => {
    history.push(`/application/view/${id}`);
  };

  const tableBodyData = () => {
    return appData?.map(e => (
      <tr key={e.id}>
        <td>{e.id}</td>
        <td>
          <Indicator
            disabled={e.notifications > 0}
            position="top-start"
            color="red"
          >
            {e.firstName} {e.lastName}
          </Indicator>
        </td>
        <td>{e.nationality}</td>
        <td>{e.User.name}</td>
        <td>
          <Badge color={applicationStatus(e.status).color}>
            {applicationStatus(e.status).statusText}
          </Badge>
        </td>
        <td>{e.ProgramsAvailable.Term?.name}</td>
        <td>{e.ProgramsAvailable.Program.institution}</td>
        <td>{e.ProgramsAvailable.Program.code}</td>
        <td>{dayjs(e.createdAt).format("MMM DD YYYY")}</td>
        <td>
          <Button variant="outline" onClick={() => handleView(e.id)}>
            View
          </Button>
        </td>
      </tr>
    ));
  };

  if (isLoading) {
    return <>Loading</>;
  }

  return (
    <ScrollArea mb={8}>
      <Table verticalSpacing="xs" withBorder striped>
        <thead>
          <tr>
            {tableHeaders.map(e => (
              <th key={e}>{e}</th>
            ))}
          </tr>
        </thead>
        <tbody>{tableBodyData()}</tbody>
      </Table>
    </ScrollArea>
  );
};

export default ApplicationsTable;
