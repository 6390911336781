import { Button, Group, Modal, Select, Stack, TextInput } from "@mantine/core";
import React, { Fragment, useEffect } from "react";
import { useForm } from "@mantine/form";
import { useAddEditProgram } from "./api";
import { notifications } from "@mantine/notifications";

const institutionTypes = [
  {
    label: "Toronto",
    value: "Toronto",
  },
  {
    label: "Belleville",
    value: "Belleville",
  },
  {
    label: "Port Hope",
    value: "Port Hope",
  },
];

const ProgramForm = ({ opened, close, addEdit, data = {} }) => {
  const form = useForm({
    initialValues: {
      id: 0,
      code: "",
      name: "",
      url: "",
      institution: "",
    },
    validate: {
      code: value =>
        value.trim().length < 1
          ? "Code must be at least 1 character long"
          : null,
      name: value =>
        value.trim().length < 1
          ? "Name must be at least 1 character long"
          : null,
      url: value =>
        value.trim().length < 1 ? "Must include a website url" : null,
      institution: value =>
        value.trim().length < 1 ? "Must select an institution" : null,
    },
  });

  useEffect(
    () => {
      form.setValues(data);
      form.resetDirty(data);
    },
    [data]
  );

  const { mutateAsync } = useAddEditProgram();

  const handleAddEditProgram = async () => {
    const data = form.getTransformedValues();
    const res = await mutateAsync(data);
    if (res.data.success === true) {
      close();
      notifications.show({
        title: "Program added/updated",
        autoClose: 5000,
        color: "teal",
      });
    }
  };

  return (
    <Fragment>
      <Modal
        opened={opened}
        onClose={close}
        title={
          addEdit === "add" ? "Add New Program" : `Edit Program - ${data.id}`
        }
      >
        <form onSubmit={form.onSubmit(handleAddEditProgram)}>
          <Stack mb={16}>
            <TextInput
              label="Code"
              placeholder="SCM"
              required
              {...form.getInputProps("code")}
            />
            <TextInput
              label="Name"
              placeholder="Supply Change Management"
              required
              {...form.getInputProps("name")}
            />
            <TextInput
              label="Website"
              placeholder="www.tbcollege.com"
              required
              {...form.getInputProps("url")}
            />
            <Select
              label="Institution"
              data={institutionTypes}
              required
              {...form.getInputProps("institution")}
            />
          </Stack>
          <Group position="right">
            <Button type="submit">
              {addEdit === "add" ? "Create" : "Edit"}
            </Button>
          </Group>
        </form>
      </Modal>
    </Fragment>
  );
};

export default ProgramForm;
