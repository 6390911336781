import { GET_ERRORS, GET_ALL_PROGRAMS, GET_AVAILABLE_PROGRAMS } from "./types";

import axios from "axios";
import isEmpty from "../utils/is-empty";
import config from "../config/config.js";

// Add a new programs
export const addRequests = (programData, requestUrl) => async dispatch => {
  // Send the axios request to backend server
  await axios
    .post(requestUrl, programData)
    .then(res => {
      // Return the data to the reducer
      dispatch({
        type: GET_AVAILABLE_PROGRAMS,
        playload: res.data,
      });
      dispatch({
        type: GET_ERRORS,
        playload: {},
      });
    })
    .catch(err => {
      // Return the data to the reducer
      dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      });
    });
};

// Change the status of the term
export const changeTermStatus = data => async dispatch => {
  // Send the request to the backend server
  await axios
    .delete(`${config.server}${config.api.programsTerm}`, { data: data })
    .then(res => {
      // Return the data to the reducer
      return dispatch({
        type: GET_AVAILABLE_PROGRAMS,
        playload: res.data,
      });
    })
    .catch(err => {
      return dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      });
    });
};

// Get the list of all programs
export const programsList = queryString => async dispatch => {
  queryString = (!isEmpty(queryString)) ? `/${queryString}` : "";
  // Send the get request to backend server
  await axios
    .get(`${config.server}${config.api.programsList}${queryString}`)
    .then(res => {
      // Return the data to the reducer
      dispatch({
        type: GET_ALL_PROGRAMS,
        playload: res.data,
      });
    })
    .catch(err => console.log(err));
};


// Get the list of all programs
export const availability = queryString => async dispatch => {
  queryString = (!isEmpty(queryString)) ? `/${queryString}` : "";
  // Send the get request to backend server
  await axios
    .get(`${config.server}${config.api.availability}${queryString}`)
    .then(res => {
      // Return the data to the reducer
      dispatch({
        type: GET_AVAILABLE_PROGRAMS,
        playload: res.data,
      });
    })
    .catch(err => console.log(err));
};
