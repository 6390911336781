// @flow

import * as React from "react";
import { Formik } from "formik";
import { LoginPage as TablerLoginPage } from "tabler-react";
import { connect } from "react-redux";
import { userLogin } from "../actions/authActions";
import PropTypes from "prop-types";

class LoginPage extends React.Component {
  constructor() {
    super();
    // Set the initial state of the componenet
    this.state = {
      email: "",
      password: "",
      errors: {},
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/applications");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/applications");
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  // On submit form
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    // Prevent form submission
    e.preventDefault();

    // Set the new state elements
    const newLogin = {
      email: this.state.email,
      password: this.state.password,
    };

    // Call the user login action
    this.props.userLogin(newLogin);
  }

  render() {
    return (
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validate={values => {
          // same as above, but feel free to move this into a class method now.
          let errors = {};
          if (!this.state.email) {
            errors = this.state.errors;
            errors.email = "Required";
            this.setState({ errors: errors });
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
          ) {
            errors = this.state.errors;
            errors.email = "Invalid email address";
            this.setState({ errors: errors });
          }
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <TablerLoginPage
            onSubmit={this.handleSubmit}
            onChange={this.handleChange}
            onBlur={handleBlur}
            values={{
              email: this.state.email,
              password: this.state.password,
            }}
            errors={this.state.errors}
            touched={touched}
          />
        )}
      />
    );
  }
}

LoginPage.propTypes = {
  userLogin: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(
  mapStateToProps,
  { userLogin }
)(LoginPage);
