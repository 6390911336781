import {
  CURRENT_APP_STEP1,
  CURRENT_APP_STEP2,
  CURRENT_APP_STEP3,
  CURRENT_APP_STEP4,
  GET_ALL_APPLICATIONS,
  GET_CURRENT_APPLICATION,
  SET_CURRENT_APPLICATION,
  APPLICATION_COMMENT,
  APP_STATUS,
  APP_AGENT,
} from "../actions/types";
import isEmpty from "../utils/is-empty";

const initialState = {
  applications: [],
  currentApplication: {},
  editMode: false,
  submissionMode: false,
  currentApplicationStep1: {},
  currentApplicationID: "",
  count: 0,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_APPLICATIONS:
      return {
        ...state,
        applications: action.playload[0],
        count: action.playload[1],
      };
    case GET_CURRENT_APPLICATION:
      return {
        ...state,
        currentApplication: action.playload,
      };
    case APP_STATUS:
      return {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          status: action.playload,
        },
      };
    case APP_AGENT:
      return {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          agent: action.playload,
        },
      };
    case SET_CURRENT_APPLICATION:
      return {
        ...state,
        editMode: action.playload.status,
        currentApplicationID: action.playload.id,
      };
    case CURRENT_APP_STEP1:
      return {
        ...state,
        currentApplication: Object.assign(
          {},
          state.currentApplication,
          action.playload
        ),
        currentApplicationID: action.playload.id,
        submissionMode: true,
      };
    case CURRENT_APP_STEP2:
      return {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          ApplicationLanguage: Object.assign(
            {},
            state.currentApplication.ApplicationLanguage,
            action.playload
          ),
        },
      };
    case CURRENT_APP_STEP3:
      return {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          ApplicationAcademics: action.playload,
        },
      };
    case CURRENT_APP_STEP4:
      return {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          ApplicationDocuments: action.playload.files,
        },
      };
    case APPLICATION_COMMENT:
      return {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          applicationLogs: action.playload,
        },
      };

    default:
      return state;
  }
}
