import React from "react";
import { useFetchAllPrograms } from "./api";
import {
  ActionIcon,
  Anchor,
  Button,
  Container,
  Flex,
  Paper,
  Table,
  Title,
} from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { renderFor } from "../../utils/renderComp";
import queryClient from "../../lib/queryClient";

const ProgramTable = ({ openProgramForm }) => {
  const { data, isLoading } = useFetchAllPrograms();
  const myInfo = queryClient.getQueryData("myInfo");

  const actionButtons = program => {
    return (
      <Flex>
        <ActionIcon
          color="blue"
          size="lg"
          variant="outline"
          mr={8}
          onClick={() => openProgramForm(program, "edit")}
        >
          <IconEdit size="1.2rem" />
        </ActionIcon>
        <ActionIcon color="red" size="lg" variant="outline">
          <IconTrash size="1.2rem" />
        </ActionIcon>
      </Flex>
    );
  };

  const tableHeaders = () => {
    return (
      <tr>
        <th>NAME</th>
        {/* <th>CODE</th> */}
        <th>INSTITUTION</th>
        <th />
        <th />
      </tr>
    );
  };

  const tableRows = () => {
    return data.map(program => (
      <tr key={program.id}>
        <td>{program.name}</td>
        {/* <td>{program.code}</td> */}
        <td>{program.institution}</td>
        <td>
          <Anchor href={program.url} target="_blank">
            More Details
          </Anchor>
        </td>
        <td>
          {renderFor([0, 2], true, myInfo?.permission) &&
            actionButtons(program)}
        </td>
      </tr>
    ));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (data.length === 0) {
    return <div>Something went wrong</div>;
  }

  return (
    <Container size="lg" my={32} py={16} px={0}>
      <Paper shadow="xs" p="md">
        <Flex justify="space-between">
          <Title size="h3" ml={16}>
            Program Information
          </Title>
          {renderFor([0], true, myInfo?.permission) && (
            <Button
              color="pink"
              onClick={() => openProgramForm(undefined, "add")}
            >
              Add new program
            </Button>
          )}
        </Flex>
        <Table>
          <thead>{tableHeaders()}</thead>
          <tbody>{tableRows()}</tbody>
        </Table>
      </Paper>
    </Container>
  );
};

export default ProgramTable;
