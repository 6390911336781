import React, { useState } from "react";
import {
  Anchor,
  Button,
  Divider,
  FileInput,
  Grid,
  List,
  Paper,
  Select,
  Text,
  Title,
} from "@mantine/core";
import { useAppStore } from "../../lib/zustandStore";
import { useGetOneApplication, useUploadAppDocument } from "./api";
import queryClient from "../../lib/queryClient";
import RenderIcon from "./RenderIcon";
import { documentTypes } from "./constants";
import config from "../../config/config";
import Axios from "axios";

const Step4 = ({ form }) => {
  const [file, setFile] = useState(/** @type {File | null} */ null);
  const [docType, setDocType] = useState(/** @type {string | null} */ null);
  const appId = useAppStore(state => state.appId);

  const { data, isLoading } = useGetOneApplication(appId);

  const fileUpload = useUploadAppDocument();

  const handleUploadFile = async () => {
    // if null, don't try upload
    if (file === null) {
      return;
    }
    if (docType === null) {
      alert("Please select a document type");
      return;
    }

    if (docType === "6") {
      const studentId = file.name.substring(0, file.name.indexOf("_"));
      await Axios.post(`${config.server}${config.api.applicationStudentId}`, {
        id: appId,
        studentId,
      });
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentType", docType);
    formData.append("application", appId);

    await fileUpload.mutateAsync(formData);
    await queryClient.invalidateQueries(["application", appId]);

    // on successful upload reset the file
    setFile(null);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Paper shadow="xs" px="md" py="xl" mb="xl">
        <Title size="h3">Documents</Title>
        <Divider my="lg" />
        <Title order={4}>
          List of the documents that your are required to submit:
        </Title>
        <List>
          <List.Item> Copy of the applicant's passport</List.Item>
          <List.Item> Academic transcripts</List.Item>
          <List.Item>
            Academic degrees/certificates with consolidate GPA/Passing Class
          </List.Item>
          <List.Item> Provisional Certificates if applicable</List.Item>
          <List.Item> English test score if applicable</List.Item>
          <List.Item>
            Documents supporting employment history if applicable
          </List.Item>
          <List.Item>
            Notice of Disclosure-Confidentiality and Use of Personal Data
          </List.Item>
        </List>
        <Divider my="lg" />
        <Title order={4}>Important Note</Title>
        <Text>
          Please note that the application is saved up to this point and can be
          completed later. You can also contact support for any help by emailing
          at{" "}
          <Anchor href="mailto:loyalistinternational@tbcollege.com">
            loyalistinternational@tbcollege.com
          </Anchor>
        </Text>
      </Paper>
      <Paper shadow="xs" px="md" py="xl" mb="xl">
        <Title size="h3">File Upload</Title>
        <Divider my="lg" />
        <Text mb="md">
          Files should be in PDF/JPG/PNG format and file size should be less
          than 5MB.
        </Text>

        <Select
          data={documentTypes}
          onChange={setDocType}
          label="Document Type"
        />

        <FileInput
          label="Select File"
          accept="image/*, application/pdf"
          onChange={file => setFile(file)}
          value={file}
          mt="lg"
        />
        <Button
          mt="md"
          onClick={() => handleUploadFile()}
          loading={fileUpload.isLoading}
        >
          Upload
        </Button>
      </Paper>
      <Paper shadow="xs" px="md" py="xl">
        <Title size="h3">Your Files</Title>
        <Divider my="lg" />
        <Grid>
          {data?.ApplicationDocuments.map(file => (
            <>
              <Grid.Col span={4}>
                {<RenderIcon fileType={file?.extension} />}{" "}
                <strong>
                  {file.name}.{file.extension}
                </strong>
              </Grid.Col>
              <Grid.Col span={8}>
                {
                  documentTypes.filter(e => e.value == file.documentType)[0]
                    ?.label
                }
              </Grid.Col>
            </>
          ))}
        </Grid>
      </Paper>
    </>
  );
};

export default Step4;
