import {
  IconFile,
  IconFileTypeJpg,
  IconFileTypePdf,
  IconFileTypePng,
} from "@tabler/icons-react";
import React from "react";

const RenderIcon = ({ fileType }) => {
  switch (fileType) {
    case "pdf": {
      return <IconFileTypePdf color="red" />;
    }
    case "jpg": {
      return <IconFileTypeJpg color="blue" />;
    }
    case "jpeg": {
      return <IconFileTypeJpg color="blue" />;
    }
    case "png": {
      return <IconFileTypePng color="teal" />;
    }
    default:
      return <IconFile />;
  }
};

export default RenderIcon;
