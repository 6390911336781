import { GET_ALL_PROGRAMS, GET_AVAILABLE_PROGRAMS } from "../actions/types";
import isEmpty from "../utils/is-empty";

const initialState = {
  list: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PROGRAMS:
      return {
        ...state,
        list: action.playload,
      };
    case GET_AVAILABLE_PROGRAMS:
      return {
        ...state,
        available: action.playload,
      }
    default:
      return state;
  }
}
