import { Button, Center, Flex, Select, Switch, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { APP_STATUS } from "../../utils/applicationStatus";
import queryClient from "../../lib/queryClient";
import AgentSearchBar from "../../components/common/AgentSearchBar";
import { useGetAllTerms } from "./api";

/**
 * @param {Object} props
 * @param {Object} props.filterValues
 */
const FilterBar = ({ filterValues, setFilterValues }) => {
  const {
    searchType,
    searchStatus,
    searchQuery,
    searchTerm,
    searchAgent,
    searchDate,
    diversity,
  } = filterValues;

  const [statusOptions, setStatusOptions] = useState([]);

  const myInfo = queryClient.getQueryData("myInfo");
  const { isLoading: termsIsLoading, data: termsData } = useGetAllTerms();

  const handleFilterChange = (value, key) => {
    setFilterValues({ [key]: value || "" });
  };

  useEffect(
    () => {
      handleStatusOptions();
    },
    [myInfo]
  );

  const handleStatusOptions = () => {
    const data = Object.values(APP_STATUS).map(e => ({
      value: e.value,
      label: e.name,
    }));
    setStatusOptions(data);
  };

  return (
    <Center my={8}>
      <Flex>
        <TextInput
          mr={4}
          placeholder="Search for..."
          value={searchQuery}
          onChange={e => handleFilterChange(e.target.value, "searchQuery")}
        />
        <Select
          mr={4}
          data={["1", "2", "3"]}
          value={searchType}
          onChange={e => handleFilterChange(e, "searchType")}
        />
        <AgentSearchBar
          onChange={e => handleFilterChange(e, "searchAgent")}
          value={searchAgent}
        />
        <Select
          clearable
          placeholder="Term"
          name="term"
          mr={4}
          data={
            termsIsLoading
              ? []
              : termsData?.map(e => ({ label: e.name, value: e.id }))
          }
          value={searchTerm}
          onChange={e => handleFilterChange(e, "searchTerm")}
        />
        <Select
          clearable
          placeholder="Program"
          name="term"
          mr={4}
          data={["SCM", "CST", "Business"]}
          value={searchTerm}
          onChange={e => handleFilterChange(e, "searchTerm")}
        />
        <Select
          clearable
          placeholder="Date Order"
          name="dateOrder"
          mr={4}
          data={[
            { value: "ASC", label: "Ascending" },
            { value: "DESC", label: "Descending" },
          ]}
          value={searchDate}
          onChange={e => handleFilterChange(e, "searchDate")}
        />
        <Select
          clearable
          name="status"
          mr={4}
          maxDropdownHeight={500}
          data={statusOptions}
          value={searchStatus}
          onChange={e => handleFilterChange(e, "searchStatus")}
        />
        {/* <Switch */}
        {/*   label="diversity" */}
        {/*   checked={diversity} */}
        {/*   onChange={() => handleFilterChange(diversity, "diversity")} */}
        {/* /> */}
        {/* <Button>Search</Button> */}
      </Flex>
    </Center>
  );
};

export default FilterBar;
