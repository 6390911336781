import React from "react";
import { Route, Switch } from "react-router-dom";
import AgentStep1 from "./AgentStep1.jsx";
import AgentAppViewV2 from "./AgentAppViewV2.jsx";

const AgentAppRoute = () => {
  return (
    <Switch>
      {/* <Route path="/agentapp/view/:id" component={AgentAppView} /> */}
      <Route path="/agentapp/view/:id" component={AgentAppViewV2} />
      <Route path="/agentapp/submit/:id" component={AgentStep1} />
      <Route path="/agentapp/edit/:id" component={AgentStep1} />
    </Switch>
  );
};

export default AgentAppRoute;
