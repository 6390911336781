import React, { Component } from "react";
import { Avatar } from "tabler-react";

export const avatarGenerator = (text) => {

    // Colors available 
    const colors = [
        "blue",
        "azure",
        "indigo",
        "purple",
        "pink",
        "red",
        "orange",
        "yellow",
        "lime",
        "green",
        "teal",
        "cyan",
        "gray",
        "gray-dark"
    ];

    // Get the alphabets 
    const alphabets = 'abcdefghijklmnopqrstuvwxyz'.split('');

    text = text.toLowerCase();
    // Get the first letter
    const character = text.substring(0, 1);
    
    // Get the aplhabeth index
    const currentIndex = alphabets.indexOf(character);

    //Search for the color
    const color = [].concat(...Array(2).fill(colors))[currentIndex];
    
    // Return the avatar
    const name = text.toUpperCase().substring(0, 2);
    return <Avatar color={color}>{name}</Avatar>

}


