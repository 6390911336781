// Hello

import React from "react";
import SiteWrapper from "../SiteWrapper.react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import isEmpty from "../utils/is-empty";
import { Link } from "react-router-dom";
import { readFileFrom } from "../utils/fileReader";
import { getPayments, setPayments } from "../actions/paymentsActions.js";
import Swal from "sweetalert2";
import {
  Page,
  Table,
  Progress,
  Text,
  Icon,
  Form,
  Button,
  Grid,
  Card,
} from "tabler-react";
import csvtojson from "csvtojson";

class Payments extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    // Set the initial state
    this.state = {
      dataUpload: false,
      processing: false,
      file: {},
      errors: {},
    };

    // Bind the functions
    this.toggleUpload = this.toggleUpload.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.onChange = this.onChange.bind(this);
    this.paymentListView = this.paymentListView.bind(this);
  }

  // Get all the payments
  componentWillMount() {
    this.props.getPayments();
  }

  // Set the errors state
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  // Toggle the model
  toggleUpload() {
    this.setState({
      dataUpload: !this.state.dataUpload,
    });
  }

  // Function to check the file upload
  async handleUpload() {
    // Set the processing to loading
    this.setState({ processing: true });
    // Get the file
    const file = this.state.file;

    // Check if file is empty
    if (isEmpty(file.name) || file.type.search("csv") == -1) {
      Swal.fire({
        type: "error",
        title: "Please select a CSV file",
      });
      this.setState({ processing: false });

      return false;
    }

    // Read the file content
    const fileContent = await readFileFrom(file);

    // Convert CSV to json
    csvtojson({ noheader: false })
      .fromString(fileContent)
      .then(async data => {
        // Send data to backend to update the file
        await this.props.setPayments(data);
        // End the processing & toggle
        this.setState({ processing: false, dataUpload: false });

        // Check for the errors
        if (!isEmpty(this.props.errors)) {
          // Check the type of error
          const errors = this.props.errors.notfound;
          let message =
            "Please fix the following errors and upload again <br><br>";

          if (!isEmpty(errors)) {
            // Check if the errors is an object
            typeof errors == "string"
              ? (message +=
                  '<div class="alert alert-danger" role="alert">' +
                  errors +
                  "</div>")
              : errors.map(obj => {
                  let values = Object.values(obj);
                  message +=
                    '<div class="alert alert-danger" role="alert">' +
                    values.join("<br>") +
                    "</div>";
                });
          } else {
            message = "Something went wrong! Please contact support";
          }

          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: message,
          });
        } else {
          Swal.fire(
            "File synced",
            "Payment info is now up to date!",
            "success"
          ).then(success => {
            window.location.reload();
          });
        }
      });

    this.setState({ file: {} });
  }

  // Onchange
  onChange(e) {
    this.setState({ file: e.target.files[0] });
  }

  // Function to get() the progress
  getPaymentProgress(amountPaid, totalAmount) {
    // Get the percentage
    const percentage = Math.round((amountPaid / totalAmount) * 100);
    let pending = totalAmount - amountPaid;

    // Check if paid amount is more then actual amount
    if (pending < 0) {
      pending = 0;
    }

    if (percentage < 50) {
      return {
        statusColor: "danger",
        percentage: percentage,
        text: "Incomplete",
        pending: pending,
      };
    } else if (percentage >= 50 && percentage <= 70) {
      return {
        statusColor: "warning",
        percentage: percentage,
        text: "Initial payment",
        pending: pending,
      };
    } else if (percentage > 70 && percentage <= 99) {
      return {
        statusColor: "azure",
        percentage: percentage,
        text: "Almost complete",
        pending: pending,
      };
    } else {
      return {
        statusColor: "success",
        percentage: percentage,
        text: "Complete",
        pending: pending,
      };
    }
  }

  // Payment list view
  paymentListView() {
    // Check if the payment is empty
    if (isEmpty(this.props.payments.paymentList)) {
      return (
        <div className="notfound">
          <img src="/assets/icons/documents.png" />
          <span>
            No data to show! <br /> Please upload the csv file
          </span>
          <Button color="primary" onClick={this.toggleUpload}>
            Upload Excel
          </Button>
        </div>
      );
    }
    // Set the result
    let result = [];
    // Get the payments view
    const payments = this.props.payments.paymentList;

    payments.map(payment => {
      // GET THE DATES
      const startDateObject = new Date(Date.parse(payment.startDate));
      const startDate = startDateObject.toDateString();
      const endDateObject = new Date(Date.parse(payment.endDate));
      const endDate = endDateObject.toDateString();

      // get the amount percentage
      const amount = this.getPaymentProgress(
        payment.total,
        payment.Application.ProgramsAvailable.Program.tutionFee
      );

      result.push(
        <Table.Row>
          <Table.Col>
            <div>
              {payment.Application.firstName +
                " " +
                payment.Application.lastName}
            </div>
            <Text size="sm" muted>
              Student ID: {payment.Application.studentId}
            </Text>
          </Table.Col>
          <Table.Col>
            <div className="clearfix">
              <div className="float-left">
                <strong>{amount.percentage}%</strong>
              </div>
              <div className="float-right">
                <Text.Small muted>{startDate + " - " + endDate}</Text.Small>
              </div>
            </div>
            <Progress size="xs">
              <Progress.Bar
                color={amount.statusColor}
                width={amount.percentage}
              />
            </Progress>
          </Table.Col>
          <Table.Col alignContent="center">
            <Icon payment name="westernunion-dark" />
          </Table.Col>
          <Table.Col>
            <div>${payment.total}</div>
          </Table.Col>
          <Table.Col alignContent="center">${amount.pending}</Table.Col>
          <Table.Col alignContent="center">
            <Link to={`application/view/${payment.Application.id}`}>
              <Button color="secondary" size="sm">
                View Application
              </Button>
            </Link>
          </Table.Col>
        </Table.Row>
      );
    });

    return (
      <Card>
        <Table
          responsive
          highlightRowOnHover
          hasOutline
          verticalAlign="center"
          cards
          className="text-nowrap"
        >
          <Table.Header>
            <Table.Row>
              <Table.ColHeader>Student/Agent Name</Table.ColHeader>
              <Table.ColHeader>Amount Paid</Table.ColHeader>
              <Table.ColHeader alignContent="center">Payment</Table.ColHeader>
              <Table.ColHeader>Amount Paid</Table.ColHeader>
              <Table.ColHeader alignContent="center">
                Amount Pending
              </Table.ColHeader>
              <Table.ColHeader alignContent="center">
                <Button size="sm" color="green" onClick={this.toggleUpload}>
                  Upload Excel
                </Button>
              </Table.ColHeader>
            </Table.Row>
          </Table.Header>
          {result}
        </Table>
      </Card>
    );
  }

  // Render function
  render() {
    return (
      <SiteWrapper>
        <Modal isOpen={this.state.dataUpload} toggle={this.toggleUpload}>
          <ModalHeader toggle={this.toggleUpload}>Update Data</ModalHeader>
          <ModalBody>
            <Form.Group label="File input">
              <Form.FileInput
                name="fileInput"
                feedback={
                  isEmpty(this.state.errors.fileInput)
                    ? ""
                    : this.state.errors.fileInput
                }
                invalid={isEmpty(this.state.errors.fileInput) ? false : true}
                onChange={this.onChange}
              />
            </Form.Group>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              loading={this.state.processing}
              onClick={this.handleUpload}
            >
              Update Excel
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleUpload}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Page.Content>
          <Grid.Row cards deck>
            <Grid.Col width={12}>{this.paymentListView()}</Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

// Map the state to props
// Map the state to props
const mapStateToProps = state => ({
  errors: state.errors,
  auth: state.auth,
  payments: state.payments,
});

export default connect(
  mapStateToProps,
  { getPayments, setPayments }
)(Payments);
