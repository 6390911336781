const config = {
  server: "https://portal.edusolutions.ca/api",
  api: {
    agentSingleAppGet: "/applications/agentapp/single",
    agentAppPost: "/applications/agentapp",
    agentApps: "/applications/agentapps",
    agentAppComment: "/applications/agentapp/comment",
    agentAppDocumentPost: "/applications/agentapp/document", // POST and GET
    agentAppStatus: "/applications/agentapp/status",
    agentRegister: "/users/new-agent", // POST
 
    changeAssignedTo: "/users/change-assigned",
 
    downloadFile: "/applications/download-file",
 
    notificationsAll: "/notifications/all-notifications",
    notificationToggle: "/notifications/toggle-notification",
    notificationClear: "/notifications/clear-notifications",
 
    applicationList: "/applications/all",
    applicationSingle: "/applications/",
    applicationLanguage: "/applications/language",
    applicationProgram: "/applications/programs",
    applicationAcademic: "/applications/academic",
    applicationAgent: "/applications/agent",
    applicationDocuments: "/applications/documents",
    applicationDelete: "/applications/delete",
    applicationStatus: "/applications/status",
    applicationStudentId: "/applications/studentid",
    applicationComments: "/applications/comments/",
    applicationInternalNotes: "/applications/internalNotes",
    autodoc: "/applications/autodoc/",
    userLogin: "/users/login",
    userRegister: "/users/register",
    userEdit: "/users/edit",
    userList: "/users/all",
    userProfile: "/users/current",
    allActive: "/users/v2/all-active",
 
    documentsList: "/documents",
    programsList: "/programs",
    programsTerm: "/programs/term",
    availability: "/programs/availability",
    programEdit: "/programs/edit",
    programStatus: "/programs/status",
 
    paymentSet: "/payments",
    paymentList: "/payments",
    paymentExist: "/payments/application-payment",
    noPayment: "/payments/nopayment_complete",
 
    razorpay: "/payments/razorpay",
 
    announcementSet: "/announcement",
    announcementGet: "/announcement",
    settingSet: "/settings",
    settingGet: "/settings",
    termsGet: "/terms/all",
    termsGetActive: "/terms/all/active",
    termsSet: "/terms",
    termsDelete: "/terms/delete",
    termsEdit: "/terms/edit",
    campus: "/campus",
    campusList: "/campus/all",
    campusEdit: "/campus/edit",
    campusDelete: "/campus/delete",
    college: "/colleges/",
    collegeList: "/colleges/all",
    collegeEdit: "/colleges/edit",
    collegeDelete: "/colleges/delete",
    programDelete: "/programs/delete",
 
    campusByCollege: "/campus/byCollege",
    programByCampus: "/programs/byCampus",

    servicesGet: "/services/all",
 
    permissionsGet: "/permissions/all",
    permissionAdd: "/permissions",
    permissionEdit: "/permissions",
    permissionDelete: "/permissions",
 
    usersGet: "/users/all/v2",
    usersEdit: "/users/edit/v2",
    usersAdd: "/users/add",
    usersSwitch: "/users/switch",
    usersProfileEdit: "/users/profile/edit",
  },
};
export default config;

