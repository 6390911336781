// @flow

import React from "react";
import SiteWrapper from "../SiteWrapper.react";
import { documentsList } from "../actions/documentActions";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import isEmpty from "../utils/is-empty";
import { handleDocumentDelete } from "../utils/contentView";
import { handleApplicationSubmissions } from "../actions/applicationActions";
import Swal from "sweetalert2";

import { Page, Grid, Card, Table, Button, Icon, Form } from "tabler-react";

import { isNull } from "util";
import config from "../config/config.js";

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentUploadModal: false,
      formData: {},
      file: {},
      uploading: false,
      errors: {},
    };

    this.toggleUpload = this.toggleUpload.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  // Set the errors state
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  // Hanlde the input changes
  onChange(e) {
    if (e.target.name == "fileInput") {
      this.setState({ file: e.target.files[0] });
    } else {
      this.setState({ documentType: e.target.value });
    }
  }

  componentWillMount() {
    // Get the list of the programs
    this.props.documentsList();
  }

  toggleUpload() {
    this.setState({
      documentUploadModal: !this.state.documentUploadModal,
    });
  }

  // Handle the form submission
  handleFormSubmission = async e => {
    e.preventDefault();

    // Set the application data
    const formData = new FormData();
    if (isEmpty(this.state.documentType)) this.setState({ documentType: "" });
    formData.append("documentType", this.state.documentType);
    formData.append("file", this.state.file);
    formData.append("application", 6);

    //Reset the errors
    this.setState({ errors: {} });

    // Send the application to action
    this.setState({ uploading: true });
    await this.props.handleApplicationSubmissions(
      formData,
      "",
      `${config.server}${config.api.applicationDocuments}`,
      4,
      this.props.history
    );
    this.setState({ uploading: false });
    // Reload the Page
    if (isEmpty(this.props.errors)) {
      window.location.reload();
    }
  };

  // Close all the moddals
  closeModals() {
    this.setState({
      documentUploadModal: false,
    });
  }

  // Handle the document dekete
  handleDelete(code) {
    handleDocumentDelete(code);
  }

  // Function to list the programs
  listDocuments(documentsList) {
    // finalList array
    const finalList = [];

    Object.keys(documentsList).forEach(key => {
      const currentDocuments = documentsList[`${key}`];
      const code = currentDocuments["code"];
      const name = currentDocuments["name"];
      const extension = currentDocuments["extension"];
      let documentType = currentDocuments["documentType"];

      // Set the documentType
      if (documentType == 12) {
        documentType = "Wire Transfer form";
      } else if (documentType == 13) {
        documentType = "Refund form";
      } else {
        documentType = "Student Proposal form";
      }

      // Get the date
      let dateObject = new Date(Date.parse(currentDocuments["createdAt"]));
      const date = dateObject.toDateString();

      finalList.push(
        <Table.Row>
          <Table.Col className="w-1">
            <img
              src={`/assets/icons/${extension}.png`}
              className="documentIcon"
            />
          </Table.Col>
          <Table.Col>{documentType}</Table.Col>
          <Table.Col>{name}</Table.Col>
          <Table.Col className="text-nowrap">{date}</Table.Col>
          <Table.Col className="text-nowrap">
            <a
              href={`${config.server}storage/documents/${code}.${extension}`}
              target="_blank"
            >
              <Button
                // onClick={() => {
                //   this.togglTerms;
                // }}
                className="btn btn-primary btn-sm pill"
              >
                {this.props.auth.user.permission == 0 ? (
                  <Icon name="download" color="pink" />
                ) : (
                  "Download"
                )}
              </Button>
            </a>
            {this.props.auth.user.permission == 0 ? (
              <Button
                onClick={() => {
                  this.handleDelete(code);
                }}
                className="btn btn-sm combineButtons pill"
                color="pink"
              >
                <Icon name="trash" color="pink" />
              </Button>
            ) : (
              ""
            )}
          </Table.Col>
        </Table.Row>
      );
    });
    //Return the final list
    return finalList;
  }

  //Empty view
  emptyView() {
    const messsage =
      this.props.auth.user.permission != 0 ? (
        <span>
          We don't have any documents to share <br /> Please come back later!
        </span>
      ) : (
        <div>
          <span>
            You don't have any documents
            <br /> Please click on the button below to upload a file.
          </span>
          <button className="btn btn-primary" onClick={this.toggleUpload}>
            Upload Document
          </button>
        </div>
      );
    return (
      <div className="notfound">
        <img src="/assets/icons/documents.png" />
        {messsage}
      </div>
    );
  }

  // Card view
  cardView(documents) {
    return (
      <Card>
        <Card.Header>
          <Card.Title>Application Information</Card.Title>
          <Card.Options>
            {this.props.auth.user.permission == 0 ? (
              <Button
                onClick={this.toggleUpload}
                color="indigo"
                icon="upload"
                size="md"
                className="ml-2"
              >
                Upload
              </Button>
            ) : (
              ""
            )}
          </Card.Options>
        </Card.Header>

        <Table
          cards={true}
          striped={true}
          responsive={true}
          className="table-vcenter"
        >
          <Table.Header>
            <Table.Row>
              <Table.ColHeader colSpan={2}>Document Type</Table.ColHeader>
              <Table.ColHeader>Name</Table.ColHeader>
              <Table.ColHeader>Uploaded On</Table.ColHeader>
              <Table.ColHeader />
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.listDocuments(documents)}</Table.Body>
        </Table>
      </Card>
    );
  }

  render() {
    let documents = this.props.documents.list;

    return (
      <SiteWrapper>
        <Modal
          isOpen={this.state.documentUploadModal}
          toggle={this.toggleUpload}
        >
          <ModalHeader toggle={this.toggleUpload}>Add new program</ModalHeader>
          <ModalBody>
            <Form.Group label="Document type">
              <Form.Select
                name="documentType"
                value={this.state.documentType}
                feedback={
                  isEmpty(this.state.errors.documentType)
                    ? ""
                    : this.state.errors.documentType
                }
                invalid={isEmpty(this.state.errors.documentType) ? false : true}
                onChange={this.onChange}
              >
                <option value="0">Select a document type</option>
                <option value="12">Wire transfer form</option>
                <option value="13">Refund form</option>
                <option value="14">Others forms and docs</option>
              </Form.Select>
            </Form.Group>

            <Form.Group label="File input">
              <Form.FileInput
                name="fileInput"
                feedback={
                  isEmpty(this.state.errors.fileInput)
                    ? ""
                    : this.state.errors.fileInput
                }
                invalid={isEmpty(this.state.errors.fileInput) ? false : true}
                onChange={this.onChange}
              />
            </Form.Group>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              loading={this.state.uploading}
              onClick={this.handleFormSubmission}
            >
              Upload Document
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleUpload}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Page.Content>
          <Grid.Row>
            <Grid.Col width={12}>
              {isEmpty(documents) ? this.emptyView() : this.cardView(documents)}
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

// Map the state to props
const mapStateToProps = state => ({
  documents: state.documents,
  errors: state.errors,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { documentsList, handleApplicationSubmissions }
)(Documents);
