import {
  SET_CURRENT_USER,
  GET_ERRORS,
  REGISTER_NEW_USER,
  USER_LIST,
  EDIT_USER,
  DELETE_USER,
  SET_PROFILE,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import axios from "axios";
import jwt_decode from "jwt-decode";
import config from "../config/config.js";

// Login - Get user token
export const userLogin = userData => dispatch => {
  axios
    .post(`${config.server}${config.api.userLogin}`, userData)
    .then(res => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      })
    );
};

export const userLogin2 = token => dispatch => {
  // Set token to ls
  localStorage.setItem("jwtToken", token);
  // Set token to Auth header
  setAuthToken(token);
  // Decode token to get user data
  const decoded = jwt_decode(token);
  // Set current user
  dispatch(setCurrentUser(decoded));
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    playload: decoded,
  };
};

// Get the list of all users/agents
export const userList = () => async dispatch => {
  // Send a axios request to backend server
  await axios
    .get(`${config.server}${config.api.userList}`)
    .then(res => {
      dispatch({
        type: USER_LIST,
        playload: res.data,
      });
      dispatch({
        type: GET_ERRORS,
        playload: {},
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      })
    );
};

// Action to get the current user profile
export const userProfile = () => async dispatch => {
  // Send a axios request to backend server
  await axios
    .get(`${config.server}${config.api.userProfile}`)
    .then(res => {
      dispatch({
        type: SET_PROFILE,
        playload: res.data,
      });
      dispatch({
        type: GET_ERRORS,
        playload: {},
      });
    })
    .catch(err => {
      return dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      });
    });
};

// Register a new user
export const registerUser = (userData, editMode) => async dispatch => {
  var URL = `${config.server}${config.api.userRegister}`;
  var type = REGISTER_NEW_USER;

  // Set the url based on EditMode
  if (editMode) {
    URL = `${config.server}${config.api.userEdit}`;

    if (editMode == "DELETE") {
      type = DELETE_USER;
    } else if (editMode == "SELF") {
      type = SET_PROFILE;
    } else {
      type = EDIT_USER;
    }
  }

  // Send a axios request to backend server
  await axios
    .post(URL, userData)
    .then(res => {
      dispatch({
        type: type,
        playload: res.data,
      });
      dispatch({
        type: GET_ERRORS,
        playload: {},
      });
    })
    .catch(err => {
      return dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      });
    });
};

// Log user log
export const logoutUser = () => dispatch => {
  // remove token from localstorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future request
  setAuthToken(false);
  // Set the current user to {} which will also set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
