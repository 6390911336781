import { useQuery } from "react-query";
import queryString from "query-string";
import axios from "axios";
import config from "../../config/config";

/**
 * @typedef {Object} Application
 * @property {string} id
 * @property {string} passport
 * @property {string} studentId
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} nationality
 * @property {string} email
 * @property {string} createdAt
 * @property {Object} User
 * @property {ProgramsAvailable} User
 *
 * @param {number} page
 * @param {*} filters
 * @returns {Promise<Application[]>}
 */
const fetchApplications = async (page = 0, filters) => {
  const stringified = queryString.stringify(filters);

  const res = await axios.get(
    `${config.server}/applications/v2/all?page=${page}&${stringified}`
  );

  return res.data;
};

export const useGetApplications = (page, filters) =>
  useQuery(["applications"], () => fetchApplications(page, filters), {
    keepPreviousData: true,
    refetchOnMount: false,
  });

export const useGetFilterOptions = () =>
  useQuery(["filterOptions"], async () => {
    const res = await axios.get(`${config.server}/applications/filterOptions`);
    return res.data;
  });

export const useGetAllTerms = () =>
  useQuery(["terms"], async () => {
    const res = await axios.get(`${config.server}${config.api.termsGetActive}`);
    return res.data;
  });
