import React, { useEffect, useReducer, useState } from "react";
import SiteWrapper from "../../SiteWrapper.react";
import { Box, Pagination, ScrollArea, Table } from "@mantine/core";
import FilterBar from "./FilterBar";
import ApplicationsTable from "./ApplicationsTable";
import { useGetApplications } from "./api";

export const tableHeaders = [
  "Assigned ID",
  "Name",
  "Nationality",
  "Agent",
  "Status",
  "Term",
  "Institution",
  "Course",
  "Created",
  "",
];

const Applications2 = () => {
  const [page, setPage] = useState(1);

  const initialValues = {
    searchType: "",
    searchStatus: "",
    searchQuery: "",
    searchTerm: "",
    searchAgent: "",
    searchDate: "",
    diversity: 0,
  };
  const [filterValues, setFilterValues] = useReducer(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    initialValues,
    undefined
  );

  const { isLoading, data: appData, refetch } = useGetApplications(
    page,
    filterValues
  );

  useEffect(
    () => {
      refetch();
    },
    [filterValues]
  );

  const Wrapper = () => {
    return (
      <SiteWrapper>
        <FilterBar
          filterValues={filterValues}
          setFilterValues={setFilterValues}
        />
        <ScrollArea bg="white">
          <Table verticalSpacing="xs" bg="white" withBorder striped m={8}>
            <thead>
              <tr>
                {tableHeaders.map(e => (
                  <th key={e}>{e}</th>
                ))}
              </tr>
            </thead>
            <tbody />
            Loading
          </Table>
        </ScrollArea>
      </SiteWrapper>
    );
  };

  if (isLoading) {
    return <Wrapper />;
  }

  if (!appData) {
    return <Wrapper />;
  }

  return (
    <SiteWrapper>
      <Box mx={16}>
        <FilterBar
          filterValues={filterValues}
          setFilterValues={setFilterValues}
        />
        <ApplicationsTable appData={appData[0]} isLoading={isLoading} />
        <Pagination
          total={appData[1] / 10}
          siblings={2}
          value={page}
          onChange={setPage}
        />
      </Box>
    </SiteWrapper>
  );
};

export default Applications2;
