import  React  from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form } from "tabler-react";
import isEmpty from "../../utils/is-empty";

class IntakeModal extends React.Component {

	// Render Function
	render() {



		// Return the modal
		return (
			<Modal isOpen={ this.props.intakeModal } toggle={ this.props.intakeModalToggle } >
				<ModalHeader>Add new Intake</ModalHeader>
				<ModalBody>
					<Form.Group label="Intake Name" required isRequired>
						<Form.Input
							name="name"
							value={this.props.intakeData.name}
							invalid={isEmpty(this.props.errors.name) ? false : true}
							feedback={isEmpty(this.props.errors.term)? "": this.props.errors.term }
  							onChange={this.props.onChange}
							placeholder="F2019"
						/>
					</Form.Group>

					<Form.Group label="Year" isRequired>
						<Form.Select
							name="year"
							value={this.props.intakeData.year}
							onChange={this.props.onChange}
							invalid={isEmpty(this.props.errors.year) ? false : true }
							feedback={isEmpty(this.props.errors.year) ? "" : this.props.errors.year}
						>
							<option value={null}>Select Year</option>
							<option value="2019">2019</option>
							<option value="2020">2020</option>
							<option value="2021">2021</option>
							<option value="2022">2022</option>
							<option value="2023">2023</option>
							<option value="2024">2024</option>
							<option value="2025">2025</option>
							<option value="2026">2026</option>
							<option value="2027">2027</option>
							<option value="2028">2028</option>
							<option value="2029">2029</option>
							<option value="2030">2030</option>
						</Form.Select>
					</Form.Group>

					<Form.Group label="term" isRequired>
						<Form.Input
							name="term"
							placeholder="Term Number: 1, 2, 3"
							value={this.props.intakeData.term}
							invalid={isEmpty(this.props.errors.term) ? false : true}
							feedback={isEmpty(this.props.errors.term) ? "": this.props.errors.term }
							onChange={this.props.onChange}
						/>
					</Form.Group>

					<Form.Group label="Intake Description">
						<Form.Textarea
							name="desc"
							value={this.props.intakeData.desc}
							invalid={isEmpty(this.props.errors.description) ? false : true}
                            feedback={isEmpty(this.props.errors.description)? "": this.props.errors.description }
							onChange={this.props.onChange}
							rows="5"
						/>
					</Form.Group>

				</ModalBody>
				<ModalFooter>
					<Button size="md" color="primary" onClick={this.props.addTerm}>Add Intake</Button>
					<Button size="md" color="secondary" onClick={this.props.closeIntake}>Cancel</Button>
				</ModalFooter>
			</Modal>
		)

	}

}

export default IntakeModal;

