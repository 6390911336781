import { useMutation, useQuery } from "react-query";
import queryClient from "../lib/queryClient";
import axios from "axios";
import config from "../config/config";

/**
 *
 * @param {number} currentId
 * @returns {Promise<Object> | Error}
 */
const fetchSingleApp = async currentId => {
  const res = await axios.get(
    `${config.server}${config.api.agentSingleAppGet}/${currentId}`
  );

  return res.data;
};

/**
 *
 * @param {Object} payload
 * @returns {Promise<string> | Error}
 */
const submitAgentApp = async payload => {
  const res = await axios.post(
    `${config.server}${config.api.agentAppPost}`,
    payload
  );

  return res.data;
};

/**
 * @param {Object} payload
 * @param {number} payload.id
 * @param {number} payload.status
 * @returns {Promise<string> | Error} payload.status
 */
const updateAgentAppStatus = async payload => {
  const res = await axios.post(
    `${config.server}${config.api.agentAppStatus}`,
    payload
  );

  return res.data;
};

/**
 * @param {Object} payload
 * @param {number} payload.id
 * @param {string} payload.message
 * @returns {Promise<string> | Error}
 */
const addAgentAppComment = payload => {
  return axios.post(`${config.server}${config.api.agentAppComment}`, payload);
};

/**
 *
 * @param {FormData} payload
 * @param {File} payload.file
 * @returns {Promise<string> | Error}
 */
const uploadAgentAppDoc = payload => {
  return axios.post(
    `${config.server}${config.api.agentAppDocumentPost}`,
    payload
  );
};

/**
 *
 * @param {string} document
 * @returns {Promise<Object>} {success: boolean, message: <string | Error>}
 */
const deleteAgentAppDoc = document => {
  const applicationData = { document };
  console.log(applicationData);
  return axios.delete(`${config.server}${config.api.applicationDocuments}`, {
    data: applicationData,
  });
};

/**
 *
 * @param {number} currentId
 * @returns {Promise<Object[]> | Error}
 */
const fetchAgentAppDocs = async currentId => {
  const res = await axios.get(
    `${config.server}${config.api.agentAppDocumentPost}/${currentId}`
  );

  return res.data;
};

export const useSingleAgentApp = currentId => {
  return useQuery(["agentapp", currentId], () => fetchSingleApp(currentId), {
    initialData: () =>
      queryClient.getQueryData(["agentapp", currentId]) &&
      queryClient
        .getQueryData(["agentapp", currentId])
        .find(d => d.id === currentId),
  });
};

export const useAgentAppSubmit = () => {
  return useMutation(submitAgentApp, {
    onSuccess: () => {
      queryClient.invalidateQueries("agentapp");
    },
  });
};

export const useAgentAppStatus = () => {
  return useMutation(updateAgentAppStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries("agentapp");
    },
  });
};

export const useAgentAppComment = () => {
  return useMutation(payload => addAgentAppComment(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries("agentapp");
    },
  });
};

export const useAgentAppDocUpload = () => {
  return useMutation(uploadAgentAppDoc, {
    onSuccess: () => {
      queryClient.invalidateQueries("agentappDocs");
    },
  });
};

export const useAgentAppDocDelete = () => {
  return useMutation(deleteAgentAppDoc, {
    onSuccess: () => {
      queryClient.invalidateQueries("agentappDocs");
    },
  });
};

export const useAgentAppDocs = currentId => {
  return useQuery(["agentappDocs", currentId], () =>
    fetchAgentAppDocs(currentId)
  );
};
