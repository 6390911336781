import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Form, Button } from "tabler-react";
import isEmpty from "../../utils/is-empty";

import countriesList from "../../utils/countriesList.js";

class AgentAdd extends React.Component {
  constructor(props) {
    super(props);

    this.addInformation = this.addInformation.bind(this);
  }

  // Addational information form
  addInformation(e) {
    if (this.props.globalForm != true) {
      return (
        <div>
          <Form.Group label="Select User type">
            <Form.Select
              name="permission"
              value={this.props.formData.permission}
              // value={this.props.permission}
              feedback={
                isEmpty(this.props.errors.permission)
                  ? ""
                  : this.props.errors.permissions
              }
              invalid={isEmpty(this.props.errors.permission) ? false : true}
              onChange={this.props.onChange}
            >
              <option value={null}>Select a user type</option>
              <option value="1">Agent</option>
              <option value="2">Admission Assistant</option>
              <option value="3">Admission Officer</option>
              <option value="4">Finance Officer</option>
              <option value="5">Student</option>
            </Form.Select>
          </Form.Group>

          <Form.Group label="Company name" className={this.props.agentSelected}>
            <Form.Input
              name="company"
              value={this.props.formData.company}
              feedback={
                isEmpty(this.props.errors.company)
                  ? ""
                  : this.props.errors.company
              }
              invalid={isEmpty(this.props.errors.company) ? false : true}
              onChange={this.props.onChange}
              placeholder="Ocean's Company"
            />
          </Form.Group>

          <Form.Group
            label="Agent's Country"
            className={this.props.agentSelected}
          >
            <Form.Select
              value={this.props.formData.country}
              onChange={this.props.onChange}
              name="country"
              feedback={
                isEmpty(this.props.errors.country)
                  ? ""
                  : this.props.errors.country
              }
              invalid={isEmpty(this.props.errors.country) ? false : true}
            >
              {countriesList(this.props.formData.country)}
            </Form.Select>
          </Form.Group>

          <Form.Group
            label="Website URL (Optional)"
            className={this.props.agentSelected}
          >
            <Form.Input
              name="website"
              value={this.props.formData.website}
              feedback={
                isEmpty(this.props.errors.website)
                  ? ""
                  : this.props.errors.website
              }
              invalid={isEmpty(this.props.errors.website) ? false : true}
              onChange={this.props.onChange}
              placeholder="https://www.oceanscompany.com"
            />
          </Form.Group>
        </div>
      );
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.agentAddModal} toggle={this.props.toggleAdd}>
        <ModalHeader toggle={this.props.toggleAdd}>Add new User</ModalHeader>
        <ModalBody>
          <Form.Group
            label={this.props.editMode ? "Change Agent Name" : "Agent Name"}
          >
            <Form.Input
              name="name"
              value={this.props.formData.name}
              feedback={
                isEmpty(this.props.errors.name) ? "" : this.props.errors.name
              }
              invalid={isEmpty(this.props.errors.name) ? false : true}
              onChange={this.props.onChange}
              placeholder="Agent's Full name"
            />
          </Form.Group>

          <Form.Group
            label={
              this.props.editMode ? "Change Email Address" : "Email Address"
            }
          >
            <Form.Input
              name="email"
              value={this.props.formData.email}
              feedback={
                isEmpty(this.props.errors.email) ? "" : this.props.errors.email
              }
              invalid={isEmpty(this.props.errors.email) ? false : true}
              onChange={this.props.onChange}
              placeholder="Agent's Email Address"
            />
          </Form.Group>

          <Form.Group label={this.props.editMode ? "New Password" : "Password"}>
            <Form.Input
              name="password"
              value={this.props.formData.password}
              feedback={
                isEmpty(this.props.errors.password)
                  ? ""
                  : this.props.errors.password
              }
              invalid={isEmpty(this.props.errors.password) ? false : true}
              onChange={this.props.onChange}
              placeholder="password"
              type="password"
            />
          </Form.Group>

          <Form.Group
            label={
              this.props.editMode ? "Confirm new password" : "Confirm Password"
            }
          >
            <Form.Input
              name="password2"
              value={this.props.formData.password2}
              feedback={
                isEmpty(this.props.errors.password2)
                  ? ""
                  : this.props.errors.password2
              }
              invalid={isEmpty(this.props.errors.password2) ? false : true}
              onChange={this.props.onChange}
              placeholder="password"
              type="password"
            />
          </Form.Group>
          {/* <Form.Group label="Assigned To">
            <Form.Select
              name="assignedTo"
              value={this.props.formData.assignedTo}
              onChange={this.props.onChange}
            >
              {this.props.staffList.map(e => (
                <option value={this.props.staffList.id}>
                  {this.props.staffList.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group> */}
          {this.addInformation()}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            loading={this.props.uploading}
            onClick={this.props.handleFormSubmission}
          >
            {this.props.editMode ? "Update User Profile" : "Add User"}
          </Button>{" "}
          <Button color="secondary" onClick={this.props.toggleAdd}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AgentAdd;
