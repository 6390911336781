// @flow

import * as React from "react";
import { Alert } from "react-bs-notifier";

import { Card, Container, Grid, Form, Button } from "tabler-react";

import SiteWrapper from "../../SiteWrapper.react";
import "../../App.css";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { handleApplicationSubmissions } from "../../actions/applicationActions";
import isEmpty from "../../utils/is-empty";
import config from "../../config/config.js";
import { capitalFirstLetter } from "../../utils/formatUserData";

class Step2 extends React.Component {
  constructor(props) {
    super(props);

    // Set the initial State
    this.state = {
      formData: {},
      errors: {},
    };

    // Bind the functions with {this}
    this.onChange = this.onChange.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
  }

  // Check if the form is in submission mode
  componentWillMount() {
    // Redirect if form is not in submission mode
    if (
      !this.props.application.submissionMode &&
      !this.props.application.editMode
    ) {
      this.props.history.push("/application/submit");
    }

    // Check if we are in edit mode and got currentApplication data
    if (
      !isEmpty(this.props.application.currentApplication.ApplicationLanguage)
    ) {
      // Set the form state to currentApplication state
      this.setState({
        formData: this.props.application.currentApplication.ApplicationLanguage,
      });
    }
  }

  // Hanlde the input changes
  onChange(e) {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  }

  // Set the errors state
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  // Handle the form submission
  handleFormSubmission = e => {
    e.preventDefault();

    // Set the application data
    let { formData } = this.state;

    // Format the application data
    formData = {
      ...formData,
      mother_tongue: capitalFirstLetter(formData.mother_tongue),
    };

    // Attach the application id with formData
    formData.application = this.props.application.currentApplicationID;
    //Reset the errors
    this.setState({ errors: {} });
    // Send the application to action
    this.props.handleApplicationSubmissions(
      formData,
      "/application/Academic",
      `${config.server}${config.api.applicationLanguage}`,
      2,
      this.props.history
    );
  };

  // Handle the previous button
  handlePrevious = e => {
    e.preventDefault();
    if (true) {
      // Redirect user to next link
      this.props.history.push("/application/submit");
    }
  };

  render() {
    return (
      <SiteWrapper>
        <div className="my-3 my-md-5">
          <Container>
            <Grid.Row>
              <Grid.Col lg={8} className="applicationForm">
                <Card
                  title="Submit Application - Languages Proficiency"
                  RootComponent={Form}
                  footer={
                    <div className="d-flex">
                      <Button
                        onClick={this.handlePrevious}
                        color="secondary"
                        link
                      >
                        Previous Step
                      </Button>
                      <Button
                        onClick={this.handleFormSubmission}
                        color="primary"
                        className="ml-auto"
                      >
                        Next Step
                      </Button>
                    </div>
                  }
                >
                  <Card.Body>
                    {this.state.errors.bands &&
                      this.state.errors.bands.map(value => {
                        return (
                          <Alert type="danger" isDismissible>
                            {value}
                          </Alert>
                        );
                      })}
                    <Form.Group label="Mother Tongue" isRequired>
                      <Form.Input
                        value={this.state.formData.mother_tongue}
                        name="mother_tongue"
                        feedback={
                          isEmpty(this.state.errors.mother_tongue)
                            ? ""
                            : this.state.errors.mother_tongue
                        }
                        invalid={
                          isEmpty(this.state.errors.mother_tongue)
                            ? false
                            : true
                        }
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group label="English Language test" isRequired>
                      <Form.Select
                        value={this.state.formData.testTaken}
                        name="testTaken"
                        feedback={
                          isEmpty(this.state.errors.testTaken)
                            ? ""
                            : this.state.errors.testTaken
                        }
                        invalid={
                          isEmpty(this.state.errors.testTaken) ? false : true
                        }
                        onChange={this.onChange}
                      >
                        <option value="">Select the test</option>
                        <option
                          selected={
                            this.state.formData.testTaken == 0 ? "0" : ""
                          }
                          value="0"
                        >
                          IELTS
                        </option>
                        <option
                          selected={
                            this.state.formData.testTaken == 1 ? "1" : ""
                          }
                          value="1"
                        >
                          TOFEL
                        </option>
                        <option
                          selected={
                            this.state.formData.testTaken == 2 ? "2" : ""
                          }
                          value="2"
                        >
                          PTE
                        </option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group label="Overall Band Score">
                      <Form.Input
                        value={this.state.formData.totalScore}
                        name="totalScore"
                        feedback={
                          isEmpty(this.state.errors.totalScore)
                            ? ""
                            : this.state.errors.totalScore
                        }
                        invalid={
                          isEmpty(this.state.errors.totalScore) ? false : true
                        }
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group label="Listening Score" isRequired>
                      <Form.Input
                        value={this.state.formData.listening}
                        name="listening"
                        feedback={
                          isEmpty(this.state.errors.listening)
                            ? ""
                            : this.state.errors.listening
                        }
                        invalid={
                          isEmpty(this.state.errors.listening) ? false : true
                        }
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group label="Reading Score" isRequired>
                      <Form.Input
                        value={this.state.formData.reading}
                        name="reading"
                        feedback={
                          isEmpty(this.state.errors.reading)
                            ? ""
                            : this.state.errors.reading
                        }
                        invalid={
                          isEmpty(this.state.errors.reading) ? false : true
                        }
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group label="Writing Score" isRequired>
                      <Form.Input
                        value={this.state.formData.writing}
                        name="writing"
                        feedback={
                          isEmpty(this.state.errors.writing)
                            ? ""
                            : this.state.errors.writing
                        }
                        invalid={
                          isEmpty(this.state.errors.writing) ? false : true
                        }
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group label="Speaking Score" isRequired>
                      <Form.Input
                        value={this.state.formData.speaking}
                        name="speaking"
                        feedback={
                          isEmpty(this.state.errors.speaking)
                            ? ""
                            : this.state.errors.speaking
                        }
                        invalid={
                          isEmpty(this.state.errors.speaking) ? false : true
                        }
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group label="Test Date (YYYY/MM/DD)" isRequired>
                      <Form.MaskedInput
                        value={this.state.formData.takenOn}
                        name="takenOn"
                        feedback={
                          isEmpty(this.state.errors.takenOn)
                            ? ""
                            : this.state.errors.takenOn
                        }
                        invalid={
                          isEmpty(this.state.errors.takenOn) ? false : true
                        }
                        onChange={this.onChange}
                        placeholder="0000-00-00"
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Container>
        </div>
      </SiteWrapper>
    );
  }
}

// Set the component's properties
const mapStateToProps = state => ({
  application: state.application,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  { handleApplicationSubmissions }
)(Step2);
