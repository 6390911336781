import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Container, Table } from "tabler-react";
import config from "../../config/config";
import { renderFor } from "../../utils/renderComp";
import { logToString } from "../../utils/notificationStrings";
import axios from "axios";
import { useQuery } from "react-query";
import { useToggleRead } from "../../hooks/useNotifications";

const Notifications = ({ history, user }) => {
  if (renderFor([0, 2], true, user)) {
    history.push("/applications");
  }

  const { mutate: mutateRead } = useToggleRead();
  const [page, setPage] = useState(1);

  const fetchNotifications = async page => {
    const res = await axios.get(
      `${config.server}${config.api.notificationsAll}?page=${page}`
    );

    return res.data;
  };

  const { isLoading, data } = useQuery(
    ["notifications", page],
    () => fetchNotifications(page),
    { keepPreviousData: true }
  );

  // toggle the
  const toggleRead = id => {
    mutateRead({ id });
  };

  const unreadColor = {
    backgroundColor: "#D8E9FF",
  };

  if (isLoading) {
    return (
      <Container className="defaultMargin">
        <div>Loading</div>
      </Container>
    );
  }

  if (data.row && data.row.length === 0 && !isLoading) {
    return (
      <Container className="defaultMargin">
        <div>No data</div>
      </Container>
    );
  }

  return (
    <Container>
      <Table>
        <Table.Header>
          <Table.ColHeader>Application</Table.ColHeader>
          <Table.ColHeader>User</Table.ColHeader>
          <Table.ColHeader>Message</Table.ColHeader>
          <Table.ColHeader>CreatedAt</Table.ColHeader>
          <Table.ColHeader></Table.ColHeader>
        </Table.Header>

        <Table.Body>
          {data.rows &&
            data.rows.map(notif => {
              let createdAt = new Date(notif.createdAt).toDateString();
              return (
                <Table.Row key={notif.id} style={notif.read ? {} : unreadColor}>
                  <Table.Col>
                    <a href={`/application/view/${notif.application}`}>
                      <span onClick={() => toggleRead(notif.id)}>
                        {notif.application}
                      </span>
                    </a>
                  </Table.Col>
                  <Table.Col>{notif.User.name}</Table.Col>
                  <Table.Col>
                    {logToString(notif.code, notif.User, notif.message)}
                  </Table.Col>
                  <Table.Col>{createdAt}</Table.Col>
                  <Table.Col>
                    <Button
                      color={notif.read ? "default" : "primary"}
                      onClick={() => toggleRead(notif.id)}
                    >
                      {notif.read ? "unread" : "read"}
                    </Button>
                  </Table.Col>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </Container>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(Notifications);
