import  { MAKE_ANNOUNCEMENT, GET_ANNOUNCEMENT, GET_ERRORS } from "./types";
import axios from "axios";
import config from "../config/config.js"

// Send the announcement message to the backend server
export const announcementSet =  (message) => async dispatch => {
  // Send the get request to backend server
  await axios
    .post(`${config.server}${config.api.announcementSet}`, {message: message})
    .then(res => {
      // Return the data to the reducer
      dispatch({
        type: GET_ERRORS,
        playload: {},
      });
      dispatch({
        type: MAKE_ANNOUNCEMENT,
        playload: res.data,
      });
     
    })
    .catch(err => {
      return dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      })
    });
};

// Send the announcement message to the backend server
export const announcementGet =  () => async dispatch => {
  // Send the get request to backend server
  await axios
    .get(`${config.server}${config.api.announcementGet}`)
    .then(res => {
      // Return the data to the reducer
      dispatch({
        type: GET_ANNOUNCEMENT,
        playload: res.data,
      });
    })
    .catch(err => {
      return dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      })
    });
};