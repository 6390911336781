import React from "react";
import SiteWrapper from "../SiteWrapper.react";
import { Link } from "react-router-dom";
import {
  Page,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Button,
  Form,
} from "tabler-react";

// Get the redux data
import { connect } from "react-redux";
import {
  applicationsList,
  setCurrentApplication,
  getCurrentApplication,
} from "../actions/applicationActions";
import { PropTypes } from "prop-types";
import { applicationStatus } from "../utils/applicationStatus";
import isEmpty from "../utils/is-empty.js";
import { termsList, agentsList, mapProgramsList } from "../utils/contentView";
import AgentInfo from "../components/modals/AgentInfo";
import Pagination from "rc-pagination";
import { userList } from "../actions/authActions";
import { programsList } from "../actions/programsActions.js";
import axios from "axios";
import config from "../config/config";
import { CSVLink } from "react-csv";
import queryString from "query-string";

const headers = [
  { label: "Assigned Id", key: "id" },
  { label: "Passport", key: "passport" },
  { label: "Student Id", key: "studentId" },
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Nationality", key: "nationality" },
  { label: "Province", key: "province" },
  { label: "City", key: "city" },
  { label: "Agent", key: "User.name" },
  { label: "Email", key: "email" },
  { label: "Term", key: "ProgramsAvailable.Term.name" },
  { label: "Created", key: "createdAt" },
  { label: "Status", key: "status" },
  { label: "Course", key: "ProgramsAvailable.Program.code" },
];

class Applications extends React.Component {
  // Constructor
  constructor(props) {
    super(props);
    // Set the initial search state
    this.state = {
      applications: props.application.applications,
      searchOptions: {
        searchType: "nameSearch",
        searchStatus: "",
        searchQuery: "",
        searchTerm: "",
        searchAgent: "",
        searchProgram: "",
        searchDate: "",
        diversity: 0,
      },
      page: 1,
      agentInfo: false,
      dataForDownload: [],
      generated: false,
    };
    // Bind this
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.toggle = this.toggle.bind(this);
    this.searchApplications = this.searchApplications.bind(this);
    this.showOption = this.showOption.bind(this);
    this.generateReport = this.generateReport.bind(this);
  }

  async componentWillReceiveProps(props) {
    // Set the initial data
    this.setState({ applications: props.application.applications });
  }

  // On search change, Change the state
  async onChangeSearch(e) {
    // Get the state for the current search
    let value = e.target.value;
    let target = e.target.name;

    if (target === "diversity") {
      value = this.state.searchOptions.diversity === 0 ? 1 : 0;
      // await is necessary for this.searchApplications to get state properly
      await this.setState({
        searchOptions: { ...this.state.searchOptions, [target]: value },
      });
    } else {
      await this.setState({
        searchOptions: { ...this.state.searchOptions, [target]: value },
      });
    }

    this.searchApplications();
  }

  searchApplications() {
    const searchStatus = this.state.searchOptions.searchStatus || ""; // Status of the application
    const searchTerm = this.state.searchOptions.searchTerm || ""; // Status of the application
    const searchType = this.state.searchOptions.searchType || "nameSearch"; // What coloum to search?
    const searchAgent = this.state.searchOptions.searchAgent || "";
    const searchDate = this.state.searchOptions.searchDate || "";
    const searchProgram = this.state.searchOptions.searchProgram || "";
    const diversity = this.state.searchOptions.diversity === 0 ? 0 : 1;
    console.log(searchProgram, "buh");
    let searchQuery =
      encodeURIComponent(escape(this.state.searchOptions.searchQuery)) || ""; // Search keywords
    this.setState({ ...this.state, page: 1 });

    // set the path name and query string
    this.props.history.replace({
      search: `?page=${
        this.state.page
      }&searchStatus=${searchStatus}&searchTerm=${searchTerm}&searchAgent=${searchAgent}&searchDate=${searchDate}&searchProgram=${searchProgram}&${searchType}=${searchQuery}&diversity=${diversity}`,
      pathname: "/applications/",
    });

    const parsed = queryString.parse(this.props.location.search);

    // Filter the variables further
    searchQuery = searchQuery.toLowerCase();

    // Set the result as current view
    this.props.applicationsList(
      this.state.page,
      searchStatus,
      searchTerm,
      searchQuery,
      searchType,
      searchAgent,
      searchDate,
      searchProgram,
      diversity
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page != this.state.page) {
      this.props.applicationsList(
        this.state.page,
        this.state.searchOptions.searchStatus,
        this.state.searchOptions.searchTerm,
        this.state.searchOptions.searchQuery,
        this.state.searchOptions.searchType,
        this.state.searchOptions.searchAgent,
        this.state.searchOptions.searchDate,
        this.state.searchOptions.searchProgram,
        this.state.searchOptions.diversity
      );
    }

    if (prevState.searchOptions !== this.state.searchOptions) {
      this.setState({
        ...this.state,
        generated: false,
      });
    }
  }

  componentWillMount() {
    let defaultStatus = "";
    // Set the search status based on the user
    if (this.props.user.permission == 2) {
      defaultStatus = 26;
    } else if (this.props.user.permission == 3) {
      defaultStatus = 2;
    } else if (this.props.user.permission == 4) {
      defaultStatus = 5;
    }

    // these setStates need to be separated otherwise page is undefined
    // on first render
    this.setState({
      ...this.state,
      page: parseInt(this.props.match.params.page),
    });

    this.setState({
      ...this.state,
      searchOptions: {
        ...this.state.searchOptions,
        searchTerm: this.props.settings.term,
        searchStatus: defaultStatus,
      },
    });
  }

  // Set the compoenent data
  async componentDidMount() {
    await this.props.userList();
    await this.props.programsList();

    const parsed = queryString.parse(this.props.location.search);
    console.log(parsed);

    let searchType;
    let searchQuery;

    const allowed = [
      "searchAgent",
      "searchStatus",
      "searchTerm",
      "page",
      "searchDate",
      "searchProgram",
      "diversity",
    ];

    // get searchTerm and searchQuery
    for (const i in parsed) {
      if (!allowed.includes(i)) {
        searchType = i;
        searchQuery = parsed[i].toLowerCase();
      }
    }

    // if no query, show the first page with default filter settings
    if (isEmpty(parsed)) {
      this.setState({
        ...this.state,
        list: this.props.auth.list,
        programs: this.props.programs.list,
      });

      this.props.applicationsList(
        this.state.page,
        this.state.searchOptions.searchStatus,
        this.state.searchOptions.searchTerm,
        "",
        "",
        "",
        "",
        ""
      );
    } else {
      // set the initial state of filters
      this.setState({
        ...this.state,
        list: this.props.auth.list,
        programs: this.props.programs.list,
        searchOptions: {
          ...this.state.searchOptions,
          searchType: searchType,
          searchStatus: !isEmpty(parsed.searchStatus)
            ? parsed.searchStatus
            : "",
          searchAgent: !isEmpty(parsed.searchAgent) ? parsed.searchAgent : "",
          searchTerm: !isEmpty(parsed.searchTerm) ? parsed.searchTerm : "",
          searchQuery: !isEmpty(searchQuery) ? searchQuery : "",
          searchDate: !isEmpty(parsed.searchDate) ? parsed.searchDate : "",
          searchProgram: !isEmpty(parsed.searchProgram)
            ? parsed.searchProgram
            : "",
          diversity: !isEmpty(parsed.diversity) ? parsed.diversity : "",
        },
        page: parseInt(parsed.page, 10),
      });

      this.props.applicationsList(
        parsed.page,
        parsed.searchStatus,
        parsed.searchTerm,
        searchQuery,
        searchType,
        parsed.searchAgent,
        parsed.searchDate,
        parsed.searchProgram,
        parsed.diversity
      );
    }
  }

  // Function to toggle the agent info popup
  async toggle(e, agentData) {
    e.preventDefault();
    this.setState({
      agentInfo: !this.state.agentInfo,
      agentData: agentData,
    });
  }

  // Function to get the options
  showOption() {
    // Get the user permission
    const permission = this.props.user.permission;
    let options = {};
    let defaultStatus;

    // Set the option dropdown based on user permission
    if (
      permission == 0 ||
      permission == 1 ||
      permission == 2 ||
      permission == 3 ||
      permission == 4
    ) {
      options = {
        0: "Incomplete",
        1: "Pending",
        2: "Under Review",
        3: "Require Changes",
        4: "Declined",
        5: "Approved",
        9: "Expired",
        10: "Payments",
        11: "Visa Approved",
        12: "Refund Requested",
        15: "Visa Requested",
        20: "Deferred",
        21: "Pre-registered",
        25: "Defer Requested",
        26: "Pre Screen",
        27: "System Declined",
        29: "Paid",
        48: "Revoked",
        49: "Refunded",
        40: "Closed",
      };
    } else if (permission == 2) {
      defaultStatus = 26;
      options = {
        3: "Require Changes",
        4: "Declined",
        26: "Pre Screen",
        48: "Revoked",
        49: "Refunded",
        // 27: "System Declined",
      };
    } else if (permission == 3) {
      defaultStatus = 2;
      options = {
        1: "Pending",
        2: "Under Review",
        4: "Declined",
        3: "Require Changes",
        12: "Refund Requested",
        48: "Revoked",
        49: "Refunded",
      };
    }

    return options;
  }

  generateReport() {
    if (this.state.generated === false) {
      let dateObject;
      axios
        .get(
          `${config.server}${config.api.applicationList}/?page=0&searchStatus=${
            this.state.searchOptions.searchStatus
          }&searchTerm=${this.state.searchOptions.searchTerm}&searchAgent=${
            this.state.searchOptions.searchAgent
          }&searchProgram=${
            this.state.searchOptions.searchProgram
          }&searchDate=${this.state.searchOptions.searchDate}&${
            this.state.searchOptions.searchType
          }=${this.state.searchOptions.searchQuery}`
        )
        .then(res => {
          for (let i = 0; i < res.data[0].length; i++) {
            dateObject = new Date(Date.parse(res.data[0][i].createdAt));
            res.data[0][i].createdAt = dateObject.toDateString();
            res.data[0][i].status = applicationStatus(
              res.data[0][i].status
            ).statusText;
          }

          this.setState({
            ...this.state,
            dataForDownload: res.data[0],
            generated: true,
          });
        });
    } else {
      this.setState({
        ...this.state,
        generated: false,
      });
    }
  }

  // Function to return the list of application
  showApplications(listView) {
    // Get the show options
    const options = this.showOption();
    return (
      <Grid.Row>
        <Grid.Col width={12}>
          <Card>
            <Card.Header className="cardInput">
              <Card.Title>All Applications</Card.Title>
              <Card.Options>
                <Form.Group className="cardInput">
                  <Form.Input
                    className="mb-3"
                    icon="search"
                    name="searchQuery"
                    value={this.state.searchOptions.searchQuery}
                    onChange={this.onChangeSearch}
                    placeholder="Search for..."
                    position="append"
                  />
                </Form.Group>

                <Form.Group className="cardInput combineButtons">
                  <Form.Select
                    onChange={this.onChangeSearch}
                    name="searchType"
                    value={this.state.searchOptions.searchType}
                  >
                    <option selected value="nameSearch">
                      Name
                    </option>
                    <option value="passportSearch">Passport</option>
                    <option value="emailSearch">Email</option>
                    <option value="studentIdSearch">Student ID</option>
                  </Form.Select>
                </Form.Group>

                {this.props.auth.user.permission === 1 ? (
                  ""
                ) : (
                  <Form.Group className="cardInput combineButtons">
                    <Form.Select
                      onChange={this.onChangeSearch}
                      name="searchAgent"
                      value={this.state.searchOptions.searchAgent}
                    >
                      <option selected value="">
                        All Agents
                      </option>
                      {agentsList(this.state.list)}
                    </Form.Select>
                  </Form.Group>
                )}

                {this.props.auth.user.permission === 1 ? (
                  ""
                ) : (
                  <Form.Group className="cardInput combineButtons">
                    <Form.Select
                      onChange={this.onChangeSearch}
                      name="searchProgram"
                      value={this.state.searchOptions.searchProgram}
                    >
                      <option selected value="">
                        All Programs
                      </option>
                      {mapProgramsList(this.state.programs)}
                    </Form.Select>
                  </Form.Group>
                )}

                <Form.Group className="cardInput combineButtons">
                  <Form.Select
                    onChange={this.onChangeSearch}
                    name="searchTerm"
                    value={this.state.searchOptions.searchTerm}
                  >
                    <option selected value="">
                      All Term
                    </option>
                    {termsList(this.props.terms)}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="cardInput combineButtons">
                  <Form.Select
                    onChange={this.onChangeSearch}
                    name="searchDate"
                    value={this.state.searchOptions.searchDate}
                  >
                    <option>Date order</option>
                    <option>Ascending</option>
                    <option>Descending</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="cardInput combineButtons">
                  <Form.Select
                    size="sm"
                    onChange={this.onChangeSearch}
                    name="searchStatus"
                    value={this.state.searchOptions.searchStatus}
                  >
                    <option value="">All status</option>
                    {Object.keys(options).map(key => {
                      // Get the selected item
                      if (key == this.state.searchOptions.searchStatus) {
                        return (
                          <option value={key} selected>
                            {options[key]}
                          </option>
                        );
                      }
                      return <option value={key}>{options[key]}</option>;
                    })}
                  </Form.Select>
                </Form.Group>

                {/*{(this.props.auth.user.permission === 0 ||*/}
                {/*  this.props.auth.user.permission === 2) && (*/}
                {/*    <Form.Group className="cardInput combineButtons">*/}
                {/*      <Form.Switch*/}
                {/*        color="pink"*/}
                {/*        onClick={e => this.onChangeSearch(e)}*/}
                {/*        name="diversity"*/}
                {/*        className="combineButtons"*/}
                {/*        label="Diversity Only"*/}
                {/*        checked={*/}
                {/*          this.state.searchOptions.diversity == 0 ? false : true*/}
                {/*        }*/}
                {/*      />*/}
                {/*    </Form.Group>*/}
                {/*  )}*/}

                <Form.Group>
                  {this.state.generated === false ? (
                    <Button
                      color="purple"
                      size="md"
                      className="reportButton"
                      onClick={this.generateReport}
                    >
                      Generate Report
                    </Button>
                  ) : (
                    <Button color="green" size="md" className="reportButton">
                      <CSVLink
                        data={this.state.dataForDownload}
                        headers={headers}
                        filename={`report_${new Date()
                          .toDateString()
                          .split(" ")
                          .join("_")}.csv`}
                        style={{ color: "white" }}
                      >
                        Download Report
                      </CSVLink>
                    </Button>
                  )}
                </Form.Group>
              </Card.Options>
            </Card.Header>
            <Table
              responsive
              className="card-table table-vcenter text-nowrap"
              headerItems={[
                { content: "No.", className: "w-1" },
                { content: "Assigned ID" },
                { content: "Name" },
                { content: "Nationality" },
                { content: "Agent" },
                { content: "email" },
                { content: "Term" },
                { content: "Created" },
                { content: "Status" },
                { content: "Course" },
                { content: null },
                { content: null },
              ]}
              bodyItems={listView}
            />
            {listView == false ? (
              <div className="notfound notfound-search">
                <img src="/assets/icons/sad-search.png" />
                <span>
                  No result found{" "}
                  {!isEmpty(this.state.searchOptions.searchQuery)
                    ? ` for: ${this.state.searchOptions.searchQuery}`
                    : ""}
                </span>
              </div>
            ) : (
              ""
            )}
          </Card>
        </Grid.Col>
      </Grid.Row>
    );
  }

  // Function to switch between the content based on the data and user
  showContent(permission, listView) {
    // Check if the listView is empty
    if (!isEmpty(listView)) {
      return this.showApplications(listView);
    } else {
      const messsage =
        permission == 1
          ? "You didn't submitted any application, "
          : "No application has been submitted by the agents";
      return (
        <div className="notfound">
          <img src="/assets/icons/application.png" />
          <span>
            {messsage}
            <br /> Please click on the button below to submit one.
          </span>
          <a href="/application/submit">
            <button className="btn btn-primary">Submit application</button>
          </a>
        </div>
      );
    }
  }

  // Function to set the current application
  async handleEdit(applicationID, currentPassport) {
    // Get the current application state
    await this.props.getCurrentApplication(applicationID);
    // Set the current application and submission
    await this.props.setCurrentApplication(true, applicationID);
    // Redirect the user to form submission
    this.props.history.push("/application/submit");
  }

  render() {
    // Get the application
    const applications = this.state.applications;
    let listView = [];
    let dateObject;
    let dateReadable;
    let currentLoop = 0;
    let fullName;

    // Check if the seach mode exits and state is isEmpty
    if (!isEmpty(this.state.searchOptions) && isEmpty(applications)) {
      listView = false;
    } else {
      applications.forEach(application => {
        // Get the user full name
        fullName = application.firstName + " " + application.lastName;

        // Get the date
        dateObject = new Date(Date.parse(application.createdAt));
        dateReadable = dateObject.toDateString();

        // Get the status of the application
        const { statusColor, statusText } = applicationStatus(
          application.status
        );

        // Get the profile score
        /*let scoreColor = "blue";
        if (application.profileScore < 60) {
          scoreColor = "red";
        } else if (application.profileScore >= 60) {
          scoreColor = "blue";
        } else if (application.profileScore >= 80) {
          scoreColor = "green";
        }*/

        // Set the data
        listView[currentLoop] = [
          {
            content: (
              <Text RootComponent="span" muted>
                {application.status == 5
                  ? application.studentId
                  : application.passport}
              </Text>
            ),
          },
          {
            content: (
              <Text RootComponent="span" muted>
                EDUS
                {Array(6 - application.id.toString().length)
                  .fill()
                  .map(value => "0")}
                {application.id}
              </Text>
            ),
          },
          {
            content: (
              <div style={{ position: "relative" }}>
                {fullName}{" "}
                {application.notifications > 0 ? (
                  <span
                    class=""
                    style={{
                      marginLeft: "5px",
                      backgroundColor: "#fe5757",
                      color: "white",
                      borderRadius: "50%",
                      paddingLeft: "6px",
                      paddingRight: "6px",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {application.notifications}
                  </span>
                ) : (
                  ""
                )}
              </div>
            ),
          },
          {
            content: application.nationality,
          },
          {
            content: (
              <a
                href=""
                onClick={e => this.toggle(e, application.User)}
                className="text-inherit"
              >
                {application.User.name}
              </a>
            ),
          },
          { content: application.email },
          { content: application.ProgramsAvailable.Term.name },
          { content: dateReadable },
          {
            content: (
              <React.Fragment>
                <span className={`status-icon bg-${statusColor}`} />{" "}
                {statusText}
              </React.Fragment>
            ),
          },
          {
            content:
              application.ProgramsAvailable.Program.institution +
              " | " +
              application.ProgramsAvailable.Program.code,
          },
          {
            alignContent: "right",
            content: (
              <React.Fragment>
                <Link to={`/application/view/${application.id}`}>
                  <Button size="sm" color="secondary">
                    View
                  </Button>
                </Link>
              </React.Fragment>
            ),
          },
        ];
        currentLoop++;
      });
    }

    return (
      <SiteWrapper>
        <Page.Content contain={true}>
          <div>
            {this.state.agentData ? (
              <AgentInfo
                toggle={this.toggle}
                agentInfo={this.state.agentInfo}
                agentData={this.state.agentData}
              />
            ) : (
              ""
            )}
            <Grid.Col width={12}>
              {this.showContent(this.props.user.permission, listView)}
              <Pagination
                prevIcon={<Icon link name="arrow-left" />}
                nextIcon={<Icon link name="arrow-right" />}
                jumpPrevIcon={<Icon link name="more-horizontal" />}
                jumpNextIcon={<Icon link name="more-horizontal" />}
                onChange={current => {
                  this.setState({ ...this.state, page: current });
                  this.props.history.push(
                    `/applications/?page=${current}&searchStatus=${
                      this.state.searchOptions.searchStatus
                    }&searchTerm=${
                      this.state.searchOptions.searchTerm
                    }&searchAgent=${
                      this.state.searchOptions.searchAgent
                    }&searchProgram=${
                      this.state.searchOptions.searchProgram
                    }&searchDate=${this.state.searchOptions.searchDate}&${
                      this.state.searchOptions.searchType
                    }=${this.state.searchOptions.searchQuery}&diversity=${
                      this.state.searchOptions.diversity
                    }`
                  );
                }}
                current={this.state.page}
                total={this.props.application.count}
              />
            </Grid.Col>
          </div>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

// Set the propsTypes
Applications.propTypes = {
  applicationsList: PropTypes.func.isRequired,
  setCurrentApplication: PropTypes.func.isRequired,
  getCurrentApplication: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
};

// Set the props that we want to send to applicationsList
const mapStateToProps = state => ({
  application: state.application,
  user: state.auth.user,
  settings: state.settings,
  terms: state.terms,
  auth: state.auth,
  programs: state.programs,
});

export default connect(
  mapStateToProps,
  {
    applicationsList,
    setCurrentApplication,
    getCurrentApplication,
    userList,
    programsList,
  }
)(Applications);
