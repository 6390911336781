// File reader

export const readFileFrom = file => {
  return new Promise((resolve, reject) => {
    var fileReader = new FileReader();
    fileReader.onloadend = function(evt) {
      resolve(fileReader.result);
    };
    // Read the file.
    fileReader.readAsText(file);
  });
};
