import { Button, Select, Text } from "@mantine/core";
import { institutionList } from "./Step1";
import React, { useEffect, useState } from "react";
import { useChangeProgram, useGetOpenPrograms } from "./api";
import { useGetAllTerms } from "../Applications/api";
import { useAppStore } from "../../lib/zustandStore";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";

/**
 * @param {Object} props
 * @param {boolean} props.primary
 * @param {function} props.close
 */
export default function ProgramPicker({ close, primary }) {
  const [institution, setInstitution] = useState("");
  const [term, setTerm] = useState("");
  const [termList, setTermList] = useState([{ label: "", value: "" }]);
  const [programList, setProgramList] = useState([{ label: "", value: "" }]);
  const appId = useAppStore(state => state.appId);

  const [form, setForm] = useState({
    application: appId,
    program: "",
    program_secondary: "",
  });

  const { data: termData } = useGetAllTerms();

  /** @type {{data: import('../../types').OpenProgram[], isLoading: boolean}} */
  const {
    data: programData,
    isLoading: programIsLoading,
  } = useGetOpenPrograms();

  const handleProgramChange = (key, value) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const handleChange = (e = "", v = "") => {
    if (v === "") {
      setInstitution(e);
    }
    if (e === "") {
      setTerm(v);
    }
  };

  const { mutateAsync: mutateProgram } = useChangeProgram();

  const handleSubmit = async () => {
    if (form.program === "" && form.program_secondary === "") {
      close();
      setForm({
        ...form,
        program: "",
        program_secondary: "",
      });
      return;
    }

    try {
      if (!appId) {
        notifications.show({
          title: "Something went wrong changing program(s)",
          color: "red",
          icon: <IconX />,
        });
        return;
      }

      await mutateProgram(form);
      notifications.show({
        title: "Program(s) changed!!",
        color: "teal",
        icon: <IconCheck />,
      });
      close();
    } catch (err) {
      console.log(err);
      notifications.show({
        title: "Something went wrong changing program(s)",
        color: "red",
        icon: <IconX />,
      });
    }
  };

  // create list of programs based on selected campus/institution and term
  useEffect(
    () => {
      if (programData?.length > 0) {
        let temp = programData
          // .filter(e => e.Program.institution === institution && e.term === term)
          .filter(e => e.Program.institution === institution && e.term === term)
          .map(e => ({
            label: `${e.Program.name} - ${e.Term.name}`,
            value: e.id,
          }));
        setProgramList(temp);
      }
    },
    [institution, programData, term]
  );

  useEffect(
    () => {
      setTermList(termData?.map(e => ({ label: e.name, value: e.id })));
    },
    [termData]
  );

  if (programIsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Text mb="sm">
        Select at least one program or secondary program. If you do not want to
        change the existing program, simply leave the field{" "}
        <strong>empty</strong>.
      </Text>
      <Select
        label="Institution"
        nothingFound="No institutions found"
        name="institution"
        value={institution}
        data={institutionList}
        onChange={e => handleChange(e, "")}
      />
      <Select
        label="Term"
        nothingFound="No open terms found"
        name="term"
        value={term}
        data={termList}
        onChange={v => handleChange("", v)}
      />
      <Select
        label="Primary Program"
        searchable
        data={programList}
        onChange={e => handleProgramChange("program", e)}
        nothingFound="No programs found"
      />
      <Select
        defaultValue=""
        label="Secondary Program"
        searchable
        data={programList}
        onChange={e => handleProgramChange("program_secondary", e)}
        nothingFound="No programs found"
      />
      <Button mt="md" onClick={handleSubmit}>
        Update
      </Button>
    </div>
  );
}
