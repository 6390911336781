import { combineReducers } from "redux";
import authReducers from "./authReducers";
import applicationReducers from "./applicationReducers";
import errorsReducer from "./errorsReducer";
import programsReducers from "./programsReducers";
import documentReducers from "./documentReducers";
import paymentsReducers from "./paymentsReducers";
import announcementReducer from "./announcementReducer";
import settingsReducer from "./settingsReducer";
import termsReducer from "./termsReducer";

export default combineReducers({
  auth: authReducers,
  application: applicationReducers,
  programs: programsReducers,
  documents: documentReducers,
  payments: paymentsReducers,
  announcement: announcementReducer,
  settings: settingsReducer,
  errors: errorsReducer,
  terms: termsReducer
});
