import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Button, Card, Container, Form, Header } from "tabler-react";
import { useRegisterAgent } from "../hooks/useRegister";
import { userLogin } from "../actions/authActions";
import countriesList from "../utils/countriesList";

const Register = props => {
  const { isSuccess, mutate: registerAgent, error, data } = useRegisterAgent();

  // If applying agent, go to their agent application
  // If anyone else, go to /applications
  useEffect(() => {
    if (props.auth.isAuthenticated && props.auth.user.permission !== 5) {
      props.history.push("/applications");
    }
    if (props.auth.user.permission === 5) {
      props.history.push("/agentapps");
    }
  }, []);

  // Redirect after successfully registering
  useEffect(
    () => {
      if (isSuccess) {
        props.userLogin({ email: data.email, password: data.password });
        props.history.push("/agentapps");
      }
    },
    [isSuccess]
  );

  const registerUser = (email, companyName, country, password, password2) => {
    if (password === password2) {
      registerAgent({
        email,
        companyName,
        country,
        password,
      });
    }
  };

  return (
    <Container className="defaultMargin">
      <div style={{ width: "400px", margin: "auto" }}>
        <Card>
          <Card.Body>
            <Header.H3>Agent Registration Form</Header.H3>
            <div>
              <img src="/assets/logo2.png" className="mainLogo" />
            </div>
            <Formik
              initialValues={{
                email: "",
                companyName: "",
                country: "",
                password: "",
                password2: "",
              }}
              validate={values => {
                const errors = {};

                if (!values.companyName) errors.companyName = "Required";

                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }

                if (!values.password) errors.password = "Required";

                if (values.password.length < 6)
                  errors.password = "Must be at least 6 characters long";

                if (
                  values.password !== "" &&
                  values.password2 !== "" &&
                  values.password !== values.password2
                ) {
                  errors.password = "Passwords do not match";
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                console.log(values);
                registerUser(
                  values.email,
                  values.companyName,
                  values.country,
                  values.password,
                  values.password2
                );
                setSubmitting(false);
              }}
            >
              {({
                errors,
                values,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Form.Input
                    label="Company Name"
                    name="companyName"
                    value={values.companyName}
                    onChange={handleChange}
                    feedback={error && error.response.data.companyName}
                    invalid={error && error.response.data.companyName}
                  />
                  <Form.Select
                    label="Country"
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                  >
                    {countriesList(values.country)}
                  </Form.Select>
                  <Form.Input
                    label="Email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    feedback={error && error.response.data.email}
                    invalid={error && error.response.data.email}
                  />
                  <Form.Input
                    label="Password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    feedback={errors.password}
                    invalid={errors.password}
                  />
                  <Form.Input
                    label="Confirm Password"
                    name="password2"
                    type="password"
                    value={values.password2}
                    onChange={handleChange}
                  />
                  <Button type="submit" disabled={isSubmitting}>
                    Submit
                  </Button>
                </form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  { userLogin }
)(Register);
