/**
 *
 * @param {number[]} permissions list of permissions
 * @param {bool} isAllowed should permission array be allowed or not
 * @param {number} userPermission the current user's permission
 * @returns {bool}
 */
export const renderFor = (permissions, isAllowed, userPermission) => {
  if (isAllowed === true) {
    if (permissions.indexOf(userPermission) > -1) {
      return true;
    }
  } else {
    if (permissions.indexOf(userPermission) < 0) {
      return true;
    }
  }

  return false;
};
