// @flow

import React from "react";
import SiteWrapper from "../SiteWrapper.react";
import { getTerms, setTerms } from "../actions/termActions";
import { connect } from "react-redux";
import isEmpty from "../utils/is-empty";
import Swal from "sweetalert2";
import IntakeModal from "../components/modals/Intake";
import { Page, Grid, Card, Table, Button, Icon } from "tabler-react";

class Terms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      intakeData: {},
      terms: {},
      intakeModal: false,
      errors: {},
    };

    // Backend and user input functions
    this.onChange = this.onChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddTerm = this.handleAddTerm.bind(this);

    // Modal Functions
    this.intakeModalToggle = this.intakeModalToggle.bind(this);
    this.closeModals = this.closeModals.bind(this);
  }

  // Set the errors state
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    this.setState({ terms: nextProps.terms });
  }

  async componentWillMount() {
    // Get the list of the terms
    this.props.getTerms();
  }

  intakeModalToggle() {
    this.setState({
      intakeModal: !this.state.intakeModal,
    });
  }

  // Close all the moddals
  closeModals() {
    this.setState({
      intakeModal: false,
    });
  }

  // Handle add term
  handleAddTerm() {
    // Gather the data
    const data = {};
    data.name = this.state.intakeData.name;
    data.year = this.state.intakeData.year;
    data.term = this.state.intakeData.term;
    data.description = this.state.intakeData.desc;

    // Send Request to the server using axios
    this.props.setTerms(data);
  }

  // Hanlde the input changes
  onChange(e) {
    this.setState({
      intakeData: {
        ...this.state.intakeData,
        [e.target.name]: e.target.value,
      },
    });
  }

  // Handle the delete program
  handleDelete(id) {
    //Show the popup
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, withdraw it!",
    }).then(result => {
      if (result.value) {
        // Send the delete request
        const data = {};
        data.term = id;
        data.status = 0;

        // Send Request to the backend delete API
        const response = this.props.changeTermStatus(data);

        if (response) {
          Swal.fire("Deleted!", "Term has been removed.", "success");
        } else {
          Swal.fire(
            "Error",
            "There is some error, Please contact support.",
            "error"
          );
        }
      }
    });
  }

  // Function to list the programs
  listTerms(terms) {
    // finalList array
    const finalList = [];

    Object.keys(terms).forEach(key => {
      // Set the term data
      let id = terms[key].id;
      let name = terms[key].name;
      let year = terms[key].year;
      let term = terms[key].term;
      let desc = terms[key].description || "Not Provided";
      let date = terms[key].createdAt;

      finalList.push(
        <Table.Row>
          <Table.Col>{name}</Table.Col>
          <Table.Col>{year}</Table.Col>
          <Table.Col>{`Term ${term}`}</Table.Col>
          <Table.Col>{desc}</Table.Col>
          <Table.Col className="text-nowrap">{date}</Table.Col>
          {this.props.auth.user.permission == 0 ? (
            <Table.Col>
              <Icon
                className="combineButtons"
                onClick={() => this.handleDelete(id)}
                name="trash"
              />
              <Icon
                className="combineButtons"
                onClick={() => this.handleAddTerm(id)}
                name="edit"
              />
            </Table.Col>
          ) : (
            ""
          )}
        </Table.Row>
      );
    });

    //Return the final list
    return finalList;
  }

  //Empty view
  emptyView() {
    const message =
      this.props.auth.user.permission != 0 ? (
        <span>
          We don't have any terms open <br /> Please come back later!
        </span>
      ) : (
        <div>
          <span>
            You don't have any terms available
            <br /> Please click on the button below to add one.
          </span>
          <button className="btn btn-primary" onClick={this.intakeModalToggle}>
            Add Intake
          </button>
        </div>
      );
    return (
      <div className="notfound">
        <img src="/assets/icons/switch.png" />
        {message}
      </div>
    );
  }

  // Card view
  cardView() {
    return (
      <Card>
        <Card.Header>
          <Card.Title>Terms Available</Card.Title>
          <Card.Options>
            {this.props.auth.user.permission == 0 ? (
              <Button
                onClick={this.intakeModalToggle}
                color="purple"
                size="sm"
                className="ml-2"
              >
                New Term
              </Button>
            ) : (
              ""
            )}
          </Card.Options>
        </Card.Header>

        <Table
          cards={true}
          striped={true}
          responsive={true}
          className="table-vcenter"
        >
          <Table.Header>
            <Table.Row>
              <Table.ColHeader>Intake Name</Table.ColHeader>
              <Table.ColHeader>Intake Year</Table.ColHeader>
              <Table.ColHeader>Intake Term</Table.ColHeader>
              <Table.ColHeader>Description</Table.ColHeader>
              <Table.ColHeader>Date Added</Table.ColHeader>
              {this.props.auth.user.permission == 0 ? (
                <Table.ColHeader>Manage Options</Table.ColHeader>
              ) : (
                ""
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.listTerms(this.state.terms)}</Table.Body>
        </Table>
      </Card>
    );
  }

  render() {
    return (
      <SiteWrapper>
        <IntakeModal
          intakeModal={this.state.intakeModal}
          intakeModalToggle={this.intakeModalToggle}
          intakeData={this.state.intakeData}
          errors={this.state.errors}
          addTerm={this.handleAddTerm}
          closeIntake={this.closeModals}
          onChange={this.onChange}
        />

        <Page.Content>
          <Grid.Row>
            <Grid.Col width={12}>
              {isEmpty(this.state.terms) ? this.emptyView() : this.cardView()}
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

// Map the state to props
const mapStateToProps = state => ({
  programs: state.programs,
  errors: state.errors,
  terms: state.terms,
  settings: state.settings,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { getTerms, setTerms }
)(Terms);
