// @flow

import * as React from "react";

import { Card, Container, Grid, Form, Button, Table } from "tabler-react";

import SiteWrapper from "../../SiteWrapper.react";
import "../../App.css";
import { connect } from "react-redux";
import isEmpty from "../../utils/is-empty";
import { handleApplicationSubmissions } from "../../actions/applicationActions";
import { filesContentView } from "../../utils/contentView";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../config/config.js";

class Step4 extends React.Component {
  constructor(props) {
    super(props);

    // Set the initial State
    this.state = {
      formData: {},
      file: {},
      uploading: false,
      errors: {},
      studentId: "",
    };
    // Bind the functions with {this}
    this.onChange = this.onChange.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.success = this.success.bind(this);
  }

  // Check if the form is in submission mode
  componentWillMount() {
    // Redirect if form is not in submission mode
    if (
      !this.props.application.submissionMode &&
      !this.props.application.editMode
    ) {
      this.props.history.push("/application/submit");
    }

    // Check if we are in edit mode and got currentApplication data
    if (
      this.props.application.editMode &&
      !isEmpty(this.props.application.currentApplication.ApplicationDocuments)
    ) {
      // Set the form state to currentApplication state
      this.setState({
        formData: this.props.application.currentApplication
          .ApplicationDocuments,
      });
    }
  }

  // Hanlde the input changes
  onChange(e) {
    if (e.target.name == "fileInput") {
      this.setState({ file: e.target.files[0] });
    } else {
      this.setState({ documentType: e.target.value });
    }
  }

  // Set the errors state
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  // Handle the form submission
  handleFormSubmission = async e => {
    e.preventDefault();

    // Set the application data
    const formData = new FormData();
    if (isEmpty(this.state.documentType)) this.setState({ documentType: "" });
    formData.append("documentType", this.state.documentType);
    formData.append("file", this.state.file);
    formData.append("application", this.props.application.currentApplicationID);

    //Reset the errors
    this.setState({ errors: {} });

    // check if document is letter of acceptance
    if (this.state.documentType === "6") {
      const studentId = this.state.file.name.substring(
        0,
        this.state.file.name.indexOf("_")
      );

      if (studentId.length === 9 && studentId.substring(0, 3) === "500") {
        this.setState({
          studentId,
        });
      } else {
        console.log(studentId, "is not the correct format");
      }
    }

    // Send the application to action
    this.setState({ uploading: true });
    await this.props.handleApplicationSubmissions(
      formData,
      "",
      `${config.server}${config.api.applicationDocuments}`,
      4,
      this.props.history
    );
    this.setState({ uploading: false });
    this.setState({
      formData: this.props.application.currentApplication.ApplicationDocuments,
    });
  };

  // Handle the previous button
  handlePrevious = e => {
    e.preventDefault();
    if (true) {
      // Redirect user to next link
      this.props.history.push("/application/Academic");
    }
  };

  // Success message Swal for Job Done
  success() {
    return Swal.fire(
      "Good job!",
      "Your application is be submitted for review!",
      "success"
    ).then(confirm => {
      this.props.history.push(
        `/application/view/${this.props.application.currentApplication.id}`
      );
    });
  }

  // Function to handle to complete application submission
  handleCompleteApplication(e) {
    e.preventDefault();

    // Check the current application status
    const status = this.props.application.currentApplication.status;
    // Get the application id
    const id = this.props.application.currentApplication.id;

    // if a student ID is stored in state...
    if (!isEmpty(this.state.studentId)) {
      axios
        .post(`${config.server}${config.api.applicationStudentId}`, {
          id: this.props.application.currentApplication.id,
          studentId: this.state.studentId,
        })
        .then(result => {
          console.log(result);
        })
        .catch(err => console.error(err));
    }

    if (status >= 2) {
      // See if status is beyond Under review
      return this.success();
    }

    // Send the axios request to change the application status
    axios
      .post(`${config.server}${config.api.applicationStatus}`, {
        status: 26,
        application: id,
      })
      .then(success => {
        this.success();
      })
      .catch(err => {
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: "<a href>Please contact the support team?</a>",
        });
      });
  }

  //Function to list the files
  listFiles(files) {
    // Check if file array is empty
    if (isEmpty(files)) {
      return (
        <div className="notfound small">
          <img src="/assets/icons/file.png" />
          <span>
            You don't have any files right now, <br />
            Please upload a file first.
          </span>
        </div>
      );
    }

    return filesContentView(files);
  }

  render() {
    return (
      <SiteWrapper>
        <div className="my-3 my-md-5">
          <Container className="defaultMargin">
            <Grid.Row>
              <Grid.Col lg={8} className="applicationForm">
                <Card title="Documents infomations">
                  <Card.Body>
                    <ul>
                      <h4>
                        List of the documents that your are required to submit:
                      </h4>
                      <li>Copy of the applicant's passport</li>
                      <li>Academic transcripts</li>
                      <li>
                        Academic Degress\certificates with consolidate
                        GPA\Passing Class
                      </li>
                      <li>Provisional Certificates if applicable</li>
                      <li>English test score if applicable</li>
                      <li>
                        Documents supporting employment history if applicable
                      </li>
                      <li>
                        Notice of disclosure-Confidentiality and Use of personal
                        Data
                      </li>
                      <hr />
                      <h4>Important note</h4>
                      <span>
                        Please note that the application is saved upto this
                        point and can be completed later. You can also contact
                        support for any help by emailing at
                        loyalistinternational@tbcollege.com
                      </span>
                      <hr />
                      <h4>What files one can upload?</h4>
                      <span>
                        Files should be in PDF/JPG/PNG format and not in any
                        other file format will be accepted and file size should
                        be less than 5MB.
                      </span>
                    </ul>
                  </Card.Body>
                </Card>
                <Card
                  title="Submit Application - Upload Documents"
                  RootComponent={Form}
                  footer={
                    <div className="d-flex">
                      <Button
                        loading={this.state.uploading}
                        onClick={this.handleFormSubmission}
                        type="submit"
                        color="primary"
                        icon="upload"
                        className="ml-auto"
                      >
                        Upload file
                      </Button>
                    </div>
                  }
                >
                  <Card.Body>
                    <Form.Group label="Document type">
                      <Form.Select
                        name="documentType"
                        value={this.state.documentType}
                        feedback={
                          isEmpty(this.state.errors.documentType)
                            ? ""
                            : this.state.errors.documentType
                        }
                        invalid={
                          isEmpty(this.state.errors.documentType) ? false : true
                        }
                        onChange={this.onChange}
                      >
                        <option value="0">Select a document type</option>
                        {this.props.auth.user.permission != 1 ? (
                          <option value="6">Letter of acceptance</option>
                        ) : (
                          ""
                        )}
                        {this.props.auth.user.permission != 1 ? (
                          <option value="7">Conditional offer letter</option>
                        ) : (
                          ""
                        )}
                        {this.props.auth.user.permission != 1 ? (
                          <option value="8">Wire transfer receipt</option>
                        ) : (
                          ""
                        )}
                        <option value="1">Passport</option>
                        <option value="2">ID card</option>
                        <option value="3">Academic Documents</option>
                        <option value="4">IELTS/PTE/OTHERS</option>
                        <option value="10">Highschool marksheets</option>
                        <option value="11">Highschool certificate</option>
                        <option value="15">Bachelors marksheets</option>
                        <option value="16">Bachelors certificate</option>
                        <option value="17">Post-Graduate marksheets</option>
                        <option value="18">Post-Graduate certificate</option>
                        <option value="19">Employment Documents</option>
                        <option value="20">
                          Status in Canada Documents - if applicable
                        </option>
                        {/* <option value="9">All merged in one</option> */}
                        <option value="5">Others Documents</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group label="File input">
                      <Form.FileInput
                        name="fileInput"
                        feedback={
                          isEmpty(this.state.errors.fileInput)
                            ? ""
                            : this.state.errors.fileInput
                        }
                        invalid={
                          isEmpty(this.state.errors.fileInput) ? false : true
                        }
                        onChange={this.onChange}
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>
                <Card
                  title="Your files"
                  RootComponent={Form}
                  footer={
                    <div className="d-flex">
                      <Button
                        onClick={this.handlePrevious}
                        color="secondary"
                        link
                      >
                        Previous Step
                      </Button>
                      <Button
                        disabled={isEmpty(this.state.formData)}
                        onClick={this.handleCompleteApplication.bind(this)}
                        type="submit"
                        color="primary"
                        className="ml-auto"
                      >
                        Submit Application
                      </Button>
                    </div>
                  }
                >
                  <Card.Body>
                    <Table
                      cards={true}
                      striped={true}
                      responsive={true}
                      className="table-vcenter"
                    >
                      <Table.Body>
                        {this.listFiles(this.state.formData, true)}
                      </Table.Body>
                    </Table>
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Container>
        </div>
      </SiteWrapper>
    );
  }
}

const mapStateToProps = state => ({
  application: state.application,
  auth: state.auth,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  { handleApplicationSubmissions }
)(Step4);
