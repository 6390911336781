import { useMutation, useQuery } from "react-query";
import queryClient from "../lib/queryClient";
import axios from "axios";
import config from "../config/config";

/**
 *  @param {Object} payload
 *  @param {number} [payload.id] id of the applicationLog
 */
const toggleRead = payload => {
  return axios.post(`${config.server}${config.api.notificationToggle}`, {
    id: payload.id,
  });
};

export const useToggleRead = () => {
  return useMutation(toggleRead, {
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
  });
};
