import axios from "axios";
import config from "../config/config";
import { useQuery } from "react-query";

/**
 * @typedef {Object} User
 * @property {string} id
 * @property {string} name
 * @property {string} email
 * @property {number} status
 * @property {string} assignedTo
 * @property {Object} User
 *
 * @returns {Promise<User[]>}
 */
const fetchAllUsers = async () => {
  const res = await axios.get(`${config.server}${config.api.userList}`);
  return res.data;
};

export const useGetAllUsers = () =>
  useQuery(["allUsers"], () => fetchAllUsers());
