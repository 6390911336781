/**
 * @readonly
 * @type {Array<{value: string, label: string, staffOnly: boolean}>}
 */
export const documentTypes = [
  { value: "6", label: "Letter of Acceptance", staffOnly: true },
  { value: "7", label: "Conditional Offer Letter", staffOnly: true },
  { value: "8", label: "Wire Transfer Receipt", staffOnly: true },
  { staffOnly: false, value: "1", label: "Passport" },
  { staffOnly: false, value: "2", label: "ID Card" },
  { staffOnly: false, value: "3", label: "Academic Documents" },
  { staffOnly: false, value: "4", label: "IELTS/PTE/OTHERS" },
  { staffOnly: false, value: "10", label: "High School Marksheets" },
  { staffOnly: false, value: "11", label: "High School Certificate" },
  { staffOnly: false, value: "15", label: "Bachelors Marksheets" },
  { staffOnly: false, value: "16", label: "Bachelors Certificate" },
  { staffOnly: false, value: "17", label: "Post-Graduate Marksheets" },
  { staffOnly: false, value: "18", label: "Post-Graduate Certificate" },
  { staffOnly: false, value: "19", label: "Employment Documents" },
  {
    staffOnly: false,
    value: "20",
    label: "Status in Canada Documents - if applicable",
  },
  { staffOnly: false, value: "21", label: "Application Fee" },
  { staffOnly: false, value: "5", label: "Others Documents" },
];
