/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import "../../App.css";
import {
  Container,
  Grid,
  Table,
  Button,
  Form,
  Card,
  Stamp,
  Timeline,
  Avatar,
} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import {
  getCurrentApplication,
  setCurrentApplication,
  handleApplicationSubmissions,
  updateApplicationStatus,
  updateApplicationAgent,
} from "../../actions/applicationActions";
import contentView from "../../utils/contentView";
import Swal from "sweetalert2";
import { filesContentView } from "../../utils/contentView";
import isEmpty from "../../utils/is-empty";
import axios from "axios";
import { applicationStatus } from "../../utils/applicationStatus";
import config from "../../config/config.js";
import { allCapitalLetters } from "../../utils/formatUserData";
import { userList } from "../../actions/authActions";
import { termsList } from "../../utils/contentView";
import {
  SwalDeferOrRefund,
  SwalVisaStatus,
  SwalWithdraw,
} from "../../components/SwalFunctions";
import { renderFor } from "../../utils/renderComp";

class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: "",
      errors: {},
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.statusButton = this.statusButton.bind(this);
    this.offerLetter = this.offerLetter.bind(this);
    this.sendOffer = this.sendOffer.bind(this);
    this.deferRequest = this.deferRequest.bind(this);
    this.changeAppStatus = this.changeAppStatus.bind(this);
    this.handleAgent = this.handleAgent.bind(this);
    this.agentSwitch = this.agentSwitch.bind(this);
    this.clearNotifications = this.clearNotifications.bind(this);
  }

  // Set the errors state
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  // Get the current application infomation
  async componentDidMount() {
    // Get the current application's passport number
    const currentId = this.props.match.params.id;
    await this.props.getCurrentApplication(currentId);

    await this.props.userList();
    // Set the user/agent list and initial states
    // NOTE: Idk if this is necessary...
    this.setState({
      initialList: this.props.auth.list,
      initialAgentId: this.props.application.currentApplication.User.id,
      initialAgentName: this.props.application.currentApplication.User.name,
    });
  }

  // Function to set the current application
  async handleEdit(e, location) {
    e.preventDefault();
    // Check the current application status
    const currentStatus = this.props.application.currentApplication.status;
    if (
      currentStatus != 0 &&
      currentStatus != 1 &&
      currentStatus != 3 &&
      this.props.user.permission == 1
    ) {
      return Swal.fire({
        title: "Application under process",
        text:
          "You can no longer edit this application as it is under review, Please contact support for more info.",
        type: "warning",
        confirmButtonText: "Okay",
      });
    }

    // Exec the function to set the current application
    await this.props.setCurrentApplication(
      true,
      this.props.application.currentApplication.id
    );
    // Set the current application id
    // Redirect the user to form submission
    const finalLocation = !isEmpty(location) ? location : "/application/submit";
    this.props.history.push(finalLocation);
  }

  // Function to delete the application
  async deleteApplication(applicationID) {
    // Send the delete request
    const applicationData = { application: applicationID };

    await axios
      .delete(`${config.server}${config.api.applicationDelete}`, {
        data: applicationData,
      })
      .then(res => {
        return res.response.success;
      })
      .catch(err => {
        if (!isEmpty(err)) {
          return err.response.data.success;
        }
      });
  }

  // remove all notifications from application
  async clearNotifications() {
    await axios.post(
      `${config.server}${config.api.notificationClear}/${
        this.props.application.currentApplication.id
      }`
    );

    alert("Notifications cleared");
  }

  //Function to list the files
  listFiles(files, fileType) {
    // Check if file array is empty
    if (isEmpty(files)) {
      return (
        <div className="notfound small">
          <img src="/assets/icons/file.png" />
          <span>
            You don't have any files right now, <br />
            Please upload a file first.
          </span>
        </div>
      );
    }
    return filesContentView(files, true, true, fileType);
  }

  // Function to defer app
  async deferRequest(e) {
    const { id } = this.props.application.currentApplication;

    const message = await SwalDeferOrRefund(null, this.props.terms);

    if (!message) {
      return;
    }

    const applicationData = {
      application: id,
      message,
      status: 25,
    };

    await this.changeAppStatus(applicationData);
  }

  agentSwitch(evt) {
    let e = evt.target.value;

    Swal.fire({
      title: "Agent switch",
      text: "Do you want to change the agent?",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async req => {
      if (req.value) {
        await this.handleAgent(e);
      }
    });
  }

  // Function to handle switching agent
  async handleAgent(e) {
    const applicationData = {
      application: this.props.application.currentApplication.id,
      agent: e,
    };

    await this.props.updateApplicationAgent(applicationData);

    if (isEmpty(this.state.errors)) {
      Swal.fire({
        text: "Successfully changed agent",
        type: "success",
      });
      this.props.history.push(
        `/application/view/${this.props.application.currentApplication.id}`
      );
    } else {
      if (!isEmpty(this.state.errors)) {
        Swal.fire({
          text: "Failed to change agent",
          type: "error",
        });
        console.log("Agent switch failed", this.state.errors);
      }
    }
  }

  //Function to handle update button
  async handleUpdate(e) {
    const { value: status } = await Swal.fire({
      title: "<h4>Change the application status</h4>",
      input: "select",
      html: "",
      inputOptions: {
        // 1: "Pending",
        2: "Under Review",
        3: "Require Changes",
        4: "Declined",
        21: "Pre-registered",
        5: "Approved",
        20: "Deferred",
        10: "Payment Received",
        15: "Visa Requested",
        25: "Defer Requested",
        // 27: "System Declined",
        29: "Paid",
        48: "Revoked",
        49: "Refunded",
        40: "Closed",
      },
      inputPlaceholder: "Select a status",
      showCancelButton: true,
    });

    if (status) {
      // Approve application
      if (status === "5") {
        await Swal.fire({
          title: "Enter a student ID (optional)",
          input: "text",
          inputPlaceholder: "student ID...",
          inputAutoTrim: true,
          showCancelButton: true,
          inputValidator: value => {
            if (!isEmpty(value) && (value.length < 9 || value.length > 9)) {
              return "Student number must be 9 characters long";
            }
          },
        }).then(result => {
          if (!result.dismiss) {
            const applicationData = {
              application: this.props.application.currentApplication.id,
              message: allCapitalLetters(result.value),
              status: parseInt(status),
            };

            this.changeAppStatus(applicationData);
          }
        });
      } else {
        // Ask for the comment
        const { value: comment } = await Swal.fire({
          title: "Do you want to add any comment?",
          input: "textarea",
          inputPlaceholder: "Type your message here...",
          showCancelButton: true,
        });

        const applicationData = {
          application: this.props.application.currentApplication.id,
          message: comment,
          status: parseInt(status),
        };
        await this.changeAppStatus(applicationData);
      }
    }
  }

  // Function to send change status request
  async changeAppStatus(applicationData) {
    // Send the request to update the status of this application
    await this.props.updateApplicationStatus(applicationData);

    // Check if there are any errors
    if (isEmpty(this.state.errors)) {
      Swal.fire("Done!", "Application status has been updated", "success").then(
        success => {
          this.props.history.push(
            `/application/view/${this.props.application.currentApplication.id}`
          );
        }
      );
    } else {
      if (!isEmpty(this.state.errors))
        Swal.fire("Request Failed", this.state.errors.message, "error");
    }
  }

  //Hanlde the Withdraw application request
  async handleWithdraw(e) {
    // Check the application status
    const { status, id } = this.props.application.currentApplication;

    if (status >= 2) {
      const withdrawReason = await SwalWithdraw(id);

      if (!withdrawReason) {
        return;
      }

      const deferRefundValue = await SwalDeferOrRefund(id, this.props.terms);

      switch (typeof deferRefundValue) {
        case "string": {
          // defer
          const message = withdrawReason.concat(" ", deferRefundValue);
          const applicationData = {
            application: id,
            message,
            status: 25,
          };

          await this.changeAppStatus(applicationData);
          break;
        }
        case "object": {
          // refund
          await this.props.handleApplicationSubmissions(
            deferRefundValue,
            "",
            `${config.server}${config.api.applicationDocuments}`,
            4,
            this.props.history
          );

          const applicationData = {
            application: id,
            message: "",
            status: 12,
          };
          await this.changeAppStatus(applicationData);
          break;
        }
        default: {
          console.log("default case", typeof deferRefundValue);
        }
      }
    } else {
      //Show the popup
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, withdraw it!",
      }).then(result => {
        if (result.value) {
          // Send the delete request

          const response = this.deleteApplication(
            this.props.application.currentApplication.id
          );

          if (response) {
            Swal.fire(
              "Deleted!",
              "Your application has been deleted!",
              "success"
            ).then(success => {
              this.props.history.push("/applications");
            });
          } else {
            Swal.fire(
              "Error!",
              "Something went wrong, Please contact support.",
              "warning"
            );
          }
        }
      });
    }
  }

  // Handle the comment change
  handleCommentChange(e) {
    this.setState({ comment: e.target.value });
  }

  // Hanlde the view aprroved button
  async visaApproved(e) {
    const { id } = this.props.application.currentApplication;

    const fileData = await SwalVisaStatus(id);

    switch (typeof fileData) {
      case "object": {
        await this.props.handleApplicationSubmissions(
          fileData,
          "",
          `${config.server}${config.api.applicationDocuments}`,
          4,
          this.props.history
        );

        const applicationData = {
          application: id,
          message: "",
          status: 11,
        };
        await this.changeAppStatus(applicationData);

        break;
      }
      default: {
        break;
      }
    }
  }

  // Function to handle on comment
  async handleOnComment(e) {
    if (e.key === "Enter") {
      // Form the application data
      const applicationID = this.props.application.currentApplication.id;
      const commentData = {
        application: applicationID,
        comment: this.state.comment,
      };

      // Send the comment to action
      this.props.handleApplicationSubmissions(
        commentData,
        "",
        `${config.server}${config.api.applicationComments}`,
        5,
        this.props.history,
        applicationID
      );
      // Check for any errors and clear comment box
      isEmpty(this.state.errors) ? this.setState({ comment: "" }) : "";
    }
  }

  // Function to send offer letter
  sendOffer(application, type) {
    // Decalre the variables
    let title;

    // Set the message as per the documents type
    if (type == 7) {
      // Conditional offer letter
      title = "Send Conditional Offer letter?";
    } else if (type == 6) {
      // final offter letter
      title = "Send LOA?";
    } else {
      // Payment receipt
      title = "Send Payment Receipt?";
    }

    Swal.fire({
      title: title,
      text: "You won't be able to revert this!",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Send it",
    }).then(result => {
      if (result.value) {
        //Set the data
        const finalData = {
          application: application,
          file: type,
        };

        // Send the request
        // TODO: This is not suppose to be localhost
        axios
          .post(`${config.server}${config.api.autodoc}`, finalData)
          .then(result => {
            Swal.fire({
              title: "Sent!",
              text: "Offer letter is sent to the agent.",
              type: "success",
              confirmButtonColor: "#298eea",
              cancelButtonColor: "#d33",
              confirmButtonText: "View Document",
            }).then(success => {
              if (success.dismiss != "overlay")
                window.open(
                  `http://localhost:5000/storage/documents/${
                    result.data.code
                  }.pdf`,
                  "_blank"
                );
              window.location.reload();
            });
          })
          .catch(error => {
            Swal.fire(
              "Error!",
              "Something went wrong, Please contact support.",
              "warning"
            );
          });
      }
    });
  }

  // Function to add send button for offer letters, and recepits button
  offerLetter(application) {
    if (!isEmpty(application)) {
      const result = [];

      // Button for the conditional offer letter
      if (
        this.props.user.permission != 1 &&
        application.status == 5 &&
        application.ApplicationDocuments.findIndex(x => x.documentType == 7) ===
          -1
      ) {
        result.push(
          <Button
            onClick={() => this.sendOffer(application.id, 7)}
            color="orange"
            size="sm"
            className="ml-2"
            icon="navigation"
          >
            Send Letter
          </Button>
        );
      }

      // Button for the wire transfer receipt
      if (
        this.props.user.permission != 1 &&
        application.status == 10 &&
        application.ApplicationDocuments.findIndex(x => x.documentType == 8) ===
          -1
      ) {
        result.push(
          <Button
            onClick={() => this.sendOffer(application.id, 8)}
            color="cyan"
            size="sm"
            className="ml-2"
            icon="navigation"
          >
            Send Receipt
          </Button>
        );
      }

      return result;
    }
  }

  // Function to add status button
  statusButton() {
    if (this.props.user.permission != 1) {
      return (
        <Button
          onClick={this.handleUpdate.bind(this)}
          color="purple"
          size="sm"
          className="ml-2"
        >
          Update Status
        </Button>
      );
    } else if (
      this.props.application.currentApplication.status === 5 ||
      this.props.application.currentApplication.status === 29
    ) {
      return (
        <Button
          size="sm"
          color="purple"
          className="ml-2"
          onClick={this.visaApproved.bind(this)}
        >
          Visa Approved
        </Button>
      );
    }
  }

  // Function to show deffer button to agents
  defferButton() {
    const application = this.props.application.currentApplication;
    // Check the user status
    if (
      (application.status === 4 ||
        application.status === 5 ||
        application.status === 29) &&
      this.props.user.permission === 1
    ) {
      return (
        <Button
          size="sm"
          color="orange"
          className="ml-2"
          onClick={this.deferRequest}
        >
          Request Defer
        </Button>
      );
    }
  }

  renderAgent() {
    const permission = this.props.auth.user.permission;

    if (permission === 0) {
      return (
        <Table.Row>
          <Table.Col>Agent</Table.Col>
          <Table.Col>
            <Form.Group className="cardInput mb-1">
              <Form.Select onChange={this.agentSwitch}>
                <option selected value={this.state.initialAgentId}>
                  {this.state.initialAgentName}
                </option>
                {termsList(this.state.initialList)}
              </Form.Select>
            </Form.Group>
          </Table.Col>
        </Table.Row>
      );
    } else if (permission === 1) {
      return;
    } else {
      return (
        <Table.Row>
          <Table.Col>Agent</Table.Col>
          <Table.Col>{this.state.initialAgentName}</Table.Col>
        </Table.Row>
      );
    }
  }

  render() {
    // Get the application data
    const { currentApplication } = this.props.application;

    // Get the profile score
    let scoreColor = "blue";
    if (currentApplication.profileScore < 60) {
      scoreColor = "red";
    } else if (currentApplication.profileScore >= 60) {
      scoreColor = "blue";
    } else if (currentApplication.profileScore >= 80) {
      scoreColor = "green";
    }

    return (
      <SiteWrapper>
        <Container className="defaultMargin">
          <Grid.Row cards>
            <Grid.Col lg={7} className="">
              <Card>
                <Card.Header>
                  <Card.Title>
                    <span className="appTitle">Application Information</span>
                    <Avatar color={scoreColor}>
                      {currentApplication.profileScore}
                    </Avatar>
                  </Card.Title>
                  <Card.Options>
                    <Button color="azure" size="sm" onClick={this.handleEdit}>
                      Edit
                    </Button>
                    <Button
                      onClick={this.handleWithdraw.bind(this)}
                      color="pink"
                      size="sm"
                      className="ml-2"
                    >
                      withdraw
                    </Button>
                    {this.statusButton()}
                    {/* {this.defferButton()} */}
                    <Button
                      onClick={this.clearNotifications}
                      color="primary"
                      size="sm"
                      className="ml-2"
                    >
                      Clear Notifications
                    </Button>
                    {this.offerLetter(currentApplication)}
                  </Card.Options>
                </Card.Header>

                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter"
                >
                  <Table.Body>
                    {contentView(currentApplication)}
                    {this.renderAgent()}
                  </Table.Body>
                </Table>
              </Card>

              <Card>
                <Card.Header>
                  <Card.Title>English Test</Card.Title>
                </Card.Header>
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter"
                >
                  <Table.Body>
                    {contentView(
                      currentApplication.ApplicationLanguage,
                      "language"
                    )}
                  </Table.Body>
                </Table>
              </Card>

              {currentApplication?.ApplicationAcademics?.length > 0 &&
                currentApplication.ApplicationAcademics.map(item => (
                  <Card>
                    <Card.Header>
                      <Card.Title>Academics</Card.Title>
                    </Card.Header>
                    <Table
                      cards={true}
                      striped={true}
                      responsive={true}
                      className="table-vcenter"
                    >
                      <Table.Body>{contentView(item, "academics")}</Table.Body>
                    </Table>
                  </Card>
                ))}
            </Grid.Col>

            <Grid.Col lg={5} md={6}>
              <Card
                title="Application timeline"
                options={
                  <Stamp
                    className={`bg-${
                      applicationStatus(currentApplication.status).statusColor
                    }`}
                  >
                    {applicationStatus(currentApplication.status)
                      .statusText.substring(0, 2)
                      .toUpperCase()}
                  </Stamp>
                }
              >
                <Card.Body>
                  <Timeline>
                    {this.props.application.currentApplication.applicationLogs
                      ? this.props.application.currentApplication.applicationLogs.map(
                          comment => {
                            let message = false;
                            let title = comment.message;
                            let badgeColor = "blue";

                            if (comment.code == 2310) {
                              message = comment.message;
                              title = comment.User.name;
                            } else if (comment.code == 2301) {
                              // Set the status color
                              const appStatus = applicationStatus(
                                comment.status
                              );
                              badgeColor = appStatus.statusColor;
                              title = appStatus.statusText;
                              message = comment.message;
                            } else if (comment.code === 2303) {
                              title = "Agent changed";
                              message = comment.message;
                            } else if (comment.code == 2305) {
                              title = comment.User.name;
                              const doc = JSON.parse(comment.message);
                              message = `Uploaded a new file - ${doc.name}`;
                            } else if (comment.code == 2322) {
                              badgeColor = "red";
                            } else if (comment.code == 2306) {
                              title = "Program change: " + comment.User.name;
                              message = comment.message;
                            }
                            // Get the date
                            const date = new Date(
                              comment.createdAt
                            ).toDateString();
                            return (
                              <Timeline.Item
                                title={title}
                                badgeColor={badgeColor}
                                //time={ta.ago(comment.createdAt)}
                                time={date}
                                active={message}
                                description={message}
                              />
                            );
                          }
                        )
                      : ""}
                  </Timeline>

                  <Form.Group label="Add comment">
                    <Form.Input
                      className="mb-3"
                      icon="send"
                      onChange={this.handleCommentChange.bind(this)}
                      placeholder="Write a comment here..."
                      value={this.state.comment}
                      feedback={
                        isEmpty(this.state.errors.comment)
                          ? ""
                          : this.state.errors.comment
                      }
                      invalid={
                        isEmpty(this.state.errors.comment) ? false : true
                      }
                      position="append"
                      onKeyPress={this.handleOnComment.bind(this)}
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
              <Card
                title="Application documents"
                RootComponent={Form}
                footer={
                  <div className="d-flex">
                    <Button
                      onClick={e => {
                        this.handleEdit(e, "/application/documents");
                      }}
                      type="submit"
                      color="primary"
                      className="ml-auto"
                    >
                      Attach documents
                    </Button>
                  </div>
                }
              >
                {renderFor([0, 1, 2], true, this.props.user.permission) ? (
                  <Card.Body>
                    <Table
                      cards={true}
                      striped={true}
                      responsive={true}
                      className="table-vcenter"
                    >
                      <Table.Body>
                        {this.listFiles(
                          currentApplication.ApplicationDocuments,
                          false
                        )}
                      </Table.Body>
                    </Table>
                  </Card.Body>
                ) : null}
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </SiteWrapper>
    );
  }
}

View.propTypes = {
  getCurrentApplication: PropTypes.func.isRequired,
  setCurrentApplication: PropTypes.func.isRequired,
  handleApplicationSubmissions: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

// Set state to properties
const mapStateToProps = state => ({
  application: state.application,
  user: state.auth.user,
  errors: state.errors,
  auth: state.auth,
  terms: state.terms,
});

// Connect the component with redux state
export default connect(
  mapStateToProps,
  {
    getCurrentApplication,
    setCurrentApplication,
    handleApplicationSubmissions,
    updateApplicationStatus,
    updateApplicationAgent,
    userList,
  }
)(View);
