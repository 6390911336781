import React, { useEffect } from "react";
import {
  Anchor,
  Box,
  Button,
  Center,
  Image,
  Paper,
  PasswordInput,
  Stack,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { userLogin2 } from "../actions/authActions";
import { connect } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import config from "../config/config";
import { useGetMyInfo } from "../hooks/useGetMyInfo";

const initialValues = {
  email: "",
  password: "",
};

const Login = props => {
  const history = useHistory();

  const { refetch } = useGetMyInfo();

  const form = useForm({
    initialValues,
  });

  useEffect(() => {
    if (props.auth.isAuthenticated) {
      history.push("/applications");
    }
  }, []);

  const handleSubmit = async values => {
    try {
      const loginResp = await axios.post(
        `${config.server}${config.api.userLogin}`,
        {
          email: values.email,
          password: values.password,
        }
      );

      if (loginResp.data.success) {
        await props.userLogin2(loginResp.data.token);
        await refetch();
        history.push("/applications");
      }
    } catch (error) {
      form.setErrors(error.response.data);
    }
  };

  return (
    <Center h="100vh">
      <Paper shadow="xs" px="md" py="xl" maw={"400px"} miw="400px">
        <Center>
          <Stack>
            <Image src="/assets/logo2.png" className="mainLogo" maw="300px" />
            <form onSubmit={form.onSubmit(values => handleSubmit(values))}>
              <TextInput
                label="Email Address"
                {...form.getInputProps("email")}
              />
              <PasswordInput
                label="Password"
                {...form.getInputProps("password")}
              />
              <Box>
                <Anchor href="/forgot-password" size="xs">
                  Forgot Password
                </Anchor>
              </Box>
              <Button mt={"md"} type="submit">
                Login
              </Button>
            </form>
          </Stack>
        </Center>
      </Paper>
    </Center>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { userLogin2 }
)(Login);
