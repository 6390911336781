import  {  GET_TERMS, GET_ERRORS } from "./types";
import axios from "axios";
import config from "../config/config.js"

// Get the list of the terms 
export const getTerms = () => async dispatch => {
  // Send the get request to backend server
  await axios
    .get(`${config.server}${config.api.termsGet}`)
    .then(res => {
      // Return the data to the reducer
      return dispatch({
        type: GET_TERMS,
        playload: res.data,
      });
    })
    .catch(err => {
      return dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      })
    });
};

// Add new term to database
export const setTerms = (termData) => async dispatch => {

  // Send post request to database to add a new term
  await axios.post(`${config.server}${config.api.termsSet}`, termData)
  .then(res => {
      // Return the data to the reducer
      return dispatch({
        type: GET_TERMS,
        playload: res.data,
      });
    })
    .catch(err => {
      return dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      })
    });

}