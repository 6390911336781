import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Form, Button } from "tabler-react";
import isEmpty from "../../utils/is-empty";

class Announcements extends React.Component {

    // Constructor function
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <Modal isOpen={this.props.announcementModal} toggle={this.props.toggle}>
            <ModalBody>
            <Form.Group label={
            <Form.Label>Message to Agents</Form.Label>}>
            <Form.Textarea
              name="message"
              onChange={this.props.onChange}
              placeholder="Message to agent goes here"
              rows={2}
              feedback={
                isEmpty(this.props.errors.announcement)
                  ? ""
                  : this.props.errors.announcement
              }
              invalid={isEmpty(this.props.errors.announcement) ? false : true}
            />
          </Form.Group>
            </ModalBody>
            <Button
              color="primary"
              onClick={this.props.onClick}
            >
             Make New Announcement
            </Button>{" "}
        </Modal>
        )
    }

}

export default Announcements;