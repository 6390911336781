/* eslint-disable no-unused-expressions */
import {
  GET_ERRORS,
  CURRENT_APP_STEP1,
  CURRENT_APP_STEP2,
  CURRENT_APP_STEP3,
  CURRENT_APP_STEP4,
  GET_ALL_APPLICATIONS,
  GET_CURRENT_APPLICATION,
  SET_CURRENT_APPLICATION,
  APPLICATION_COMMENT,
  APP_STATUS,
  APP_AGENT,
} from "./types";
import axios from "axios";
import isEmpty from "../utils/is-empty";
import config from "../config/config.js";

// Get the list of the applications
export const applicationsList = (
  page,
  searchStatus,
  searchTerm,
  searchQuery,
  searchType,
  searchAgent,
  searchDate,
  searchProgram,
  diversity
) => dispatch => {
  // Send the get request to backend server
  console.log(searchProgram);
  axios
    .get(
      `${config.server}${
        config.api.applicationList
      }/?page=${page}&searchStatus=${searchStatus}&searchTerm=${searchTerm}&searchAgent=${searchAgent}&searchDate=${searchDate}&searchProgram=${searchProgram}&${searchType}=${searchQuery}&diversity=${diversity}`
    )
    .then(res => {
      // Return the data to the reducer
      dispatch({
        type: GET_ALL_APPLICATIONS,
        playload: res.data,
      });
    })
    .catch(err => console.log(err));
};

// Set the current application
export const setCurrentApplication = (
  status,
  currentApplicationID
) => dispatch => {
  // Set the current application edit status
  return dispatch({
    type: SET_CURRENT_APPLICATION,
    playload: { status: status, id: currentApplicationID },
  });
};

// Get the current application
export const getCurrentApplication = passport => async dispatch => {
  // Send request to the backend server
  return await axios
    .get(`${config.server}${config.api.applicationSingle}${passport}`)
    .then(res => {
      // return the data to the reducer
      dispatch({
        type: GET_CURRENT_APPLICATION,
        playload: res.data,
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      })
    );
};

// Change the application status
export const updateApplicationStatus = applicationData => async dispatch => {
  // Set the current application status
  await axios
    .post(`${config.server}${config.api.applicationStatus}`, applicationData)
    .then(res => {
      dispatch({
        type: APP_STATUS,
        playload: applicationData.status,
      });
      dispatch({
        type: GET_ERRORS,
        playload: {},
      });
    })
    .catch(err => {
      return dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      });
    });
};

export const updateApplicationAgent = agentData => async dispatch => {
  await axios
    .post(`${config.server}${config.api.applicationAgent}`, agentData)
    .then(res => {
      dispatch({
        type: APP_AGENT,
        playload: agentData.agent,
      });
      dispatch({
        type: GET_ERRORS,
        playload: {},
      });
    })
    .catch(err => {
      return dispatch({
        type: GET_ERRORS,
        playload: err.reponse.data,
      });
    });
};

// Handle the application submission
export const handleApplicationSubmissions = (
  applicationData,
  redirectURL,
  requestURL,
  step,
  history,
  applicationID
) => async dispatch => {
  let headers = {};

  // Set the current step
  if (step == 1) {
    step = CURRENT_APP_STEP1;
  } else if (step == 2) {
    step = CURRENT_APP_STEP2;
  } else if (step == 3) {
    step = CURRENT_APP_STEP3;
  } else if (step == 4) {
    step = CURRENT_APP_STEP4;
  } else if ((step = 5)) {
    step = APPLICATION_COMMENT;
  }

  // Send request to backEnd
  await axios
    .post(requestURL, applicationData, headers)
    .then(res => {
      if (!isEmpty(applicationID)) {
        res.data.id = applicationID;
      }

      dispatch({
        type: step,
        playload: res.data,
      });
      dispatch({
        type: GET_ERRORS,
        playload: {},
      });

      isEmpty(redirectURL) ? "" : history.push(redirectURL);
    })
    .catch(err => {
      return dispatch({
        type: GET_ERRORS,
        playload: err.response.data,
      });
    });
};
