import React, { Fragment, useState } from "react";
import SiteWrapper from "../../SiteWrapper.react";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  Paper,
  ScrollArea,
  Select,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
  Timeline,
  Title,
} from "@mantine/core";
import { useParams, useHistory } from "react-router-dom";
import {
  useAgentAppComment,
  useAgentAppSubmit,
  useSingleAgentApp,
} from "../../hooks/useAgentApp";
import {
  IconCheck,
  IconCirclePlus,
  IconCircleX,
  IconDownload,
  IconFile,
  IconInfoCircle,
  IconMessage2,
  IconReplace,
  IconTrash,
  IconUser,
} from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { APP_STATUS, applicationStatus } from "../../utils/applicationStatus";
import { useDisclosure } from "@mantine/hooks";
import { renderFor } from "../../utils/renderComp";
import queryClient from "../../lib/queryClient";
import { DateInput } from "@mantine/dates";
import TitledPaper from "../../components/common/TitledPaper";
import dayjs from "dayjs";
import RenderIcon from "../ApplicationForm/RenderIcon";
import { openInNewTab } from "../../utils/downloadFile2";

/**
 * @typedef AgentApplication
 * @property {number} id - Application ID
 * @property {number} status - Application Status
 * @property {string} user - Agent's uuid
 * @property {string} companyName
 * @property {string} website
 * @property {string} email
 * @property {string} phoneNumber
 * @property {string} licenseNo
 * @property {string} timeOperating
 * @property {string} address
 * @property {string} branchesNLocation
 * @property {string} services
 * @property {string} recruitCountries
 * @property {string} ownerName
 * @property {string} ownerEmail
 * @property {string} ownerPhone
 * @property {Object[]} staff
 * @property {string} staff.name
 * @property {string} staff.email
 * @property {string} staff.title
 * @property {number} studentsPerIntake
 * @property {number} visasLastYear
 * @property {number} studentsToSend
 * @property {string} currentCEI
 * @property {string} repName
 * @property {Date} createdAt
 * @property {Date} updatedAt
 * @property {number | null} level
 * @property {Object[]} references
 * @property {Object} references.institution
 * @property {Object} references.contact
 * @property {Object} references.position
 * @property {Object} references.phone
 * @property {Object} references.email
 * @property {Date} startDate
 * @property {Date} expireDate
 * @property {string} assignedTo
 * @property {Object[]} AgentApplicationLogs
 */

const statusSelectOptions = [
  APP_STATUS.AG_UNDER_REVIEW,
  APP_STATUS.AG_REQUIRE_CHANGES,
  APP_STATUS.AG_SIGNED,
  APP_STATUS.AG_PARTNER,
  APP_STATUS.AG_TERMINATED,
  APP_STATUS.AG_EXPIRED,
  APP_STATUS.AG_ON_HOLD,
];

const AgentAppViewV2 = () => {
  const myInfo = queryClient.getQueryData("myInfo");
  const { id } = useParams();
  const history = useHistory();

  const [comment, setComment] = useState("");
  const { mutate: addComment } = useAgentAppComment();

  const [eStatus, setEStatus] = useState();
  const [openedStatus, { open: openS, close: closeS }] = useDisclosure(false);

  const editApp = async () => {
    // for agents, they can only edit the application when the status is 0,3,45
    // and they are either an applying agent or regular agent
    if (myInfo?.permission === 1 || myInfo?.permission === 5) {
      if ([0, 1, 3, 42, 45].indexOf(data.status) >= 0) {
        history.push(`/agentapp/submit/${id}`);
      } else {
        return alert("Cannot edit application");
      }
      // for agents and super admins, they can edit whenever
    } else if (renderFor([0, 2], true, myInfo?.permission)) {
      history.push(`/agentapp/submit/${id}`);
    }
  };

  /** @type {{data: AgentApplication, isLoading: boolean }} */
  const { data, isLoading } = useSingleAgentApp(id);

  const { mutate, mutateAsync } = useAgentAppSubmit();

  const changeStatus = () => {
    mutateAsync({ ...data, status: eStatus });
    notifications.show({
      title: "Status changed!",
      color: "teal",
      icon: <IconCheck />,
    });
    closeS();
  };

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <SiteWrapper>
      <Container size="xl" pt="xl">
        <Center>
          <Modal opened={openedStatus} onClose={closeS} title="Change Status">
            <>
              <Select
                data={statusSelectOptions}
                value={eStatus}
                onChange={setEStatus}
                withinPortal={true}
              />
              <Group position="right" mt="md">
                <Button onClick={closeS} color="red">
                  Cancel
                </Button>
                <Button type="submit" onClick={changeStatus}>
                  Change
                </Button>
              </Group>
            </>
          </Modal>

          <Box>
            <Flex gap="md">
              <Box
                sx={{
                  flex: "3",
                }}
              >
                <Stack pb="lg">
                  <Paper shadow="xs" px="md" py="xl">
                    <Title size="h3">
                      <Flex
                        sx={{
                          justifyContent: "space-between",
                        }}
                      >
                        Application Information
                        <Flex>
                          <Button
                            size="xs"
                            color="cyan"
                            onClick={() => editApp()}
                            mr="sm"
                          >
                            Edit
                          </Button>
                          {renderFor([0, 2], true, myInfo?.permission) && (
                            <Button size="xs" color="violet" onClick={openS}>
                              Edit Status
                            </Button>
                          )}
                        </Flex>
                      </Flex>
                    </Title>
                    <Divider my="lg" />
                    <Grid>
                      <Grid.Col span={3}>Status</Grid.Col>
                      <Grid.Col span={9}>
                        <Badge
                          color={
                            Object.entries(APP_STATUS).filter(
                              e => e.value === data.status
                            )[0]?.color
                          }
                        >
                          {applicationStatus(data.status).statusText}
                        </Badge>
                      </Grid.Col>
                      {renderFor([0, 2], true, myInfo?.permission) && (
                        <>
                          <StaffOnlyInfo agentData={data} mutate={mutate} />
                        </>
                      )}
                    </Grid>
                  </Paper>
                  <CompanyInfo agentData={data} />
                  <OwnerInfo agentData={data} />
                  <StaffInfo staffData={data.staff} />
                  <AdditionalInfo agentData={data} />
                </Stack>
              </Box>
              <Box
                sx={{
                  flex: "2",
                }}
              >
                {renderFor([0, 2], true, myInfo?.permission) && (
                  <>
                    <AppTimeline
                      id={data.id}
                      data={data.AgentApplicationLogs}
                      addComment={addComment}
                      comment={comment}
                      setComment={setComment}
                      status={data.status}
                    />
                    <AppFiles data={data.ApplicationDocuments} />
                  </>
                )}
                {/* <AppFiles data={data.ApplicationDocuments} /> */}
              </Box>
            </Flex>
          </Box>
        </Center>
      </Container>
    </SiteWrapper>
  );
};

/**
 * @param {Object} data
 * @param {AgentApplication} data.agentData
 * @param {() => void} data.mutate
 * @returns {React.FC}
 */
const StaffOnlyInfo = ({ agentData, mutate }) => {
  return (
    <>
      <Grid.Col span={3}>Level</Grid.Col>
      <Grid.Col span={9}>
        <Select
          data={[
            { label: "0", value: "0" },
            { label: "1", value: "1" },
            { value: "2", label: "2" },
            { label: "3", value: "3" },
            { label: "4", value: "4" },
            { label: "5", value: "5" },
          ]}
          value={agentData?.level?.toString()}
        />
      </Grid.Col>
      <Grid.Col span={3}>Start Date</Grid.Col>
      <Grid.Col span={9}>
        <Flex>
          <DateInput
            firstDayOfWeek="monday"
            value={new Date(agentData?.startDate)}
            onChange={e => mutate({ ...agentData, startDate: e })}
            clearable={false}
            mr="md"
          />
        </Flex>
      </Grid.Col>
      <Grid.Col span={3}>Expire Date</Grid.Col>
      <Grid.Col span={9}>
        <Flex>
          <DateInput
            firstDayOfWeek="monday"
            value={new Date(agentData?.expireDate)}
            onChange={e => mutate({ ...agentData, expireDate: e })}
            clearable={false}
            mr="md"
          />
        </Flex>
      </Grid.Col>
      <Grid.Col span={3}>Signed with</Grid.Col>
      <Grid.Col span={9}>
        <Flex>
          <Checkbox
            label="TBC"
            mr="md"
            checked={agentData.tbc}
            onChange={event => {
              mutate({ ...agentData, tbc: event.currentTarget.checked });
            }}
          />
          <Checkbox
            label="Loyalist"
            checked={agentData.loyalist}
            onChange={event => {
              mutate({ ...agentData, loyalist: event.currentTarget.checked });
            }}
          />
        </Flex>
      </Grid.Col>
    </>
  );
};

/**
 * @param {Object} data
 * @param {AgentApplication} data.agentData
 * @returns {React.FC}
 */
const CompanyInfo = ({ agentData }) => {
  return (
    <TitledPaper title="Company information">
      <Grid>
        <Grid.Col span={3}>Name of Company</Grid.Col>
        <Grid.Col span={9}>{agentData.companyName}</Grid.Col>
        <Grid.Col span={3}>Website</Grid.Col>
        <Grid.Col span={9}>{agentData.website}</Grid.Col>
        <Grid.Col span={3}>Email Address</Grid.Col>
        <Grid.Col span={9}>{agentData.email}</Grid.Col>
        <Grid.Col span={3}>Phone Number</Grid.Col>
        <Grid.Col span={9}>{agentData.phoneNumber}</Grid.Col>
        <Grid.Col span={3}>License / Registration No.</Grid.Col>
        <Grid.Col span={9}>{agentData.licenseNo}</Grid.Col>
        <Grid.Col span={3}>How long have you been operating?</Grid.Col>
        <Grid.Col span={9}>{agentData.timeOperating}</Grid.Col>
        <Grid.Col span={3}>Complete Address (Head office)</Grid.Col>
        <Grid.Col span={9}>{agentData.address}</Grid.Col>
        <Grid.Col span={3}>
          How many branches you have and in which cities/countries
        </Grid.Col>
        <Grid.Col span={9}>{agentData.branchesNLocation}</Grid.Col>
        <Grid.Col span={3}>List the services that you provide</Grid.Col>
        <Grid.Col span={9}>{agentData.services}</Grid.Col>
        <Grid.Col span={3}>
          From which countries do you recruit students?
        </Grid.Col>
        <Grid.Col span={9}>{agentData.recruitCountries}</Grid.Col>
      </Grid>
    </TitledPaper>
  );
};

/**
 * @param {Object} data
 * @param {AgentApplication} data.agentData
 * @returns {React.FC}
 */
const OwnerInfo = ({ agentData }) => {
  return (
    <TitledPaper title="Owner's Information">
      <Grid>
        <Grid.Col span={3}>Name</Grid.Col>
        <Grid.Col span={9}>{agentData.ownerName}</Grid.Col>
        <Grid.Col span={3}>Email Address</Grid.Col>
        <Grid.Col span={9}>{agentData.email}</Grid.Col>
        <Grid.Col span={3}>Contact Number</Grid.Col>
        <Grid.Col span={9}>{agentData.ownerPhone}</Grid.Col>
      </Grid>
    </TitledPaper>
  );
};

/**
 * @typedef {Object[]} staffData
 * @property {string} staffData.name
 * @property {string} staffData.email
 * @property {string} staffData.title
 *
 * @param {{staffData: staffData}} data
 */
const StaffInfo = ({ staffData }) => {
  return (
    <TitledPaper title="Staff Information">
      <Grid>
        {staffData?.map((staff, key) => (
          <>
            <Grid.Col>
              <strong>Staff #{key + 1}</strong>
            </Grid.Col>
            <Grid.Col span={3}>Name</Grid.Col>
            <Grid.Col span={9}>{staff.name}</Grid.Col>
            <Grid.Col span={3}>Email</Grid.Col>
            <Grid.Col span={9}>{staff.email}</Grid.Col>
            <Grid.Col span={3}>Title</Grid.Col>
            <Grid.Col span={9}>{staff.title}</Grid.Col>
          </>
        ))}
      </Grid>
    </TitledPaper>
  );
};

/**
 * @param {Object} data
 * @param {AgentApplication} data.agentData
 * @returns {React.FC}
 */
const AdditionalInfo = ({ agentData }) => {
  return (
    <TitledPaper title="Additional Information">
      <Grid>
        <Grid.Col span={3}>
          How many students do you recruit for Canadian Institutions per intake
          (On Average)?
        </Grid.Col>
        <Grid.Col span={9}>{agentData.studentsPerIntake}</Grid.Col>
        <Grid.Col span={3}>
          How many students received visa approval for Canada last year?
        </Grid.Col>
        <Grid.Col span={9}>{agentData.visasLastYear}</Grid.Col>
        <Grid.Col span={3}>
          How many students on an average do you anticipate sending to Loyalist
          College in Toronto?
        </Grid.Col>
        <Grid.Col span={9}>{agentData.studentsToSend}</Grid.Col>
        <Grid.Col span={3}>
          Canadian Education institutes you currently work with
        </Grid.Col>
        <Grid.Col span={9}>{agentData.currentCEI}</Grid.Col>
      </Grid>
    </TitledPaper>
  );
};

const AppTimeline = ({ id, data, addComment, comment, setComment }) => {
  const saveComment = () => {
    addComment({ id, message: comment });
    setComment("");
  };

  return (
    <Paper shadow="xs" px="md" py="xl" mb="xl">
      <Title size="h3">
        <Flex
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Box>Timeline</Box>
        </Flex>
      </Title>
      <Divider my="lg" />
      <Flex align="center" gap="sm">
        <Box sx={{ flex: "5" }}>
          <Textarea
            value={comment}
            onChange={evt => setComment(evt.currentTarget.value)}
            placeholder="Add a comment..."
            pb="md"
          />
        </Box>
        <Box sx={{ flex: "1" }}>
          <Button fullWidth={true} mb="sm" onClick={saveComment}>
            Send
          </Button>
        </Box>
      </Flex>
      <ScrollArea
        dir="ltr"
        offsetScrollbars={true}
        sx={{
          maxHeight: "500px",
          overflow: "auto",
        }}
      >
        {/*This 1px margin fixes bullets being cut off*/}
        <Timeline mt="1px" ml="1px" bulletSize={24} lineWidth={2} active={1}>
          {data.map((e, i) => {
            let newMessage = ContentGenerator(e);

            return (
              <Timeline.Item
                title={
                  <Flex sx={{ justifyContent: "space-between" }}>
                    <Text>
                      {e.code === 2310 && <>{e.User.name}</>}
                      {appLogs[`${e.code}`]?.title}
                    </Text>
                    <Text ml={"xs"} size="xs">
                      {dayjs(e.createdAt).format("YYYY-MM-DD")}
                    </Text>
                  </Flex>
                }
                bullet={appLogs[`${e.code}`]?.bullet}
                key={i}
              >
                <Text color="dimmed">{newMessage}</Text>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </ScrollArea>
    </Paper>
  );
};

const appLogs = {
  "2301": {
    // TODO: Change this to the actual status changes. Old status to new status
    title: "Application status change",
    bullet: (
      <ThemeIcon radius="xl" color="yellow">
        <IconInfoCircle />
      </ThemeIcon>
    ),
  },
  "2302": {
    title: "Application submitted",
    bullet: (
      <ThemeIcon radius="xl" color="teal">
        <IconCirclePlus />
      </ThemeIcon>
    ),
  },
  "2303": {
    title: "Agent changed",
    bullet: (
      <ThemeIcon radius="xl" color="teal">
        <IconUser size={20} />
      </ThemeIcon>
    ),
  },
  "2305": {
    title: "Document uploaded",
    bullet: (
      <ThemeIcon radius="xl" color="blue">
        <IconFile size={20} />
      </ThemeIcon>
    ),
  },
  "2306": {
    title: "Program changed",
    bullet: (
      <ThemeIcon radius="xl" color="blue">
        <IconReplace size={20} />
      </ThemeIcon>
    ),
  },
  "2310": {
    title: "",
    bullet: (
      <ThemeIcon radius="xl" color="teal">
        <IconMessage2 size={20} />
      </ThemeIcon>
    ),
  },
  "2323": {
    title: "Application deleted",
    bullet: (
      <ThemeIcon radius="xl" color="teal">
        <IconCircleX />
      </ThemeIcon>
    ),
  },
  "3323": {
    title: "Announcement changed",
    bullet: (
      <ThemeIcon radius="xl" color="teal">
        <IconCirclePlus />
      </ThemeIcon>
    ),
  },
  "4101": {
    title: "Status changed",
    bullet: (
      <ThemeIcon radius="xl" color="yellow">
        <IconInfoCircle />
      </ThemeIcon>
    ),
  },
  "4102": {
    title: "Application submitted",
    bullet: (
      <ThemeIcon radius="xl" color="teal">
        <IconCirclePlus />
      </ThemeIcon>
    ),
  },
  "4105": {
    title: "Document uploaded",
    bullet: (
      <ThemeIcon radius="xl" color="blue">
        <IconFile size={20} />
      </ThemeIcon>
    ),
  },
  "4107": {
    title: "Change made by agent",
    bullet: (
      <ThemeIcon radius="xl" color="yellow">
        <IconCirclePlus />
      </ThemeIcon>
    ),
  },
  "4108": {
    title: "Change made by staff",
    bullet: (
      <ThemeIcon radius="xl" color="yellow">
        <IconCirclePlus />
      </ThemeIcon>
    ),
  },
  "4106": {
    title: "Comment added",
    bullet: (
      <ThemeIcon radius="xl" color="teal">
        <IconMessage2 size={20} />
      </ThemeIcon>
    ),
  },
};

/**
 * @param {import("../../types").ApplicationLog} log
 */
const ContentGenerator = log => {
  const { code, message, User } = log;

  let newMessage = message;
  switch (code) {
    case 2301: {
      return newMessage;
    }
    case 2302: {
      newMessage = `${User.name} has submitted a new application`;
      return newMessage;
    }
    case 2305: {
      let tmp = JSON.parse(message);
      newMessage = `${User.name} uploaded a new file: ${tmp.name}`;
      return newMessage;
    }
    case 4106: {
      newMessage = `${User.name}: ${message}`;
    }
    default: {
      return newMessage;
    }
  }
};

/**
 * @param {Object} props
 * @param {import("../../types").ApplicationDocument[]} props.data
 * @return {JSX.Element}
 */
const AppFiles = ({ data }) => {
  return (
    <>
      <Paper shadow="xs" px="md" py="xl" mb="xl">
        <Title size="h3">Files</Title>
        <Divider my="lg" />
        <Grid>
          {data?.map((file, i) => (
            <Fragment key={i}>
              <Grid.Col span={10}>
                {<RenderIcon fileType={file?.extension} />}{" "}
                <strong>
                  {file.name}.{file.extension}
                </strong>
              </Grid.Col>
              <Grid.Col span={2}>
                <Flex>
                  <ActionIcon
                    variant="transparent"
                    onClick={evt => openInNewTab(evt, file)}
                  >
                    <IconDownload />
                  </ActionIcon>
                  {/* <ActionIcon variant="transparent"> */}
                  {/*   <IconTrash /> */}
                  {/* </ActionIcon> */}
                </Flex>
              </Grid.Col>
            </Fragment>
          ))}
        </Grid>
      </Paper>
    </>
  );
};

export default AgentAppViewV2;
