import { GET_TERMS } from "../actions/types";

const initialState = {
};


export default function(state = initialState, action) {

  // Get all the terms from database
  switch (action.type) {
    case GET_TERMS:
    	return action.playload
    default:
      return state;
  }

}
