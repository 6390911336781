// @flow

import React from "react";
import SiteWrapper from "../SiteWrapper.react";
import {
  programsList,
  addRequests,
  changeTermStatus,
  availability,
} from "../actions/programsActions";
import { getTerms } from "../actions/termActions";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import isEmpty from "../utils/is-empty";
import Swal from "sweetalert2";
import config from "../config/config.js";
import { termsList } from "../utils/contentView";
import { settingGet } from "../actions/settingsActins";

import {
  Page,
  Grid,
  Card,
  Table,
  Button,
  Tag,
  Form,
  Icon,
  Avatar,
} from "tabler-react";

class Terms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      termFormData: {},
      allPrograms: {},
      programs: {},
      terms: {},
      currentTerm: 0,
      errors: {},
    };

    this.toggleAdd = this.toggleAdd.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddTerm = this.handleAddTerm.bind(this);
    this.hanldeClose = this.hanldeClose.bind(this);
    this.termChange = this.termChange.bind(this);
  }

  // Set the errors state
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    this.setState({ programs: nextProps.programs.list });
    this.setState({ availablePrograms: nextProps.programs.available });
    this.setState({ terms: nextProps.terms });
  }

  async componentWillMount() {
    await this.props.settingGet();
    this.setState({ currentTerm: this.props.settings.term });
    // Get the list of the programs
    this.props.availability("?term=" + this.props.settings.term);
    this.props.programsList();
    // Get the list of the terms
    this.props.getTerms();
  }

  toggleAdd(currentProgram) {
    this.setState({
      addTermModal: !this.state.addTermModal,
    });
  }

  // Close all the moddals
  closeModals() {
    this.setState({
      addTermModal: false,
    });
  }

  // Handle add term
  handleAddTerm() {
    // Gather the data
    const data = {};
    data.term = this.state.termFormData.term || this.state.termFormData.term1;
    data.program = this.state.termFormData.program;
    data.start_date = this.state.termFormData.start_date;
    data.end_date = this.state.termFormData.end_date;
    data.LOA_date = this.state.termFormData.LOA_date;
    data.paymentDeadline = this.state.termFormData.paymentDeadline;
    data.termView = this.state.currentTerm;

    // Send Request to the server
    this.props.addRequests(data, `${config.server}${config.api.programsTerm}`);
  }

  // Hanlde the input changes
  onChange(e) {
    this.setState({
      termFormData: {
        ...this.state.termFormData,
        [e.target.name]: e.target.value,
      },
    });
  }

  // Function to change the term based on view
  async termChange(e) {
    let result = {};
    const programs = this.state.programs;

    // Set the new term value
    await this.setState({
      currentTerm: e.target.value,
    });

    // Filter the result based on the ter
    this.props.availability("?term=" + this.state.currentTerm);
  }

  // Handle the delete program
  handleDelete(id) {
    //Show the popup
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, withdraw it!",
    }).then(result => {
      if (result.value) {
        // Send the delete request
        const data = {};
        data.term = id;
        data.status = 0;
        const response = this.props.changeTermStatus(data);

        if (response) {
          Swal.fire("Deleted!", "Term has been removed.", "success");
        } else {
          Swal.fire(
            "Error",
            "There is some error, Please contact support.",
            "error"
          );
        }
      }
    });
  }

  // Handle the close term
  hanldeClose(id, currentStatus) {
    // Set the id and status
    const data = {};
    let status = 2;

    // Get the current status of the app
    if (currentStatus == 2) {
      status = 1;
    }

    data.term = id;
    data.status = status;

    // Send the request to backend
    this.props.changeTermStatus(data);
  }

  // Function to list the programs
  listTerms(availablePrograms) {
    // finalList array
    const finalList = [];

    Object.keys(availablePrograms).forEach(key => {
      const currentProgram = availablePrograms[`${key}`];
      const name = currentProgram["Program"]["name"];
      const code = currentProgram["Program"]["code"];
      const campus = currentProgram["Program"]["institution"];

      // Get the date
      let dateObject = new Date(Date.parse(currentProgram["createdAt"]));
      const date = dateObject.toDateString();

      // Get the other details
      const termName = currentProgram["Term"]["name"];
      const status = currentProgram["status"];
      const id = currentProgram["id"];

      finalList.push(
        <Table.Row>
          <Table.Col className="w-1">
            <Avatar color="pink">{code.substring(0, 2)}</Avatar>
          </Table.Col>

          <Table.Col>{code}</Table.Col>
          <Table.Col>{name}</Table.Col>
          <Table.Col>{campus}</Table.Col>
          <Table.Col className="text-nowrap">
            <Tag rounded>{termName}</Tag>
          </Table.Col>
          <Table.Col className="text-nowrap">
            <span
              className={`status-icon bg-${status == 2 ? "danger" : "success"}`}
            />
            {status == 2 ? "Close" : "Open"}
          </Table.Col>
          <Table.Col className="text-nowrap">{date}</Table.Col>
          {this.props.auth.user.permission == 0 ? (
            <Table.Col>
              <Button color="pink" size="sm">
                <Icon onClick={() => this.handleDelete(id)} name="trash" />
              </Button>
              <Form.Switch
                color="pink"
                onClick={() => {
                  this.hanldeClose(id, status);
                }}
                name={`toggle-${id}`}
                checked={status === 2 ? false : true}
                className="combineButtons"
              />
            </Table.Col>
          ) : (
            ""
          )}
        </Table.Row>
      );
    });

    //Return the final list
    return finalList;
  }

  //Empty view
  emptyView() {
    const message =
      this.props.auth.user.permission != 0 ? (
        <span>
          We don't have any terms open <br /> Please come back later!
        </span>
      ) : (
        <div>
          <span>
            You don't have any terms available
            <br /> Please click on the button below to add one.
          </span>
          <button className="btn btn-primary" onClick={this.toggleAdd}>
            Add program
          </button>
        </div>
      );
    return (
      <div className="notfound">
        <img src="/assets/icons/switch.png" />
        {message}
      </div>
    );
  }

  // Card view
  cardView() {
    return (
      <Card>
        <Card.Header>
          <Card.Title>Terms Available</Card.Title>
          <Card.Options>
            <Form.Select
              onChange={this.termChange}
              name="searchTerm"
              value={this.state.currentTerm}
              className="sm-select"
            >
              {termsList(this.props.terms)}
            </Form.Select>

            {this.props.auth.user.permission == 0 ? (
              <Button
                onClick={this.toggleAdd.bind(this)}
                color="purple"
                size="sm"
                className="ml-2"
              >
                New Term
              </Button>
            ) : (
              ""
            )}
          </Card.Options>
        </Card.Header>

        <Table
          cards={true}
          striped={true}
          responsive={true}
          className="table-vcenter"
        >
          <Table.Header>
            <Table.Row>
              <Table.ColHeader colSpan={2}>Program Code</Table.ColHeader>
              <Table.ColHeader>Program Name</Table.ColHeader>
              <Table.ColHeader>Campus</Table.ColHeader>
              <Table.ColHeader>Term available</Table.ColHeader>
              <Table.ColHeader>Status</Table.ColHeader>
              <Table.ColHeader>Date Added</Table.ColHeader>
              {this.props.auth.user.permission == 0 ? (
                <Table.ColHeader>Manage Options</Table.ColHeader>
              ) : (
                ""
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.listTerms(this.state.availablePrograms)}
          </Table.Body>
        </Table>
      </Card>
    );
  }

  render() {
    // Get the list of programs
    let programs = this.state.programs;

    // Extra the program name in a list view
    const programNames = [];
    Object.keys(programs).map(key => {
      programNames.push(
        <option value={programs[key]["id"]}>{programs[key]["institution"]} | {programs[key]["name"]}</option>
      );
    });

    return (
      <SiteWrapper>
        <Modal isOpen={this.state.addTermModal} toggle={this.toggleAdd}>
          <ModalHeader toggle={this.toggle}>Add new Term</ModalHeader>
          <ModalBody>
            <Form.Group label="Select a program">
              <Form.Select
                value={this.state.termFormData.id}
                name="program"
                feedback={
                  isEmpty(this.state.errors.id)
                    ? ""
                    : "Please select the program"
                }
                invalid={isEmpty(this.state.errors.id) ? false : true}
                onChange={this.onChange}
              >
                <option value="">Select a Program</option>
                {programNames}
              </Form.Select>
            </Form.Group>

            <Form.Group label="Program Start Date (YYYY-MM-DD)" isRequired>
              <Form.MaskedInput
                id="datePicker"
                value={this.state.termFormData.start_date}
                placeholder="0000-00-00"
                name="start_date"
                feedback={
                  isEmpty(this.state.errors.start_date)
                    ? ""
                    : this.state.errors.start_date
                }
                invalid={isEmpty(this.state.errors.start_date) ? false : true}
                onChange={this.onChange}
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                ]}
              />
            </Form.Group>

            <Form.Group label="Program End Date (YYYY-MM-DD)" isRequired>
              <Form.MaskedInput
                id="datePicker"
                value={this.state.termFormData.end_date}
                placeholder="0000-00-00"
                name="end_date"
                feedback={
                  isEmpty(this.state.errors.end_date)
                    ? ""
                    : this.state.errors.end_date
                }
                invalid={isEmpty(this.state.errors.end_date) ? false : true}
                onChange={this.onChange}
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                ]}
              />
            </Form.Group>

            <Form.Group label="LOA expire date (YYYY-MM-DD)" isRequired>
              <Form.MaskedInput
                id="datePicker"
                value={this.state.termFormData.LOA_date}
                placeholder="0000-00-00"
                name="LOA_date"
                feedback={
                  isEmpty(this.state.errors.LOA_date)
                    ? ""
                    : this.state.errors.LOA_date
                }
                invalid={isEmpty(this.state.errors.LOA_date) ? false : true}
                onChange={this.onChange}
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                ]}
              />
            </Form.Group>

            <Form.Group
              label="Payment Deadline for agents (YYYY-MM-DD)"
              isRequired
            >
              <Form.MaskedInput
                id="datePicker"
                value={this.state.termFormData.paymentDeadline}
                placeholder="0000-00-00"
                name="paymentDeadline"
                feedback={
                  isEmpty(this.state.errors.paymentDeadline)
                    ? ""
                    : this.state.errors.paymentDeadline
                }
                invalid={
                  isEmpty(this.state.errors.paymentDeadline) ? false : true
                }
                onChange={this.onChange}
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                ]}
              />
            </Form.Group>

            <Form.Group label="Select Term">
              <Form.Select
                value={this.state.termFormData.term}
                name="term"
                feedback={
                  isEmpty(this.state.errors.term) ? "" : this.state.errors.term
                }
                invalid={isEmpty(this.state.errors.term) ? false : true}
                onChange={this.onChange}
              >
                <option value="">Select the Term</option>
                {termsList(this.state.terms)}
              </Form.Select>
            </Form.Group>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleAddTerm}>
              Add Term
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleAdd}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Page.Content>
          <Grid.Row>
            <Grid.Col width={12}>
              {isEmpty(this.state.availablePrograms)
                ? this.emptyView()
                : this.cardView()}
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

// Map the state to props
const mapStateToProps = state => ({
  programs: state.programs,
  errors: state.errors,
  terms: state.terms,
  settings: state.settings,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {
    programsList,
    addRequests,
    changeTermStatus,
    getTerms,
    settingGet,
    availability,
  }
)(Terms);
