export const logToString = (code, user, message) => {
  switch (code) {
    case 2301:
      return message;

    case 2302:
      return "New application submitted";

    // case 2303:
    //   return

    case 2305:
      let idx = message.indexOf('"name"');
      message = message.slice(0, -1);
      return `${user.name} uploaded a file named ${message.substring(idx + 7)}`;
    default:
      return message;
  }
};
