import React from "react";
import { Form } from "tabler-react";

import countriesList from "../../utils/countriesList.js";
import isEmpty from "../../utils/is-empty";

class AcademicForm extends React.Component {
  render() {
    return (
      <div>
        <Form.Group label="Status" isRequired>
          <Form.Select
            name="type"
            value={this.props.formData.type}
            feedback={
              isEmpty(this.props.errors.type) ? "" : this.props.errors.type
            }
            invalid={isEmpty(this.props.errors.type) ? false : true}
            onChange={e => {
              this.props.onChange(e, this.props.currentForm);
            }}
          >
            <option selected={true} value={null} disabled>
              Select the status
            </option>
            <option
              selected={this.props.formData.type == 0 ? "selected" : ""}
              value="0"
            >
              Secondary / High School
            </option>
            <option
              selected={this.props.formData.type == 1 ? "selected" : ""}
              value="1"
            >
              College
            </option>
            <option
              selected={this.props.formData.type == 2 ? "selected" : ""}
              value="2"
            >
              University
            </option>
          </Form.Select>
        </Form.Group>

        <Form.Group label="Institute Name" isRequired>
          <Form.Input
            name="instituteName"
            value={this.props.formData.instituteName}
            feedback={
              isEmpty(this.props.errors.instituteName)
                ? ""
                : this.props.errors.instituteName
            }
            invalid={isEmpty(this.props.errors.instituteName) ? false : true}
            onChange={e => {
              this.props.onChange(e, this.props.currentForm);
            }}
          />
        </Form.Group>

        <Form.Group label="Status" isRequired>
          <Form.Select
            name="currentStatus"
            value={this.props.formData.currentStatus}
            feedback={
              isEmpty(this.props.errors.currentStatus)
                ? ""
                : this.props.errors.currentStatus
            }
            invalid={isEmpty(this.props.errors.currentStatus) ? false : true}
            onChange={e => {
              this.props.onChange(e, this.props.currentForm);
            }}
          >
            <option selected={true} value={null} disabled>
              Select the status
            </option>
            <option
              selected={
                this.props.formData.currentStatus == 0 ? "selected" : ""
              }
              value="0"
            >
              Completed
            </option>
            <option
              selected={
                this.props.formData.currentStatus == 1 ? "selected" : ""
              }
              value="1"
            >
              In progress
            </option>
            <option
              selected={
                this.props.formData.currentStatus == 2 ? "selected" : ""
              }
              value="2"
            >
              Not Completed
            </option>
          </Form.Select>
        </Form.Group>

        <Form.Group label="First year of Attendance" isRequired>
          <Form.MaskedInput
            name="firstYear"
            value={this.props.formData.firstYear}
            feedback={
              isEmpty(this.props.errors.firstYear)
                ? ""
                : this.props.errors.firstYear
            }
            invalid={isEmpty(this.props.errors.firstYear) ? false : true}
            onChange={e => {
              this.props.onChange(e, this.props.currentForm);
            }}
            placeholder="0000"
            mask={[/\d/, /\d/, /\d/, /\d/]}
          />
        </Form.Group>
        <Form.Group label="Last year of Attendance" isRequired>
          <Form.MaskedInput
            name="lastYear"
            value={this.props.formData.lastYear}
            feedback={
              isEmpty(this.props.errors.lastYear)
                ? ""
                : this.props.errors.lastYear
            }
            invalid={isEmpty(this.props.errors.lastYear) ? false : true}
            onChange={e => {
              this.props.onChange(e, this.props.currentForm);
            }}
            placeholder="0000"
            mask={[/\d/, /\d/, /\d/, /\d/]}
          />
        </Form.Group>

        <Form.Group label="Country" isRequired>
          <Form.Select
            name="country"
            value={this.props.formData.country}
            feedback={
              isEmpty(this.props.errors.country)
                ? ""
                : this.props.errors.country
            }
            invalid={isEmpty(this.props.errors.country) ? false : true}
            onChange={e => {
              this.props.onChange(e, this.props.currentForm);
            }}
          >
            {countriesList(this.props.formData.country)}
          </Form.Select>
        </Form.Group>

        <Form.Group label="Province" isRequired>
          <Form.Input
            name="province"
            value={this.props.formData.province}
            feedback={
              isEmpty(this.props.errors.province)
                ? ""
                : this.props.errors.province
            }
            invalid={isEmpty(this.props.errors.province) ? false : true}
            onChange={e => {
              this.props.onChange(e, this.props.currentForm);
            }}
          />
        </Form.Group>

        <Form.Group label="City" isRequired>
          <Form.Input
            name="city"
            value={this.props.formData.city}
            feedback={
              isEmpty(this.props.errors.city)
                ? ""
                : this.props.errors.city
            }
            invalid={isEmpty(this.props.errors.city) ? false : true}
            onChange={e => {
              this.props.onChange(e, this.props.currentForm);
            }}
          />
        </Form.Group>

        <Form.Group label="backlogs" isRequired>
          <Form.Input
            name="backlogs"
            type="number"
            value={this.props.formData.backlogs}
            feedback={
              isEmpty(this.props.errors.backlogs)
                ? ""
                : this.props.errors.backlogs
            }
            invalid={isEmpty(this.props.errors.backlogs) ? false : true}
            onChange={e => {
              this.props.onChange(e, this.props.currentForm);
            }}
          />
        </Form.Group>

        <Form.Group label="Bachelor's Percentage" isRequired>
          <Form.Input
            name="bachelorsPercentage"
            type="number"
            value={this.props.formData.bachelorsPercentage}
            feedback={
              isEmpty(this.props.errors.bachelorsPercentage)
                ? ""
                : this.props.errors.bachelorsPercentage
            }
            placeholder="ex. 86"
            invalid={isEmpty(this.props.errors.bachelorsPercentage) ? false : true}
            onChange={e => {
              this.props.onChange(e, this.props.currentForm);
            }}
          />
        </Form.Group>

        <hr />
      </div>
    );
  }
}

export default AcademicForm;
