import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  Input,
  Paper,
  Select,
  Stack,
  TextInput,
  Title,
} from "@mantine/core";
import { IMaskInput } from "react-imask";
import { countryList } from "../../utils/countriesList";
import { useId } from "@mantine/hooks";
import { useGetOpenPrograms } from "./api";
import { useGetAllTerms } from "../Applications/api";
import { useAppStore } from "../../lib/zustandStore";

export const institutionList = [
  { value: "Toronto Business College", label: "Toronto Business College" },
  {
    value: "Loyalist College – Belleville Campus",
    label: "Loyalist College – Belleville Campus",
  },
  {
    value: "Loyalist College – Port Hope Campus",
    label: "Loyalist College – Port Hope Campus",
  },
  {
    value: "Loyalist College – Toronto Campus",
    label: "Loyalist College – Toronto Campus",
  },
  {
    value: "Lambton College – Ottawa Campus",
    label: "Lambton College – Ottawa Campus",
  },
  {
    value: "Lambton College – Sarnia Campus",
    label: "Lambton College – Sarnia Campus",
  },
  {
    value: "Saint Paul University - Ottawa Campus",
    label: "Saint Paul University - Ottawa Campus",
  },
];

const statusInCanadaList = [
  "No Status in Canada",
  "In canada on the authority of another visa",
  "Refugee Status (i.e. Claimant)",
  "Study Permit / Student Visa",
  "Other",
];

const Step1 = ({ form }) => {
  const [institution, setInstitution] = useState("");
  const [term, setTerm] = useState("");
  const [termList, setTermList] = useState([{ label: "", value: "" }]);
  const [programList, setProgramList] = useState([{ label: "", value: "" }]);

  const isEdit = useAppStore(state => state.isEdit);

  const { data: termData } = useGetAllTerms();

  // For masked input DOB
  const id = useId();

  /** @type {{data: import('../../types').OpenProgram[], isLoading: boolean}} */
  const {
    data: programData,
    isLoading: programIsLoading,
  } = useGetOpenPrograms();

  // set initial values for state based on the application pulled from server
  useEffect(
    () => {
      // only set if it has not been set
      // this stops useEffect from making the state "stuck"
      if (!institution) {
        setInstitution(
          form?.values?.personal?.ProgramsAvailable?.Program?.institution
        );
      }
      if (!term) {
        setTerm(form?.values?.personal?.ProgramsAvailable?.term);
      }
    },
    [form]
  );

  // create list of programs based on selected campus/institution and term
  useEffect(
    () => {
      if (programData?.length > 0) {
        let temp = programData
          // .filter(e => e.Program.institution === institution && e.term === term)
          .filter(e => e.Program.institution === institution && e.term === term)
          .map(e => ({
            label: `${e.Program.name} - ${e.Term.name}`,
            value: e.id,
          }));
        setProgramList(temp);
      }
    },
    [institution, programData, term]
  );

  useEffect(
    () => {
      if (termData) {
        setTermList(termData?.map(e => ({ label: e.name, value: e.id })));
      }
    },
    [termData]
  );

  const handleInstitutionChange = (e = "", v = "") => {
    if (v === "") {
      setInstitution(e);
    }
    if (e === "") {
      setTerm(v);
    }

    form.setValues(values => ({
      ...values,
      personal: {
        ...values.personal,
        program: "",
        program_secondary: "",
      },
    }));
  };

  if (programIsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Stack>
        <Paper shadow="xs" px="md" py="xl">
          <Title size="h3">Applicant Profile Information</Title>
          <Divider my="lg" />
          <Grid columns={24}>
            <Grid.Col span={3}>
              <Select
                required
                label="Salutation"
                data={["Mr.", "Ms.", "Miss."]}
                {...form.getInputProps("personal.salutation")}
              />
            </Grid.Col>
            <Grid.Col span={7}>
              <TextInput
                required
                label="First Name"
                {...form.getInputProps("personal.firstName")}
                placeholder="John"
              />
            </Grid.Col>
            <Grid.Col span={7}>
              <TextInput
                label="Middle Name"
                {...form.getInputProps("personal.middleName")}
                placeholder="Smith"
              />
            </Grid.Col>
            <Grid.Col span={7}>
              <TextInput
                label="Last Name"
                {...form.getInputProps("personal.lastName")}
                placeholder="Baker"
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Select
                required
                {...form.getInputProps("personal.gender")}
                label="Gender"
                data={["Male", "Female"]}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Input.Wrapper required id={id} label="Date of Birth">
                <Input
                  {...form.getInputProps("personal.DOB")}
                  id={id}
                  component={IMaskInput}
                  mask="0000-00-00"
                  placeholder="YYYY-MM-DD"
                />
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                required
                label="Telephone"
                {...form.getInputProps("personal.telephone")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                required
                label="Email Address"
                placeholder="email@address.com"
                {...form.getInputProps("personal.email")}
              />
            </Grid.Col>
            <Grid.Col span={24}>
              <TextInput
                required
                label="Mailing Address"
                {...form.getInputProps("personal.MailingAddress")}
              />
            </Grid.Col>
            <Grid.Col span={24}>
              <TextInput
                label="Mailing Address (Line 2)"
                {...form.getInputProps("personal.MailingAddress2")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                required
                label="City"
                {...form.getInputProps("personal.city")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                required
                label="Postal Code"
                {...form.getInputProps("personal.postalCode")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                required
                label="Province/State"
                {...form.getInputProps("personal.province")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Select
                required
                searchable
                label="Country"
                data={countryList}
                {...form.getInputProps("personal.country")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Select
                required
                searchable
                label="Nationality"
                data={countryList}
                {...form.getInputProps("personal.nationality")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Select
                required
                searchable
                label="Status in Canada"
                data={statusInCanadaList}
                {...form.getInputProps("personal.status_in_canada")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                required
                label="Passport Number"
                placeholder="ZE9999999"
                {...form.getInputProps("personal.passport")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Input.Wrapper required id={id} label="Passport Expiry Date">
                <Input
                  {...form.getInputProps("personal.passportExpiry")}
                  id={id}
                  component={IMaskInput}
                  mask="0000-00-00"
                  placeholder="YYYY-MM-DD"
                />
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col span={24}>
              <Select
                label="Institution"
                nothingFound="No institutions found"
                name="institution"
                value={institution}
                data={institutionList}
                onChange={e => handleInstitutionChange(e, "")}
              />
            </Grid.Col>
            <Grid.Col span={24}>
              <Select
                label="Term"
                nothingFound="No open terms found"
                name="term"
                value={term}
                data={termList}
                onChange={v => handleInstitutionChange("", v)}
              />
            </Grid.Col>
            {isEdit ? (
              <>
                <Grid.Col span={24}>
                  <TextInput
                    required
                    disabled
                    label="Primary Program"
                    searchable
                    {...form.getInputProps(
                      "personal.ProgramsAvailable.Program.code"
                    )}
                    nothingFound="No programs found"
                  />
                </Grid.Col>
                <Grid.Col span={24}>
                  <TextInput
                    defaultValue=""
                    disabled
                    label="Secondary Program"
                    searchable
                    {...form.getInputProps("personal.program_secondary")}
                    nothingFound="No programs found"
                  />
                </Grid.Col>
              </>
            ) : (
              <>
                <Grid.Col span={24}>
                  <Select
                    required
                    label="Primary Program"
                    searchable
                    data={programList}
                    {...form.getInputProps("personal.program")}
                    nothingFound="No programs found"
                  />
                </Grid.Col>
                <Grid.Col span={24}>
                  <Select
                    defaultValue=""
                    label="Secondary Program"
                    searchable
                    data={programList}
                    {...form.getInputProps("personal.program_secondary")}
                    nothingFound="No programs found"
                  />
                </Grid.Col>
              </>
            )}
          </Grid>
        </Paper>

        <Paper shadow="xs" px="md" py="xl">
          <Title size="h3">Emergency Contact</Title>
          <Divider my="lg" />
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                required
                {...form.getInputProps("personal.name_emergency")}
                label="Name"
                placeholder="Albert Baker"
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                required
                label="Phone number"
                {...form.getInputProps("personal.phone_emergency")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                required
                {...form.getInputProps("personal.email_emergency")}
                label="Email address"
                placeholder="email@address.com"
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                required
                {...form.getInputProps("personal.relation_emergency")}
                label="Your relationship"
                placeholder="Father"
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Select
                required
                {...form.getInputProps("personal.english_emergency")}
                label="Is the Person fluent in English?"
                data={["Yes", "No"]}
              />
            </Grid.Col>
          </Grid>
        </Paper>
      </Stack>
    </>
  );
};

export default Step1;
