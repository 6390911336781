// @ts-check
// https://portal.edusolutions.ca/api/applications/21576
import config from "../../config/config";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import queryClient from "../../lib/queryClient";

/** @typedef  Step1Submit
 * @property {string} salutation
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} middleName
 * @property {string} MailingAddress
 * @property {string} MailingAddress2
 * @property {string} city
 * @property {string} postalCode
 * @property {string} telephone
 * @property {string} email
 * @property {string} province
 * @property {Date} DOB
 * @property {string} passport
 * @property {string} nationality
 * @property {string} country
 * @property {number} program
 * @property {string} [program_secondary]
 * @property {string} gender
 * @property {string} status_in_canada
 * @property {string} name_emergency
 * @property {string} phone_emergency
 * @property {string} email_emergency
 * @property {string} relation_emergency
 * @property {string} english_emergency
 * @property {Date} passportExpiry
 * @property {number} [status] - this only appears when action is done by admins
 * @property {boolean} editMode
 * */

/**
 * @param {number} id
 * @return {Promise<import("../../types").Application | null>}
 */
const fetchOneApplication = async id => {
  if (!id) return null;

  const res = await axios(
    `${config.server}${config.api.applicationSingle}${id}`
  );
  return res.data;
};

// See lazy query from react query docs
/**
 * @param {number | undefined} id
 */
export const useGetOneApplication = id =>
  // @ts-ignore
  useQuery(["application", id], () => fetchOneApplication(id), {
    // enabled: !!id,
  });

export const useFetchAllActive = () =>
  useQuery(["all-active"], async () => {
    const res = await axios(`${config.server}${config.api.allActive}`);

    return res.data;
  });

/**
 * @return {Promise<import("../../types").OpenProgram>}
 */
const fetchOpenPrograms = async () => {
  const res = await axios(`${config.server}/programs/current-open`);
  return res.data;
};

export const useGetOpenPrograms = () => useQuery("programs", fetchOpenPrograms);

/**
 * @param {FormData} data - formdata for file to be uploaded to server
 * @return {Promise<*>}
 */
const uploadAppDocument = async data => {
  const res = await axios.post(
    `${config.server}${config.api.applicationDocuments}`,
    data
  );

  return res.data;
};

export const useUploadAppDocument = () => {
  return useMutation(
    "",
    async (/** @type {FormData} */ data) => await uploadAppDocument(data)
  );
};

/**
 * @param {Step1Submit} data
 * @return {Promise<*>}
 */
const postStep1 = async data => {
  const res = await axios.post(
    `${config.server}${config.api.applicationSingle}`,
    data
  );

  return res.data;
};
export const usePostStep1 = () => {
  return useMutation("step1", async (/** @type {Step1Submit} */ data) => {
    const r = await postStep1(data);
    return r;
  });
};

export const usePostStep2 = () => {
  return useMutation("step2", async data => {
    const res = await axios.post(
      `${config.server}${config.api.applicationLanguage}`,
      data
    );

    return res.data;
  });
};

const postStep3 = async data => {
  const res = await axios.post(
    `${config.server}${config.api.applicationAcademic}`,
    data
  );

  return res.data;
};
export const usePostStep3 = () => {
  return useMutation("step3", async data => {
    await postStep3(data);
  });
};

export const usePostStep4 = () => {
  return useMutation("step4", async data => {
    const res = await axios.post(
      `${config.server}${config.api.applicationStatus}`,
      data
    );

    return res.data;
  });
};

/**
 *
 *
 * @async
 * @param {{status: number; application: number, message: string}} data - [TODO:description]
 * @returns {Promise<*>} [TODO:description]
 */
const updateAppStatus = async data => {
  const res = await axios.post(
    `${config.server}${config.api.applicationStatus}`,
    {
      status: data.status,
      application: data.application,
      message: data.message,
    }
  );

  return res.data;
};
export const useUpdateAppStatus = () => {
  return useMutation(
    "status",
    /**
     * @async
     * @param {{status: number; application: number; message: string}} data
     * @returns {Promise<*>}
     */
    async data => {
      await updateAppStatus(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["application"]);
      },
    }
  );
};

export const useChangeAgent = () => {
  return useMutation(
    "changeAgent",
    /**
     * @async
     * @param {{aid: string, agent: string}} data
     * @returns {Promise<*>}
     */
    async data => {
      const res = await axios.post(
        `${config.server}${config.api.applicationAgent}`,
        {
          application: data.aid,
          agent: data.agent,
        }
      );
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["application"]);
      },
    }
  );
};

export const useWithdrawApplication = () => {
  return useMutation("withdraw", async data => {
    const res = await axios.delete(
      `${config.server}${config.api.applicationDelete}`,
      {
        data: {
          application: data,
        },
      }
    );

    return res.data;
  });
};

export const useClearNotifs = () => {
  return useMutation("notifcations", async id => {
    const res = await axios.put(
      `${config.server}${config.api.notificationClear}/${id}`
    );

    return res.data;
  });
};

export const useDeleteAppDocument = () => {
  return useMutation(
    "deleteAppDoc",
    async code => {
      const res = await axios.delete(
        `${config.server}${config.api.applicationDocuments}`,
        {
          data: {
            document: code,
          },
        }
      );

      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["application"]);
      },
    }
  );
};

export const useAddComment = () => {
  return useMutation(
    "comment",
    async data => {
      const res = await axios.post(
        `${config.server}${config.api.applicationComments}`,
        data
      );

      return res.data;
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries(["application"]);
      },
    }
  );
};

export const useAddInternalNotes = () => {
  return useMutation(
    "internal_notes",
    /**
     * @async
     * @param {{application: number, status: number, note: string}} data
     * @returns {Promise<*>}
     */
    async data => {
      const res = await axios.post(
        `${config.server}${config.api.applicationInternalNotes}`,
        data
      );
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["application"]);
      },
    }
  );
};

export const useChangeProgram = () => {
  return useMutation(
    "program",
    /**
     * @async
     * @param {{program: number, program_secondary: number, application: number}} data
     * @returns {Promise<*>}
     */
    async data => {
      const res = await axios.post(
        `${config.server}/applications/v2/updateProgram`,
        data
      );

      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["application"]);
      },
    }
  );
};
