import { useQuery } from "react-query";
import axios from "axios";
import config from "../config/config";

/**
 * @return {Promise<import("../types").UserInfo>}
 */
const fetchMyInfo = async () => {
  const res = await axios.get(`${config.server}/users/current`);
  return res.data;
};

export const useGetMyInfo = () =>
  useQuery(["myInfo"], () => fetchMyInfo(), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
  });
