import React from "react";
import { Divider, Grid, Paper, Title } from "@mantine/core";
/**
 * @param {Object} props
 * @param {import("../../types").ApplicationLanguage} props.data
 * @return {JSX.Element}
 */
const AppEnglish = ({ data }) => {
  return (
    <Paper shadow="xs" px="md" py="xl" mb="xl">
      <Title size="h3">English Test</Title>
      <Divider my="lg" />
      {!data ? null : (
        <Grid>
          <Grid.Col span={3}>Mother Tongue</Grid.Col>
          <Grid.Col span={9}>{data?.mother_tongue}</Grid.Col>
          <Grid.Col span={3}>English Language Test</Grid.Col>
          <Grid.Col span={9}>{data?.testTaken}</Grid.Col>
          <Grid.Col span={3}>English Proficiency</Grid.Col>
          <Grid.Col span={9}>fdsa</Grid.Col>
          <Grid.Col span={3}>Listening Score</Grid.Col>
          <Grid.Col span={9}>{data?.listening}</Grid.Col>
          <Grid.Col span={3}>Reading Score</Grid.Col>
          <Grid.Col span={9}>{data?.reading}</Grid.Col>
          <Grid.Col span={3}>Writing Name</Grid.Col>
          <Grid.Col span={9}>{data?.writing}</Grid.Col>
          <Grid.Col span={3}>Speaking Score</Grid.Col>
          <Grid.Col span={9}>{data?.speaking}</Grid.Col>
          <Grid.Col span={3}>Total Band Score</Grid.Col>
          <Grid.Col span={9}>{data?.totalScore}</Grid.Col>
          <Grid.Col span={3}>Test Date</Grid.Col>
          <Grid.Col span={9}>{data?.takenOn}</Grid.Col>
        </Grid>
      )}
    </Paper>
  );
};

export default AppEnglish;
