import { MAKE_ANNOUNCEMENT, GET_ANNOUNCEMENT } from "../actions/types";

const initialState = {
  latest: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MAKE_ANNOUNCEMENT:
      return {
        ...state,
        latest: action.playload.message,
      };
    case GET_ANNOUNCEMENT:
    return {
      ...state,
      latest: action.playload.message,
    };
    default:
      return state;
  }
}
